.SectionSteps-inner {
  background: #01215a;
  padding: 70px 25px;
  color: #fff;
  margin: 0 -20px;
  overflow: hidden;
}
@media screen and (min-width: 64em) {
  .SectionSteps-inner {
    padding: 70px 40px;
  }
}
