.StripeAlert {
  padding: 8px 20px 7px;
  text-align: center;
  font-weight: bold;
}
@media screen and (min-width: 48em) {
  .StripeAlert {
    font-size: 1.8rem;
  }
}
.StripeAlert--info {
  color: #fff;
  background: #0485f4;
}
.StripeAlert--error {
  color: #fff;
  background: #ff4a77;
}
.StripeAlert .Icon--discountTag {
  vertical-align: middle;
}
