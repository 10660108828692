.Footer {
  background-color: #01215a;
  color: #b3ceff;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  padding-top: 60px;
  border-top: 2px solid #5edb3d;
  transition: opacity 0.4s ease;
  min-height: 270px;
}
.Footer .col-1 {
  columns: 1 !important;
}
@media screen and (min-width: 48em) {
  .Footer {
    padding-top: 0;
    font-size: 1.5rem;
  }
}
html.is-animating .Footer {
  opacity: 0;
}
.Footer-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  font-weight: bold;
  line-height: 1.6;
  margin: 0 -10px -1.2em -10px;
  color: #fff;
}
@media screen and (min-width: 33.75em) {
  .Footer-list {
    display: block;
    columns: 2;
    column-gap: 40px;
  }
}
@media screen and (min-width: 75em) {
  .Footer-list {
    margin-top: -0.3rem;
  }
}
.Footer-list li {
  break-inside: avoid;
  page-break-inside: avoid;
  padding: 0 10px 1.2em 10px;
  width: 50%;
}
@media screen and (min-width: 33.75em) {
  .Footer-list li {
    width: auto;
  }
}
.Footer-list--minor {
  line-height: 1.428571428571429;
  font-size: 1.4rem;
  margin-bottom: -1em;
  margin-top: 0;
  color: inherit;
  font-family: 'Inter Medium', sans-serif;
}
.Footer-list--minor li {
  padding-bottom: 1em;
}
@media screen and (min-width: 33.75em) {
  .Footer-listOne {
    columns: 1;
  }
}
.Footer-block {
  width: 100%;
  max-width: 600px;
  margin: 0 auto 3.466666666666667em auto;
}
@media screen and (min-width: 33.75em) {
  .Footer-block {
    width: 50%;
    margin: 0;
    max-width: none;
  }
}
@media screen and (min-width: 75em) {
  .Footer-block {
    width: 36.612903225806456%;
  }
}
.Footer-block--services {
  margin-bottom: 0;
}
@media screen and (min-width: 75em) {
  .Footer-block--services {
    padding-right: 20px;
  }
}
.Footer-block--newsletter {
  order: -1;
  width: 100%;
  margin-bottom: 2.133333333333333em;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
@media screen and (max-width: 74.9375em) {
  .Footer-block--newsletter {
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .Footer-block--newsletter {
    width: 24.193548387096776%;
    margin-bottom: 3.466666666666667em;
  }
}
@media screen and (min-width: 75em) {
  .Footer-block--newsletter {
    display: block;
    order: 99;
    width: 24.193548387096776%;
  }
}
.Footer-blockTitle {
  font-family: inherit;
  font-size: inherit;
  color: #fff;
  margin-bottom: 1.8em;
}
@media screen and (max-width: 74.9375em) {
  .Footer-block--newsletter .Footer-blockTitle {
    font-size: 2.8rem;
    text-align: center;
    font-family: 'Inter Medium', sans-serif;
    margin-bottom: 0.75em;
  }
}
.Footer-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 0 5.833vw 0;
}
@media screen and (min-width: 48em) {
  .Footer-content {
    padding-top: 5.833vw;
  }
}
@media screen and (min-width: 75em) {
  .Footer-content {
    padding: 70px 0;
    flex-wrap: nowrap;
  }
}
.Footer-info {
  color: rgba(179,206,255,0.5);
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  padding: 4.417vw 0 5vw 0;
  font-family: 'Inter Medium', sans-serif;
}
@media screen and (min-width: 33.75em) {
  .Footer-info {
    flex-flow: row nowrap;
  }
}
@media screen and (min-width: 75em) {
  .Footer-info {
    padding: 53px 0 60px 0;
  }
}
.Footer-info p {
  margin: 0;
}
@media screen and (min-width: 0em) {
  .Footer-intro {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 7vw 0 5vw 0;
    position: relative;
  }
}
@media screen and (min-width: 75em) {
  .Footer-intro {
    padding-top: 84px;
    padding-bottom: 60px;
  }
}
@media screen and (min-width: 48em) {
  .Footer-intro:after {
    content: '';
    display: block;
    width: 130px;
    height: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #5edb3d;
    z-index: 1;
  }
}
.Footer-intro p {
  margin: 0;
}
.Footer-social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.Footer-logo {
  flex-shrink: 0;
}
.Footer-contacts {
  flex-grow: 1;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  color: #fff;
  font-weight: bold;
  line-height: 1;
  padding-top: 1em;
  text-align: center;
  padding-left: 15px;
}
@media screen and (min-width: 64em) {
  .Footer-contacts {
    padding-left: 140px;
    text-align: left;
  }
}
.Footer-contacts span {
  white-space: nowrap;
}
.Footer-contacts a {
  margin-right: 20px;
  white-space: nowrap;
}
.Footer-contacts a:last-child {
  margin-right: 0;
}
.Footer a {
  color: inherit;
  text-decoration: none;
}
.is-mouse-pointer .Footer a:hover {
  text-decoration: underline;
}
.Footer .Separator {
  background-color: #0e3275;
  margin-top: 10px;
  margin-bottom: 10px;
}
@media screen and (min-width: 48em) {
  .Footer .Separator {
    margin: 0;
  }
}
.Footer .Social {
  color: #fff;
  border-color: #1a3f81;
  margin-left: -1px;
  border-radius: 0px;
}
.Footer .Container {
  positions: static;
}
.Footer .Container > .Separator {
  z-index: auto;
}
.Footer .LogoCebia,
.Footer .LogoGjirafa {
  width: auto;
  height: auto;
  margin-left: 10px;
}
.Footer .LogoCebia img,
.Footer .LogoGjirafa img {
  width: 260px;
}
.Footer .LogoGjirafa img {
  width: 450px;
}
.transparent {
  color: transparent;
}
