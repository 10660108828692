.AtrOverviewCar {
  position: relative;
  background: #01215a;
  color: #fff;
  line-height: 1.4;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  padding: 0 15px;
}
@media screen and (max-width: 47.9375em) {
  .AtrOverviewCar {
    margin: 30px -15px 0;
  }
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar {
    padding: 0;
    margin: 30px 0 0 0;
    border-radius: 3px;
  }
}
.AtrOverviewCar-banner {
  margin: -30px auto 0;
  background: #fff;
  flex: 0 0 auto;
  width: 100px;
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  border-radius: 3px;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar-banner {
    margin: -30px 0 0 20px;
    width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .AtrOverviewCar-banner {
    margin-left: 50px;
    width: 200px;
  }
}
@media screen and (min-width: 87.5em) {
  .AtrOverviewCar-banner {
    width: 230px;
  }
}
.AtrOverviewCar-logo {
  position: relative;
}
.AtrOverviewCar-logo:before {
  content: '';
  padding-bottom: 108.69565217391303%;
  display: block;
}
.AtrOverviewCar-logo .Logo {
  position: absolute;
  top: 25px;
  right: 25px;
  left: 25px;
  bottom: 25px;
}
.AtrOverviewCar-content {
  width: 100%;
  padding: 35px 0;
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar-content {
    width: auto;
    flex: 1 0 0%;
    padding: 88px 30px 30px 30px;
  }
}
@media screen and (min-width: 75em) {
  .AtrOverviewCar-content {
    padding: 65px 0 65px 65px;
  }
}
.AtrOverviewCar-label {
  font-size: 1.5rem;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  display: block;
  color: #b3ceff;
}
@media screen and (max-width: 47.9375em) {
  .AtrOverviewCar-label--empty {
    display: none;
  }
}
.AtrOverviewCar-vin {
  color: #fff;
  font-size: 1.8rem;
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar-vin {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
  }
}
@media screen and (min-width: 75em) {
  .AtrOverviewCar-vin {
    font-size: 2.6rem;
  }
}
.AtrOverviewCar-footer {
  width: 100%;
  padding: 15px 0;
  border-top: 1px solid rgba(34,77,156,0.2);
}
@media screen and (min-width: 22.5em) {
  .AtrOverviewCar-footer {
    padding: 25px 0;
  }
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar-footer {
    padding: 10px 15px 10px 25px;
  }
}
@media screen and (min-width: 75em) {
  .AtrOverviewCar-footer {
    padding: 10px 15px 10px 50px;
  }
}
.AtrOverviewCar-footerLabel {
  font-size: 1.5rem;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
}
.AtrOverviewCar-tag {
  flex: 1 0 0%;
  margin-right: -20px;
}
@media screen and (min-width: 22.5em) {
  .AtrOverviewCar-tag {
    margin-right: -30px;
  }
}
@media screen and (min-width: 48em) {
  .AtrOverviewCar-tag {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    top: 20px;
    right: -10px;
    font-size: 1.5rem;
    max-width: calc(100% - 200px);
  }
}
@media screen and (max-width: 33.6875em) {
  .AtrOverviewCar .ButtonList .Button {
    min-width: 80px;
  }
}
.AtrOverviewCar .Grid {
  min-width: 100%;
}
.AtrOverviewCar .Link--white {
  color: #fff;
}
.AtrOverviewCar .Link-small {
  position: relative;
  text-decoration: none;
  font-size: 1.3rem;
  padding-bottom: 0.1em;
  padding-left: 1em;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}
.Link-blue {
  color: #0485f4;
}
