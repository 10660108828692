.sectionTheft .warning {
  color: #ff4a77;
}
.sectionTheft .noTheftText {
  padding: 10px 0px;
  color: #5edb3d;
}
.sectionTheft .black {
  color: #000;
}
.sectionTheft .pt-15 {
  padding-top: 15px;
}
.sectionTheft .pb-25 {
  padding-bottom: 25px;
}
.sectionTheft .smallInfoText {
  font-size: 1.5rem;
}
.sectionTheft .text-justify {
  text-align: justify;
}
.sectionTheft .line-normal {
  line-height: normal;
}
