.ToggleItemGeneral-inner {
  border: 1px solid #e7f2f7;
  border-top: none;
  padding: 20px 15px;
}
@media screen and (min-width: 48em) {
  .ToggleItemGeneral-inner {
    padding: 45px 30px;
  }
}
