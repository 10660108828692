.Speedometer {
  position: relative;
}
.Speedometer-main {
  width: 100%;
  height: auto;
}
.Speedometer-hand {
  position: absolute;
  bottom: 10%;
  left: 0;
}
.Speedometer path {
  transition: 0.2s fill;
}
