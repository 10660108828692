.EuropeMap {
  position: relative;
  padding-left: 70px;
}
@media screen and (min-width: 75em) {
  .EuropeMap {
    padding-left: 100px;
  }
}
.EuropeMap-svg {
  position: relative;
}
.EuropeMap-svg:before {
  content: '';
  padding-bottom: 86.11111111111111%;
  display: block;
}
.EuropeMap-svg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.EuropeMap-label {
  padding: 0.7em 1.7em 0.7em 1.1em;
  border-radius: 3px;
  background: #eff8fd;
  border: 1px solid #b0dafe;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: bold;
  font-size: 1rem;
  top: 35%;
}
@media screen and (min-width: 48em) {
  .EuropeMap-label {
    font-size: 1.4rem;
  }
}
.EuropeMap-label svg {
  margin-right: 10px;
}
.EuropeMap-label:before {
  content: '';
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-left: 1px solid #b0dafe;
  border-bottom: 1px solid #b0dafe;
  background: #eff8fd;
  position: absolute;
  left: -4.2px;
  top: calc(50% - 3px);
}
