.HomepageStep-subtitle {
  color: #5edb3d;
  margin-bottom: 10px;
  display: block;
}
.HomepageStep-title {
  margin-bottom: 10px;
}
@media screen and (min-width: 75em) {
  .HomepageStep-title {
    font-size: 2.6rem;
  }
}
