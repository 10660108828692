.NewsletterForm {
  font-family: 'Inter Medium', sans-serif;
  max-width: 300px;
  font-size: 1.4rem;
}
.NewsletterForm .Link {
  color: #b3ceff;
  text-decoration: underline;
}
@media screen and (min-width: 33.75em) {
  .NewsletterForm {
    display: flex;
    flex-flow: row wrap;
    max-width: 600px;
    justify-content: space-between;
  }
}
@media screen and (min-width: 75em) {
  .NewsletterForm {
    display: block;
  }
}
.NewsletterForm a {
  color: #fff;
}
.NewsletterForm-formItem {
  width: 100%;
}
@media screen and (min-width: 33.75em) {
  .NewsletterForm-formItem {
    width: calc(50% - 10px);
  }
}
@media screen and (min-width: 75em) {
  .NewsletterForm-formItem {
    width: 100%;
  }
}
.NewsletterForm-formItem--input {
  order: 1;
  flex-grow: 1;
  margin-right: 10px;
}
.NewsletterForm-formItem--button {
  order: 2;
}
@media screen and (min-width: 33.75em) {
  .NewsletterForm-formItem--button {
    max-width: 260px;
  }
}
@media screen and (min-width: 75em) {
  .NewsletterForm-formItem--button {
    max-width: none;
  }
}
.NewsletterForm-formItem--checkbox {
  order: 3;
}
.NewsletterForm .Button {
  width: 100%;
}
