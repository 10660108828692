.SectionCustomerInfo {
  margin-bottom: 0;
}
.SectionCustomerInfo .PhoneCountryPrefix {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #fff;
  border: 1px solid #c0d8e5;
  border-radius: 4px;
  display: table-cell;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 90px;
}
.SectionCustomerInfo .ToggleOpenerAtr-title {
  padding-left: 45px;
}
@media screen and (max-width: 63.9375em) {
  .SectionCustomerInfo .ToggleOpenerAtr-title {
    padding-left: 15px;
  }
}
.SectionCustomerInfo .FormGroup {
  position: relative;
  display: table;
  width: 100%;
}
.SectionCustomerInfo .InputWithPrefix {
  width: 100%;
  height: 60px;
  padding: 6px 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.SectionCustomerInfo .PscInput {
  width: 150px;
}
.SectionCustomerInfo .TogglePadding {
  padding-left: 0px;
}
.SectionCustomerInfo .sectionInvoice:not(.is-active) {
  border-top: 1px solid #e7f2f7 !important;
/* border-bottom 1px solid #e7f2f7!important */
}
.SectionCustomerInfo .sectionInvoice.is-active {
  border-top: 1px solid #e7f2f7 !important;
}
.SectionCustomerInfo .sectionInvoice.is-active::before {
  background: none;
}
.SectionCustomerInfo .sectionInvoiceItem.is-active {
  border-top: initial !important;
/* border-bottom 1px solid #e7f2f7!important */
}
.SectionCustomerInfo .sectionEmail:not(.is-active) {
  border-top: 1px solid #e7f2f7 !important;
/* border-bottom 1px solid #e7f2f7!important */
}
.SectionCustomerInfo .sectionEmail.is-active {
  border-top: 1px solid #e7f2f7 !important;
}
.SectionCustomerInfo .sectionEmail.is-active {
  border-top: initial !important;
/* border-bottom 1px solid #e7f2f7!important */
}
.SectionCustomerInfo .emailTextItem {
  margin-bottom: 30px;
}
.SectionCustomerInfo .contactModal {
  margin-top: 15%;
}
@media screen and (max-width: 63.9375em) {
  .sectionInvoiceItem > .ToggleItemAtr-inner {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
.modalVies {
  align-items: center;
  flex-direction: column;
}
.modalVies .Icon--alert {
  top: 0.4em;
  left: -9px;
}
