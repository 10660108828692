.EuropeMapBubble {
  visibility: hidden;
  line-height: 1.4;
  padding: 15px 30px;
  border-radius: 3px;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  font-weight: bold;
  text-align: center;
  font-size: 1.4rem;
  position: absolute;
  transition: 0.2s visibility, 0.3s opacity, 0.3s transform, 0.15s top, 0.15s left;
  top: 100%;
  left: 100%;
  background: #fff;
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -80%);
}
.EuropeMapBubble:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: '';
  display: inline-block;
  border-width: 4px 4px 0 4px;
  border-color: transparent;
  border-top-color: #fff;
  position: absolute;
  top: 100%;
  left: calc(50% - 4px);
}
.EuropeMapBubble.is-active {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, -100%);
}
