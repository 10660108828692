.Header {
  border-bottom: 1px solid rgba(133,190,215,0.2);
  font-size: 1.4rem;
  line-height: 1.4;
  position: relative;
  z-index: 11;
  font-weight: 900;
  color: #01215a;
}
.Header-content {
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .Header-content {
    padding: 0 0 0 180px;
  }
}
@media screen and (min-width: 64em) {
  .Header-content {
    padding: 0 0 0 145px;
  }
}
.Header-nav {
  width: 100%;
}
.Header-logo {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
}
@media screen and (min-width: 48em) {
  .Header-logo {
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
  }
}
@media screen and (min-width: 64em) {
  .Header-logo {
    width: 130px;
    height: 40px;
    top: calc(50% - 20px);
  }
}
.Header-logo a {
  display: block;
}
.Header--light {
  color: #fff;
  border: none;
}
