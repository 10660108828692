.SectionReferers-logoItem {
  background: #eff8fd;
  height: 130px;
  padding: 10% 23.333333333333332%;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .SectionReferers-logoItem {
    padding: 16.666666666666664% 23.333333333333332%;
  }
}
.SectionReferers-logoItem {
  justify-content: center;
}
