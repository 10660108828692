.ToggleOpenerFaq {
  position: relative;
  padding: 20px 30px;
  display: flex;
  width: 100%;
  color: #01215a;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s background-color;
  border-radius: 3px;
  font-weight: bold;
}
@media screen and (min-width: 48em) {
  .ToggleOpenerFaq {
    padding: 35px 50px;
  }
}
@media screen and (min-width: 75em) {
  .ToggleOpenerFaq {
    font-size: 1.8rem;
  }
}
.ToggleOpenerFaq:focus,
.ToggleOpenerFaq.is-active {
  background: #0485f4;
  color: #fff;
}
.ToggleOpenerFaq:focus:after,
.ToggleOpenerFaq.is-active:after {
  color: inherit;
}
.ToggleOpenerFaq:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #b2b7c4;
  content: '\ea08';
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s transform;
}
@media screen and (min-width: 48em) {
  .ToggleOpenerFaq:after {
    right: 50px;
  }
}
.ToggleOpenerFaq.is-active:after {
  transform: translateY(-50%) rotate(180deg);
}
