.AtrOverviewTag {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0 0 20px;
  transform: translateZ(0);
  backface-visibility: hidden;
}
.AtrOverviewTag-inner {
  position: relative;
  display: block;
  text-align: left;
  background: #f69900;
  padding: 9px 20px;
  font-size: 1.2rem;
}
.AtrOverviewTag-inner:before {
  color: #f49800;
  bottom: -10px;
  right: 0;
}
@media screen and (min-width: 48em) {
  .AtrOverviewTag-inner:before {
    position: absolute;
    width: 0;
    height: 0;
    background: 0;
    border-style: solid;
    content: '';
    display: inline-block;
    border-width: 10px 10px 0 0;
    border-color: transparent;
    border-top-color: currentColor;
  }
}
.AtrOverviewTag-inner .Link {
  color: inherit;
  margin: 2px 5px;
}
.AtrOverviewTag-inner .Info {
  color: inherit;
  margin: 2px 5px;
}
@media screen and (max-width: 33.6875em) {
  .AtrOverviewTag-inner .Info {
    display: block;
  }
}
.AtrOverviewTag--alert .AtrOverviewTag-inner {
  background: #ff4a77;
  font-size: 1.6rem;
  padding: 9px 45px;
  word-break: break-all;
}
.AtrOverviewTag--alert .AtrOverviewTag-inner .Link {
  text-decoration-line: none;
  text-decoration-style: none;
  font-size: 1.6rem;
  padding-left: 10px;
}
.AtrOverviewTag--alert .AtrOverviewTag-inner .Link span {
  white-space: nowrap;
}
.AtrOverviewTag--alert .AtrOverviewTag-inner .Icon--alert {
  position: absolute;
  left: 15px;
  top: 12px;
}
.AtrOverviewTag--alert .AtrOverviewTag-inner .Icon--alert:before {
  font-size: 20px;
}
.AtrOverviewTag--alert .AtrOverviewTag-inner:before {
  color: #ce0019;
}
.AtrOverviewTag.is-fixed {
  position: fixed !important;
  z-index: 10;
  top: 15px;
  left: 15px;
  padding: 0;
}
@media screen and (max-width: 47.9375em) {
  .AtrOverviewTag.is-fixed {
    padding: 0 120px 0 0;
  }
}
@media screen and (min-width: 48em) {
  .AtrOverviewTag.is-fixed {
    top: 30px;
    left: 30px;
    max-width: 200px;
  }
}
.AtrOverviewTag.is-fixed .AtrOverviewTag-inner {
  padding: 10px 70px 10px 15px;
}
@media screen and (min-width: 48em) {
  .AtrOverviewTag.is-fixed .AtrOverviewTag-inner {
    padding: 12px 20px;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 48em) {
  .AtrOverviewTag.is-fixed .AtrOverviewTag-inner:before {
    content: none;
  }
}
.AtrOverviewTag.is-fixed .Link {
  display: none;
}
.AtrOverviewTag.is-static {
  opacity: 0;
  transition: 0.3s opacity;
}
.AtrOverviewTag.is-static.AtrOverviewTag.is-active {
  opacity: 1;
}
.AtrOverviewTag.is-fixed {
  transform: translateX(-150%);
  transition: 0.3s opacity, 0.3s transform;
}
.AtrOverviewTag.is-fixed.AtrOverviewTag.is-active {
  transform: none;
}
