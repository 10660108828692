.ToggleItemMileageTableNote {
  margin: 0 -15px;
}
.ToggleItemMileageTableNote-inner {
  position: relative;
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(46,101,160,0.1), 0 1px 6px 0 rgba(46,101,160,0.1);
  padding: 20px 30px;
  margin: 15px 15px 25px;
}
@media screen and (min-width: 75em) {
  .ToggleItemMileageTableNote-inner {
    margin-right: 40px;
  }
}
@supports (filter: drop-shadow(0px -6px 2px rgba(46,101,160,0.1))) {
  .ToggleItemMileageTableNote-inner {
    box-shadow: none;
    filter: drop-shadow(0 5px 10px rgba(46,101,160,0.1)) drop-shadow(0 1px 6px rgba(46,101,160,0.1));
  }
}
.ToggleItemMileageTableNote-inner:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: '';
  display: inline-block;
  border-width: 0 7px 7px 7px;
  border-color: transparent;
  border-bottom-color: #fff;
  position: absolute;
  bottom: 100%;
  right: 50px;
}
@media screen and (min-width: 87.5em) {
  .ToggleItemMileageTableNote-inner:before {
    right: calc(30% - 60px);
  }
}
.ToggleItemMileageTableNote-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #0485f4;
  color: #fff;
  cursor: pointer;
  top: -15px;
  right: calc(50% - 15px);
  position: absolute;
}
@media screen and (min-width: 48em) {
  .ToggleItemMileageTableNote-close {
    top: -15px;
    right: -15px;
  }
}
.ToggleItemMileageTableNote-close:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea10';
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
