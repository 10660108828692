.SectionHpHighlights {
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .SectionHpHighlights {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .SectionHpHighlights-title {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.SectionHpHighlights-gridWrap {
  line-height: 1.4;
  width: 100%;
  justify-content: center;
}
@media screen and (min-width: 48em) {
  .SectionHpHighlights-gridWrap {
    max-width: none;
  }
}
@media screen and (min-width: 87.5em) {
  .SectionHpHighlights-gridWrap {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
.SectionHpHighlights-item {
  line-height: 1.3;
  display: flex;
  align-items: center;
  text-align: left;
}
@media screen and (max-width: 33.6875em) {
  .SectionHpHighlights-item {
    display: block;
    position: relative;
    text-align: center !important;
    padding: 20px 80px 20px 80px;
  }
}
@media screen and (min-width: 48em) {
  .SectionHpHighlights-item {
    align-items: center;
    font-size: 1.8rem;
    display: grid !important;
    grid-template-columns: 1fr 3fr;
    text-align: left;
  }
}
@media screen and (max-width: 33.6875em) {
  .SectionHpHighlights-visualIcon {
    position: absolute;
    top: calc(50% - 2rem);
    left: 20px;
  }
}
@media screen and (min-width: 33.75em) {
  .SectionHpHighlights-visualIcon {
    font-size: 1.8rem;
    width: 6.5rem;
    margin-right: 10px;
    height: 6.5rem;
  }
}
.SectionHpHighlights-inner {
  padding-top: 40px;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
}
@media screen and (min-width: 33.75em) {
  .SectionHpHighlights-inner {
    padding-top: 9.167vw;
    padding-bottom: 9.167vw;
  }
}
@media screen and (min-width: 75em) {
  .SectionHpHighlights-inner {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
.SectionHpHighlights-ribbon {
  position: absolute;
  top: 20%;
  right: 105%;
  width: 150px;
}
@media screen and (max-width: 63.9375em) {
  .SectionHpHighlights-ribbon {
    display: none;
  }
}
@media screen and (min-width: 87.5em) {
  .SectionHpHighlights-ribbon {
    width: 211px;
  }
}
@media screen and (max-width: 33.6875em) {
  .SectionHpHighlights .Grid-cell {
    border-bottom: 1px solid rgba(133,190,215,0.2);
  }
}
