.AtrOverviewSale {
  width: 100%;
  height: 220px;
  max-height: 180px;
  position: relative;
  border-top: 1px solid rgba(133,190,215,0.2);
  border-bottom: 1px solid rgba(133,190,215,0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
}
.AtrOverviewSale-bubble {
  position: absolute;
  top: 13px;
  right: 13px;
}
.Sale svg {
  position: absolute;
  height: 100%;
  width: 100%;
}
.Sale-description {
  background-color: #0485f4;
  height: 100px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 3px;
}
.Sale-description-label {
  font-size: 18px;
  padding: 43px;
}
.Sale-Shape svg {
  position: absolute;
  bottom: -15px;
  right: -30px;
  width: 100px;
  height: 50px;
  background-size: 100px 150px;
  background-position: center;
  transform: rotate(-12deg);
}
