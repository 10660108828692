.sectionVehicleIdentification .arrowDown:after {
  right: 25px;
  bottom: 13px;
}
.sectionVehicleInfo,
.sectionCouponSales {
  position: relative;
}
.sectionVehicleInfo .Grid .ToggleOpenerGeneral .ToggleOpenerGeneralSimple-image {
  width: 4rem;
  height: 4rem;
}
.sectionVehicleInfo .sectionVehicleSecurity .redWarning {
  color: #ff4a77;
  font-weight: bold;
}
.sectionVehicleInfo .gmt-30 {
  margin-top: 30px;
}
.sectionVehicleInfo .Image {
  position: relative;
}
.sectionVehicleInfo .Image img {
  cursor: pointer;
}
.sectionVehicleInfo .Image:before {
  content: '';
  display: block;
  padding-bottom: 5%;
}
@media screen and (max-width: 63.9375em) {
  .sectionVehicleInfo .arrowDown:after right 30px {
    right: 20px;
  }
}
.imageDesc {
  font-size: 12px;
}
.half.Table td {
  width: 50% !important;
}
