.Visual {
  max-width: 595px;
  position: relative;
}
.Visual:before {
  content: '';
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 103.36134453781514%;
}
.Visual:after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 18.048780487804876%;
  right: 0;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  z-index: 0;
}
@media screen and (min-width: 48em) {
  .Visual:after {
    box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  }
}
.Visual-content,
.Visual-content-noshape {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 82.0371323699187%, 0 100%);
  z-index: 1;
}
.Visual--small .Visual-content,
.Visual--small .Visual-content-noshape {
  clip-path: polygon(0 0, 100% 0, 100% 87.64356665689655%, 0 100%);
}
.Visual--medium .Visual-content,
.Visual--medium .Visual-content-noshape {
  clip-path: polygon(0 0, 100% 0, 100% 86.58534404712042%, 0 100%);
}
.Visual--top .Visual-content,
.Visual--top .Visual-content-noshape {
  clip-path: polygon(0 17.9628676300813%, 100% 0, 100% 100%, 0 100%);
}
.Visual--top.Visual--small .Visual-content,
.Visual--top.Visual--small .Visual-content-noshape {
  clip-path: polygon(0 12.356433343103449%, 100% 0, 100% 100%, 0 100%);
}
.Visual--top.Visual--medium .Visual-content,
.Visual--top.Visual--medium .Visual-content-noshape {
  clip-path: polygon(0 13.414655952879581%, 100% 0, 100% 100%, 0 100%);
}
.Visual--none .Visual-content,
.Visual--none .Visual-content-noshape {
  clip-path: none;
}
.Visual-image {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.Visual-logo {
  width: 31.932773109243694%;
  position: relative;
  position: absolute;
  bottom: 0;
  right: 10.084033613445378%;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .Visual-logo {
    box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  }
}
.Visual-logo:before {
  content: '';
  padding-bottom: 100%;
  display: block;
}
.Visual-logo img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}
.Visual--390x520:before {
  padding-bottom: 133.33333333333331%;
}
.Visual--620x510:before {
  padding-bottom: 82.25806451612904%;
}
.Visual--620x540:before {
  padding-bottom: 87.09677419354838%;
}
.Visual--595x615:before {
  padding-bottom: 103.36134453781514%;
}
.Visual--top:after {
  top: 12.931034482758621%;
  bottom: 0;
}
