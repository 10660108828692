.PagePayment .ExaminationCar {
  font-size: 1.8rem;
  color: #01215a;
}
.PagePayment .ToggleOpenerAtr.is-active:before {
  opacity: 0;
}
.PagePayment .ToggleOpenerAtr-title {
  font-size: 2.4rem;
  color: #01215a;
  font-weight: 400;
}
.PagePayment .BubbleOpener {
  color: #1a3f81;
  background-color: #f0f0f0;
  margin-left: 15px;
}
.PagePayment .ToggleOpenerAtr:after {
  display: none;
}
.PagePayment .liqPayLogoImg {
  height: 2.2em;
  margin: 0;
  display: inline-block;
  font-size: 1rem;
  position: relative;
  vertical-align: middle;
  top: -0.4em;
  cursor: pointer;
  padding-left: 8px;
}
.PagePayment .goPayLogoImg {
  height: 2.2em;
  margin: 0;
  display: inline-block;
  font-size: 1rem;
  position: relative;
  vertical-align: middle;
  top: -0.4em;
  cursor: pointer;
  padding-left: 8px;
}
.PagePayment .sectionSale {
  padding-top: 50px;
}
.PagePayment .AlertIcon {
  padding: 0px 10px;
}
.PagePayment .box {
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  background: #fff;
  margin-bottom: 80px;
}
.PagePayment .section-header {
  max-width: 1360px;
}
.PagePayment .title {
  text-align: center;
  padding: 2rem 2rem;
  font-size: 2rem;
  color: #01215a;
  margin-bottom: 0px;
}
.PagePayment .fweight_700 {
  font-weight: 700;
}
.PagePayment .ToggleItemAtr-inner {
  padding: 15px 45px;
}
.PagePayment .ToggleOpenerAtr {
  padding: 15px 80px 15px 0;
}
.PagePayment .Container {
  padding-left: 30px;
  padding-right: 30px;
}
@media screen and (max-width: 47.9375em) {
  .PagePayment .Container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 47.9375em) {
  .PagePayment .pt-50 {
    padding-top: 20px !important;
  }
}
.PaymentCouponSection .CouponCaption {
  font-size: 1.8rem;
}
.PaymentCouponSection .SectionPageHeader-highlights {
  font-size: 1.6rem;
}
.PaymentCouponSection .SectionPageHeader-highlights ul {
  list-style: none;
  justify-content: flex-start;
}
.PaymentCouponSection .SectionPageHeader-highlights ul li {
  padding-left: 1.375em;
  position: relative;
}
.PaymentCouponSection .SectionPageHeader-highlights ul li:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: '';
  display: inline-block;
  border-width: 7px 7px 0px 0px;
  border-color: transparent;
  border-top-color: #5edb3d;
  display: block;
  position: absolute;
  top: 0.8em;
  left: 0;
}
.PaymentCouponSaleSection .InputError {
  font-size: 1.5rem;
}
.SectionPaymentFooter {
  background-color: #f7fbfe;
  margin-left: -30px;
  margin-right: -30px;
}
.SectionPaymentFooter .flexColumnEnd {
  justify-content: flex-end;
  flex-direction: column;
}
.SectionPaymentFooter .flexEnd {
  justify-content: flex-end;
}
.SectionPaymentFooter .alignCenter {
  align-items: center;
  font-size: 1.8rem;
  color: #01215a;
  font-weight: 400;
}
.SectionPaymentFooter .Price {
  color: #01215a;
  text-align: right;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.8rem;
  justify-content: center;
  white-space: nowrap;
}
.SectionPaymentFooter .Price-cross {
  color: #ff4500;
  text-decoration: line-through;
  font-size: 1.6rem;
  padding-top: 0 !important;
}
.SectionPaymentFooter .SectionSeparator .Container {
  padding: 30px;
}
.SectionPaymentFooter .Grid {
  padding-left: 13px;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .Grid {
    padding-left: 0px;
  }
}
.SectionPaymentFooter .Container {
  padding-bottom: 50px;
}
.SectionPaymentFooter .submitBlock {
  text-align: center;
  color: #01215a;
  width: 300px;
}
.SectionPaymentFooter .pd-80 {
  padding-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .sx-pd-30 {
    padding-top: 30px !important;
  }
}
.SectionPaymentFooter .discount {
  margin: 30px;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  background: #fff;
  border-radius: 5px;
  padding: 30px;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .discount {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.SectionPaymentFooter .break {
  flex-basis: 100%;
  height: 0;
}
.SectionPaymentFooter .discountLink {
  color: #0485f4;
  font-size: 1.6rem;
  font-weight: 400;
  padding-left: 13px;
  padding-right: 0px;
  min-width: 100px;
  cursor: pointer;
  font-weight: normal;
  text-align: left;
}
.SectionPaymentFooter .discountLink:hover:before {
  width: 0px;
  transition-duration: 0s;
  transition-delay: 0s;
}
.SectionPaymentFooter .price {
  flex-direction: row-reverse;
  font-size: 2.6rem;
  color: #01215a;
  font-weight: 400;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .price {
    padding-right: 20px !important;
  }
}
.SectionPaymentFooter .priceSmaller {
  flex-direction: row-reverse;
  font-size: 1.6rem;
  color: #01215a;
  font-weight: 400;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .priceSmaller {
    padding-right: 20px !important;
  }
}
.SectionPaymentFooter .priceInfo {
  font-size: 1.6rem;
  color: #01215a;
  font-weight: 400;
  text-wrap: nowrap;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .priceInfo {
    padding-left: 20px !important;
  }
}
.SectionPaymentFooter .agreement {
  padding-left: 17px;
  font-size: 1.5rem;
  color: #427998;
  font-weight: 400;
}
.SectionPaymentFooter .centerItems {
  align-items: center;
  justify-content: center;
}
.SectionPaymentFooter .centerEnd {
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
@media screen and (min-width: 48em) {
  .SectionPaymentFooter .centerEnd {
    justify-content: end;
    padding-top: 0px;
  }
}
@media screen and (max-width: 47.9375em) {
  .SectionPaymentFooter .centerEnd {
    padding-right: 0px !important;
  }
}
.SectionPaymentFooter .Link {
  color: #427998;
}
