.SectionPaymentMethods .baseItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ToggleOpenerAtr-title {
  padding-left: 45px;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerAtr-title {
    padding-left: 15px;
  }
}
.methodItem {
  display: flex;
  height: 50px;
}
.methodItem .VisualIcon {
  background-color: #f7fbfe;
  flex: 1;
  background-size: 75% 50%;
  border-radius: 5px;
  height: 100%;
  margin: 2px;
}
.description {
  font-weight: 400;
  color: #b2b7c4;
  font-size: 1.5rem;
}
@media screen and (min-width: 48em) {
  .description {
    text-align: right;
  }
}
:before {
  background: transparent;
}
.fs_sm_20 {
  font-size: 20px;
  display: block;
}
@media screen and (min-width: 33.75em) {
  .fs_sm_20 {
    font-size: 42px;
    display: inline;
  }
}
@media screen and (max-width: 47.9375em) {
  .Radio-title {
    font-size: 15px !important;
  }
}
.questionPayMethod {
  align-self: auto;
  margin-bottom: 4px;
}
.PaymentMethodIcons {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
}
.PaymentMethodIcons span.VisualIcon {
  width: 4.6rem;
  height: 4.3rem;
  min-width: 2rem;
  margin-right: 3px;
}
@media screen and (max-width: 63.9375em) {
  .PaymentMethodIcons {
    flex: 1 1 auto !important;
    flex-basis: auto !important;
  }
}
@media screen and (min-width: 64em) {
  .payMethodItem {
    margin-bottom: 10px;
  }
}
.cardDesc {
  font-size: 1.2rem;
  color: #707990;
}
.flex-center {
  display: flex;
  justify-content: center;
  padding-left: 0;
}
@media screen and (min-width: 48em) {
  .sm-hidden {
    display: none;
  }
}
.pl-15 {
  padding-left: 15px;
}
.b-left {
  background-position: left center;
}
