.navbar-fixed {
  bottom: 0px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  display: flex;
  background-color: #deebf7;
  padding: 10px 0;
}
.bolder {
  font-weight: bolder;
}
.wrap {
  flex-wrap: wrap;
}
.toEnd {
  margin-left: auto;
}
.confirm-box__overlay {
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.confirm-box {
  z-index: 1040 !important;
  position: absolute;
  left: 0 !important;
  top: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
