.PageAboutPayment {
  counter-reset: section;
}
.PageAboutPayment .Section {
  padding-bottom: 15px;
  padding-top: 15px;
}
.PageAboutPayment .Container {
  margin-bottom: 40px;
}
.PageAboutPayment .sub-section {
  counter-increment: section;
  counter-reset: subsection;
}
.PageAboutPayment .sub-section h3 {
  text-align: center;
  margin-bottom: 1em;
}
.PageAboutPayment .sub-section h3:before {
  content: counter(section) ". ";
}
.PageAboutPayment .sub-section .sub-text {
  margin-bottom: 1em;
}
.PageAboutPayment .sub-section .sub-text.small {
  margin-bottom: 0.1em;
}
.PageAboutPayment .sub-section .sub-text:before {
  counter-increment: subsection;
  content: counter(section) "." counter(subsection) " ";
}
.PageAboutPayment .sub-section .inner-section {
  counter-reset: inner-section;
}
.PageAboutPayment .sub-section .inner-section p:last-child {
  margin-bottom: 1em;
}
.PageAboutPayment .sub-section .inner-section .sub-text {
  margin-bottom: 0.2em;
}
.PageAboutPayment .sub-section .inner-section .sub-text:before {
  counter-increment: inner-section;
  content: counter(section) "." counter(subsection) "." counter(inner-section) " ";
}
.PageAboutPayment .sub-section .inner-section-char .sub-text {
  margin-bottom: 0.2em;
}
.PageAboutPayment .sub-section .inner-section-char .sub-text:first-child:before {
  content: "а) ";
}
.PageAboutPayment .sub-section .inner-section-char .sub-text:nth-child(2):before {
  content: "б) ";
}
