.AtrInfoStatus {
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
}
.AtrInfoStatus .Info--alert,
.AtrInfoStatus .Info--check {
  padding: 0px;
}
.AtrInfoStatus .Icon--alert {
  position: inherit !important;
}
.AtrInfoStatus .Icon--check {
  position: inherit !important;
  top: 0px;
}
@media screen and (max-width: 63.9375em) {
  .AtrInfoStatus .Icon--check {
    left: 12px !important;
  }
}
.AtrInfoStatus .Icon--alert:before {
  position: relative;
  top: 1px;
  right: 4px;
}
.AtrInfoStatus .Icon--check:before {
  position: relative;
  top: 2px;
  right: 12px;
}
.AtrInfoStatusPeriod {
  font-family: 'Inter Medium', sans-serif;
}
