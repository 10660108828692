.ToggleOpenerGeneralAdvert-visual {
  margin: -15px 0 -15px -5px;
  height: 50px;
  width: 70px;
  padding: 5px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralAdvert-visual .Image img {
    width: 116px;
    height: auto;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralAdvert-visual {
    justify-content: center;
    margin: -25px 10px -25px -30px;
    width: 110px;
    min-height: 80px;
  }
}
.ToggleOpenerGeneralAdvert-mileage {
  margin-right: auto;
  font-weight: bold;
  font-size: 1.3rem;
  text-align: right;
  white-space: nowrap;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralAdvert-mileage {
    order: 1;
    margin-top: 10px;
    margin-left: 10px;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralAdvert-mileage {
    font-size: inherit;
    width: auto;
    padding: 5px 15px;
    border-right: 1px solid rgba(133,190,215,0.2);
    margin-right: 0;
    min-width: 18%;
  }
}
@media screen and (min-width: 75em) {
  .ToggleOpenerGeneralAdvert-mileage {
    padding: 5px 25px;
  }
}
.ToggleOpenerGeneralAdvert-title {
  padding: 10px 0 10px;
  font-size: 1.125em;
  color: #01215a;
  font-weight: bold;
  text-align: right;
  padding-right: 20px;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralAdvert-title {
    width: calc(100% - 70px);
    order: 1;
    text-align: left;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralAdvert-title {
    padding: 0;
    padding-right: 20px;
  }
}
.ToggleOpenerGeneralAdvert-date {
  width: calc(50% - 31px);
  font-size: 1.3rem;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralAdvert-date {
    text-align: right;
    order: 1;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralAdvert-date {
    font-size: inherit;
    margin-right: auto;
    padding: 0 15px;
    width: auto;
    margin-left: 5px;
  }
}
