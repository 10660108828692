.rotate {
  background: transparent;
  width: 33px !important;
  padding-top: 3px;
}
button {
  position: relative;
}
button svg {
  position: absolute;
  left: 0;
  width: 21px;
  height: 31px;
  top: 0;
  opacity: 0.7;
  margin-left: 7px;
}
