.ToggleOpenerGeneral {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-between;
  background: #eff8fd;
  padding: 20px 15px;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  color: #01215a;
  line-height: 1.3;
}
.ToggleOpenerGeneral:focus {
  outline: none;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneral {
    padding: 25px 60px 25px 30px;
    flex-wrap: nowrap;
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 87.5em) {
  .ToggleOpenerGeneral {
    font-size: inherit;
  }
}
.ToggleOpenerGeneral-button {
  position: absolute;
  bottom: 14px;
  right: 40px;
  font-size: 0.875em;
  font-weight: bold;
  color: #0485f4;
  display: inline-block;
  padding: 5px 0 5px 20px;
  border-left: 1px solid rgba(133,190,215,0.2);
  text-align: center;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneral-button {
    position: relative;
    right: auto;
    bottom: auto;
  }
}
.ToggleOpenerGeneral.is-static {
  cursor: default;
}
.ToggleOpenerGeneral.is-static:after {
  content: none;
}
.ToggleOpenerGeneral.is-active:after {
  transform: translateY(-60%) rotateX(180deg);
}
.checkIcon:before {
  line-height: normal;
  transform: translateY(-4%);
  border-radius: 50%;
  background: #5edb3d;
  background-clip: content-box;
  box-sizing: content-box;
}
.checkIcon:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea0c';
  line-height: 22px;
  vertical-align: middle;
  width: 22px;
  padding-left: 2px;
  text-align: center;
}
@media screen and (min-width: 64em) {
  .checkIcon:after color #fff {
    right: 30px;
    top: 50%;
    bottom: auto;
  }
}
.arrowDown:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #0485f4;
  content: '\ea08';
  position: absolute;
  transform: translateY(-50%);
  transition: 0.3s transform;
  bottom: 11px;
  right: 20px;
}
@media screen and (max-width: 63.9375em) {
  .arrowDown:after {
    right: 20px;
  }
}
@media screen and (min-width: 64em) {
  .arrowDown:after {
    right: 30px;
  }
}
.arrowRight:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  color: #0485f4;
  content: '\ea0a';
  position: absolute;
  transform: translateY(-50%);
  transition: 0.3s transform;
  bottom: 11px;
  right: 20px;
}
@media screen and (max-width: 63.9375em) {
  .arrowRight:after {
    right: 20px;
  }
}
@media screen and (max-width: 63.9375em) and (min-width: 64em) {
  .arrowRight:after {
    right: 30px;
    top: 50%;
    bottom: auto;
  }
}
