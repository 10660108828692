.PageHeader {
  text-align: center;
  padding: 30px 20px;
}
@media screen and (min-width: 48em) {
  .PageHeader {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 75em) {
  .PageHeader {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
