.ArticleDetail {
  position: relative;
}
.ArticleDetail .Table-parent {
  overflow: auto;
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail {
    padding-left: 80px;
    padding-right: 80px;
  }
}
.ArticleDetail-header {
  position: relative;
  padding-top: 30px;
}
@media screen and (min-width: 48em) {
  .ArticleDetail-header {
    padding-top: 5.833vw;
  }
}
@media screen and (min-width: 75em) {
  .ArticleDetail-header {
    padding-top: 70px;
  }
}
.ArticleDetail-headerInner {
  display: flex;
  flex-flow: column nowrap;
}
@media screen and (min-width: 48em) {
  .ArticleDetail-headerInner {
    max-width: 745px;
    margin: 0 auto;
  }
}
.ArticleDetail-title {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #01215a;
  line-height: 1.285714285714286;
  font-size: 2.4rem;
  padding-bottom: 20px;
  max-width: 992px;
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail-title {
    order: 2;
    margin-right: -80px;
  }
}
@media screen and (min-width: 48em) {
  .ArticleDetail-title {
    font-size: calc(3.712296983758701vw + -0.251044083526682rem);
    padding-top: 4.167vw;
    padding-bottom: 4.167vw;
  }
}
@media screen and (min-width: 75em) {
  .ArticleDetail-title {
    margin-right: -33.22147651006711%;
    font-size: 4.2rem;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail-coverWrapper {
    order: 3;
  }
}
.ArticleDetail-image {
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  max-width: 992px;
  margin-bottom: 2em;
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail-image {
    margin-right: -80px;
  }
}
@media screen and (min-width: 48em) {
  .ArticleDetail-image {
    box-shadow: 0 20px 45px rgba(46,101,160,0.2);
    margin-left: 0;
    margin-bottom: 6.167vw;
  }
}
@media screen and (min-width: 75em) {
  .ArticleDetail-image {
    margin-bottom: 74px;
    margin-right: -33.22147651006711%;
  }
}
.ArticleDetail-image img {
  border-radius: 3px;
}
.ArticleDetail-info {
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 0;
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail-info {
    order: 1;
  }
}
@media screen and (min-width: 48em) {
  .ArticleDetail-info {
    padding-bottom: 0;
  }
}
.ArticleDetail-category {
  text-decoration: none;
  color: #0485f4;
}
.is-mouse-pointer .ArticleDetail-category:hover {
  text-decoration: underline;
}
.article-Detail-category + .ArticleDetail-date {
  border-left: 1px solid #c0d8e5;
  padding-left: 37px;
  margin-left: 37px;
}
.ArticleDetail-content {
  color: #01215a;
  font-size: 1.5rem;
  line-height: 1.777777777777778;
}
@media screen and (min-width: 48em) {
  .ArticleDetail-content {
    max-width: 745px;
    margin: 0 auto;
    color: inherit;
    font-size: 1.8rem;
  }
}
.ArticleDetail-perex {
  font-family: inherit;
  margin-bottom: 0;
  font-weight: 400 !important;
}
.ArticleDetail-perex:after {
  content: '';
  width: 60px;
  height: 2px;
  background-color: #0485f4;
  display: block;
  margin: 1.269230769230769em 0 1.269230769230769em 0;
}
.ArticleDetail-controls {
  display: none;
}
@media screen and (min-width: 33.75em) {
  .ArticleDetail-controls {
    display: flex;
    flex-flow: column nowrap;
    margin-left: -5px;
  }
}
@media screen and (min-width: 75em) {
  .ArticleDetail-controls {
    margin-left: 0;
  }
}
.ArticleDetail-controls--back {
  margin-top: -20px;
  position: absolute;
  left: -70px;
}
.ArticleDetail-controls .Social {
  margin-bottom: 10px;
  border-radius: 50%;
  border-color: #cde1f1;
  color: #0485f4;
}
.ArticleDetail-main {
  position: relative;
}
.ArticleDetail-attachments {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid #e7f2f7;
}
.ArticleDetail-controlsWrapper {
  position: absolute;
  height: 100%;
  left: -70px;
}
.ArticleDetail h2,
.ArticleDetail h3 {
  color: #01215a;
  font-weight: 700;
}
.ArticleDetail h2 {
  font-size: 2.6rem;
  line-height: 1.230769230769231;
  margin-top: 1.75em;
  margin-bottom: 0.75em;
  font-weight: 400 !important;
}
.ArticleDetail h3 {
  font-size: 1.8rem;
  line-height: 1.2;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}
.ArticleDetail ul li {
  padding-left: 1.375em;
  position: relative;
}
.ArticleDetail ul li:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  border-width: 7px 7px 0 0;
  border-color: #5edb3d transparent transparent;
  content: '';
  display: block;
  position: absolute;
  top: 0.8em;
  left: 0;
}
.ArticleDetail .Gama {
  font-size: 2.6rem;
  font-weight: 700;
}
