.AtrNav {
  line-height: 1.3;
}
@media screen and (max-width: 47.9375em) {
  .AtrNav {
    font-size: 1.3rem;
    color: #b3ceff;
    position: fixed;
    z-index: 99;
    padding: 80px 10px 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(100%) translateZ(0);
    backface-visibility: hidden;
    transition: 0.3s transform cubic-bezier(0.52, 0.19, 0.25, 0.79), 0.3s opacity;
    overflow: auto;
    background: #01215a;
    visibility: hidden;
    pointer-events: none;
  }
}
@media screen and (min-width: 48em) {
  .AtrNav {
    font-size: 1.4rem;
    color: #427998;
    height: 100%;
  }
}
.AtrNav-inner {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .AtrNav-inner {
    overflow-y: auto;
  }
}
@media screen and (max-width: 74.9375em) {
  .AtrNav-inner {
    padding-left: 0;
    padding-right: 15px;
  }
}
.AtrNav-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 30px;
  transform: translateY(500px);
  transition: 0.3s transform, 0.3s height;
}
@media screen and (max-width: 74.9375em) {
  .AtrNav-indicator {
    display: none;
  }
}
.AtrNav-indicator:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skew(0, 45deg);
  background: #5edb3d;
}
.AtrNav-block:not(:last-of-type) {
  margin-bottom: 50px;
}
.AtrNav-atrNavOpener {
  display: none;
  position: fixed;
  top: 20px;
  right: 30px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: #01215a;
  box-shadow: 0 0 5px 0 rgba(239,248,253,0.2);
}
.AtrNav.is-active {
  visibility: visible;
  transform: none;
  opacity: 1;
  pointer-events: all;
}
@media screen and (max-width: 47.9375em) {
  .AtrNav.is-active .AtrNav-atrNavOpener {
    display: flex;
  }
}
