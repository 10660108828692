.PageDetailSmartCode .SectionMain {
  padding-bottom: 0px;
}
.PageDetailSmartCode .SectionMain-main {
  width: 100%;
}
.PageDetailSmartCode .propagation {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.PageDetailSmartCode .SectionMain-categoryHeader {
  display: none;
}
.PageDetailSmartCode .SectionMain-categoryHeaderSmart {
  display: block;
}
.PageDetailSmartCode .SectionMain-category:not(:last-child) {
  margin-bottom: 0px;
}
.PageDetailSmartCode .ml-15 {
  margin-left: 15px;
}
.PageDetailSmartCode .mr-15 {
  margin-right: 15px;
}
.PageDetailSmartCode .basicListing {
  font-size: 1.8rem;
  color: #01215a;
  font-weight: 400;
}
.PageDetailSmartCode .borderRight {
  border-right: solid 1px #0e3275;
}
@media screen and (max-width: 63.9375em) {
  .PageDetailSmartCode .borderRight {
    border-right: none;
  }
}
.PageDetailSmartCode .cross {
  text-decoration: line-through;
  white-space: nowrap;
}
@media screen and (max-width: 33.6875em) {
  .PageDetailSmartCode .cross {
    padding-left: 10px !important;
  }
}
.PageDetailSmartCode span.font-xxxl {
  white-space: nowrap;
}
@media screen and (max-width: 33.6875em) {
  .PageDetailSmartCode span.font-xxxl {
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 33.6875em) {
  .PageDetailSmartCode .Poster.is-static {
    padding: 15px 0px;
    line-height: 1.2;
  }
}
