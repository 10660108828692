.Rating {
  display: inline-block;
  width: 17px;
  height: 15px;
  background: url("./img/star.svg") center center/17px 17px;
}
.Rating span:not([class]) {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}
.Rating--5 {
  width: 85px;
}
.Rating--4 {
  width: 68px;
  background-position: left;
}
.Rating--3 {
  width: 51px;
}
.Rating--2 {
  width: 34px;
  background-position: left;
}
.Rating--1 {
  width: 17px;
}
