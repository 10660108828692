.LoaderReport {
  font-size: 1.5rem;
  max-width: 140rem;
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport {
    width: calc(90vw - 20px);
  }
}
@media screen and (min-width: 64em) {
  .LoaderReport {
    width: calc(50vw - 20px);
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport {
    display: flex;
    flex-flow: column;
  }
}
.LoaderReport-header {
  width: 100%;
  padding: 20px 20px 0;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 20px;
  row-gap: 10px;
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport-header {
    flex-flow: column;
  }
}
@media screen and (min-width: 48em) {
  .LoaderReport-header {
    padding: 70px 20px 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport-title {
    font-size: 1.8rem;
    font-weight: 400;
  }
}
@media screen and (min-width: 48em) {
  .LoaderReport-title {
    flex: 0 0 100%;
    min-width: 580px;
  }
}
.LoaderReport-subtitle {
  font-weight: 400;
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport-subtitle {
    margin-top: 10px;
    padding: 10px 15px;
    box-shadow: 0 10px 25px rgba(46,101,160,0.1);
    border-radius: 3px;
  }
}
.LoaderReport-subtitle:before {
  content: '(';
}
.LoaderReport-subtitle:after {
  content: ')';
}
.LoaderReport-group {
  color: #707990;
}
.LoaderReport-group:before {
  content: '\ea1d';
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  background: #0485f4;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  position: absolute;
  font-size: 14px;
  vertical-align: middle;
}
.LoaderReport-group.is-done,
.LoaderReport-group.is-active {
  color: #01215a;
}
.LoaderReport-group.is-done:before {
  content: '\ea0c';
  font-size: 20px;
  background: #5edb3d;
  animation: none;
}
.LoaderReport-group.is-active:before {
  animation: 4s spin linear infinite;
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport-group {
    position: relative;
    padding-bottom: 10px;
    padding-left: 50px;
    padding-top: 7px;
    min-height: 50px;
  }
  .LoaderReport-group:before {
    left: 0;
    top: 0;
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
  .LoaderReport-group:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    left: 17px;
    top: 40px;
    height: calc(100% - 45px);
    width: 2px;
    background: #eff8fd;
  }
  .LoaderReport-group .Text {
    margin-top: 10px;
    line-height: 1.5;
  }
}
@media screen and (min-width: 48em) {
  .LoaderReport-group {
    position: absolute;
    text-align: center;
    padding-bottom: 60px;
    font-size: 1.8rem;
  }
  .LoaderReport-group span {
    position: absolute;
    padding-bottom: 5px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
  }
  .LoaderReport-group:before {
    left: calc(50% - 25px);
    bottom: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .LoaderReport-group:nth-child(1) {
    bottom: 56%;
    right: 81%;
  }
  .LoaderReport-group:nth-child(2) {
    bottom: 78%;
    right: 62%;
    text-align: left;
  }
  .LoaderReport-group:nth-child(3) {
    bottom: 78%;
    left: 62%;
    text-align: right;
  }
  .LoaderReport-group:nth-child(4) {
    bottom: 56%;
    left: 81%;
  }
}
.LoaderReport-currentItem {
  text-align: center;
}
@media screen and (min-width: 48em) {
  .LoaderReport-currentItem {
    font-size: 2rem;
  }
}
.LoaderReport-main {
  position: relative;
  padding: 40px 20px 20px;
}
@media screen and (min-width: 48em) {
  .LoaderReport-main {
    padding-bottom: 50px;
  }
}
.LoaderReport-main ul {
  max-width: 400px;
  margin: 0 auto;
}
.LoaderReport-speedometer {
  max-width: 350px;
  margin: 0 auto 10px;
}
@media screen and (min-width: 48em) {
  .LoaderReport-speedometer {
    padding: 30px 50px 0;
    max-width: 900px;
  }
}
.LoaderReport-footer {
  padding: 30px 80px;
  background: #eff8fd;
  color: #427998;
  font-size: 1.125em;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .LoaderReport-footer {
    font-size: 0.8em;
    padding: 14px 20px;
    border-bottom: 1px solid rgba(133,190,215,0.2);
    order: -1;
  }
}
.LoaderReport-footer .Icon {
  margin-right: 10px;
}
.LoaderReport-footer .Icon:before {
  vertical-align: bottom;
}
