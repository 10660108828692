.AtrTimeLineTable {
  color: #01215a;
  font-size: 1.4rem;
}
.AtrTimeLineTable .warning {
  color: #ff4a77;
  font-weight: bold;
}
.AtrTimeLineTable .normal {
  font-weight: normal;
}
.AtrTimeLineTable .referer {
  cursor: pointer;
  align-items: center;
}
.AtrTimeLineTable .mt-2 {
  margin-top: 2px;
}
.AtrTimeLineTable .AtrTimeLineTable-group {
  page-break-inside: avoid;
  break-inside: avoid;
}
.AtrTimeLineTable .AtrTimeLineTable-rowHeader {
  page-break-inside: avoid;
}
.AtrTimeLineTable .ToggleItemMileageTableNote {
  page-break-inside: avoid;
}
.AtrTimeLineTable .ToggleItemMileageTableNote table {
  page-break-inside: avoid;
}
.AtrTimeLineTable .ToggleItemMileageTableNote-close {
  display: none;
}
@media screen and (min-width: 0em) and (max-width: 22.4375em) {
  .AtrTimeLineTable .ToggleItemMileageTableNote-inner {
    padding: 10px;
  }
}
.AtrTimeLineTable .ToggleItemMileageTableNote-inner:before {
  right: 50px !important;
}
.AtrTimeLineTable .u-colAnchor a {
  text-decoration: none !important;
  padding: 0;
  background-color: transparent;
  padding-top: 12px;
}
.AtrTimeLineTable .u-colAnchor {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  order: 4;
  margin-left: auto;
  margin-right: 35px;
  height: 22px;
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colAnchor {
    flex: 0 0 auto;
    order: 4 !important;
    margin-right: 0px;
  }
  .AtrTimeLineTable .u-colAnchor a {
    flex-direction: row-reverse;
    right: 20px;
  }
}
@media screen and (min-width: 75em) and (max-width: 87.4375em) {
  .AtrTimeLineTable .u-colAnchor a {
    right: 20px;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .AtrTimeLineTable .u-colAnchor a {
    right: 5px;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colAnchor {
    margin-right: 0px;
    flex: 0 0 auto;
    order: 4;
  }
  .AtrTimeLineTable .u-colAnchor a {
    flex-direction: row-reverse;
    right: 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colAnchor {
    flex: 1 0 10%;
    order: 5;
    display: flex;
    margin-right: 0px;
  }
  .AtrTimeLineTable .u-colAnchor a {
    flex-direction: row-reverse;
    right: 20px;
  }
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable .u-colAnchor {
    flex: 1 0 10%;
    order: 5;
    display: flex;
    margin-right: 0px;
  }
  .AtrTimeLineTable .u-colAnchor a {
    flex-direction: row-reverse;
    right: 20px;
  }
}
.AtrTimeLineTable .arrowDown:after {
  bottom: 4px !important;
  right: -10px !important;
}
.AtrTimeLineTable .ToggleOpenerGeneral-button {
  border: none;
  top: -5px;
  position: relative;
  width: auto;
  margin-left: 0;
}
@media screen and (max-width: 74.9375em) {
  .AtrTimeLineTable .ToggleOpenerGeneral-button {
    right: 0;
  }
}
.AtrTimeLineTable .firstCapitalize:first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 33.6875em) {
  .AtrTimeLineTable {
    box-shadow: 0 0 1px 0 #c8dfeb;
  }
}
.AtrTimeLineTable-header {
  display: flex;
  background: #01215a;
  color: #fff;
  font-weight: bold;
  padding: 12px;
  line-height: 1.4;
}
.AtrTimeLineTable-headerRows {
  display: flex;
  flex: 1 1 0%;
}
.AtrTimeLineTable-group {
  display: flex;
  padding: 12px;
}
.AtrTimeLineTable-group:nth-child(odd) {
  background: rgba(239,248,253,0.4);
}
.AtrTimeLineTable-group:nth-child(even) {
  background: #eff8fd;
}
@media screen and (max-width: 33.6875em) {
  .AtrTimeLineTable-group {
    padding: 0 12px;
  }
}
.AtrTimeLineTable-row {
  page-break-inside: avoid;
}
@media screen and (max-width: 74.9375em) {
  .AtrTimeLineTable-row {
    border-bottom: 1px solid #c8dfeb;
  }
}
.AtrTimeLineTable-row .Table.line2rem td,
.AtrTimeLineTable-row .Table.line2rem th {
  height: 1.5rem;
}
.AtrTimeLineTable-row .Table.line2rem tr {
  display: table-row !important;
}
.AtrTimeLineTable-row .Table.line2rem tr td {
  width: 40% !important;
  display: table-cell !important;
}
.AtrTimeLineTable-row .Table.line2rem tr td:nth-child(2) {
  width: 45.5% !important;
}
.AtrTimeLineTable-row .Table table tr {
  display: flex;
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable-row .Table table tr {
    display: table-row;
  }
}
@media screen and (min-width: 0em) and (max-width: 22.4375em) {
  .AtrTimeLineTable-row .Table table tr {
    display: table-row;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable-row .Table table tr {
    display: table-row;
  }
}
.AtrTimeLineTable-row .Table table tr td {
  display: flex;
  height: auto;
  flex: 0 0 48.66666666666667%;
}
.AtrTimeLineTable-row .Table table tr td:nth-child(1) {
  padding-left: 15px;
}
@media screen and (min-width: 75em) and (max-width: 87.4375em) {
  .AtrTimeLineTable-row .Table table tr td:nth-child(2) {
    padding-left: 6.666666666666667%;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable-row .Table table tr td {
    display: table-cell;
  }
  .AtrTimeLineTable-row .Table table tr td:nth-child(2) {
    padding-left: 5.333333333333334%;
  }
}
@media screen and (min-width: 0em) and (max-width: 22.4375em) {
  .AtrTimeLineTable-row .Table table tr td {
    display: table-cell;
  }
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable-row .Table table tr td:nth-child(2) {
    padding-left: 2px;
  }
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable-row .Table table tr td {
    display: table-cell;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .AtrTimeLineTable-row .Table table tr td:nth-child(2) {
    padding-left: 4%;
  }
}
@media screen and (max-width: 74.9375em) {
  .AtrTimeLineTable-row .Table table tr td:nth-child(1) {
    width: 56%;
  }
  .AtrTimeLineTable-row .Table table tr td:nth-child(2) {
    width: 70%;
  }
}
.AtrTimeLineTable-row:last-child {
  border-bottom: 0;
}
.AtrTimeLineTable-row.is-hover .AtrTimeLineTable-rowHeader:after {
  content: '';
  position: absolute;
  pointer-events: none;
  left: -12px;
  right: -12px;
  bottom: 0;
  display: block;
  height: 100%;
  box-shadow: 0 2px 10px rgba(46,101,160,0.25);
}
@media screen and (min-width: 64em) {
  .AtrTimeLineTable-row.is-hover .AtrTimeLineTable-rowHeader:after {
    bottom: 2px;
    left: -20px;
    right: 10px;
  }
}
.AtrTimeLineTable-rowHeader {
  display: flex;
  flex-flow: row wrap;
  padding: 5px 0;
  position: relative;
  line-height: 1.4;
}
.AtrTimeLineTable-rows {
  flex: 1 1 0%;
}
.AtrTimeLineTable-state .Icon {
  margin: -10px 7px 0 0;
}
.AtrTimeLineTable .u-colYear {
  line-height: 1.4;
  width: 70px;
  text-align: center;
  font-weight: 600;
  padding: 0 0;
}
@media screen and (min-width: 87.5em) {
  .AtrTimeLineTable .u-colYear {
    width: 95px;
  }
}
.AtrTimeLineTable-group .u-colYear {
  font-size: 1.6rem;
  padding-top: 4px;
  position: relative;
}
.AtrTimeLineTable-group .u-colYear:before,
.AtrTimeLineTable-group .u-colYear:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: calc(14px + 2rem);
}
.AtrTimeLineTable-group .u-colYear:before {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #5edb3d;
}
.AtrTimeLineTable-group .u-colYear:after {
  width: 1px;
  margin-left: 2px;
  margin-top: 5px;
  background: #c8dfeb;
  bottom: -24px;
}
.AtrTimeLineTable-group:last-child .u-colYear:after {
  bottom: -12px;
}
.AtrTimeLineTable .u-colMonth {
  flex: 0 0 12%;
  font-weight: 500;
  margin-top: 2px;
}
.AtrTimeLineTable .u-colMonth.monthPrint {
  padding-left: 44px;
  flex: 0 0 18.666666666666668% !important;
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable .u-colMonth {
    flex: 0 0 50%;
    order: 2;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colMonth {
    order: 1;
    flex: 0 0 33%;
    margin-bottom: 10px;
  }
}
.AtrTimeLineTable .u-colCountry {
  display: flex;
  flex: 0 0 20.666666666666668%;
  padding: 0 6px 0 11px;
  white-space: nowrap;
  min-width: 110px;
  margin-left: 5px;
  max-width: 150px;
}
.AtrTimeLineTable .u-colCountry.countryPrint {
  margin-left: 55px;
}
.AtrTimeLineTable .u-colCountry.countryPrint .countryFlagIconName {
  display: none;
}
.AtrTimeLineTable .u-colCountry.countryPrint .countryName {
  width: 150px;
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable .u-colCountry {
    flex: 0 0 10%;
    order: 2;
    padding-left: 0px;
    margin-left: 7px !important;
    min-width: 25px !important;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colCountry {
    order: 2;
    min-width: auto;
    margin-left: auto !important;
    flex: 0 0 auto !important;
    justify-content: right;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colCountry {
    flex: 0 0 10% !important;
  }
}
.AtrTimeLineTable .u-colCountry span {
  display: inline-block;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colCountry span {
    max-width: 80px;
  }
}
.AtrTimeLineTable .u-colKm {
  flex: 0 0 16.666666666666664%;
  text-align: right;
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable .u-colKm {
    flex: 0 0 50%;
    order: 1;
  }
}
@media screen and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colKm {
    width: auto;
    flex: 1 1 0%;
    text-align: right;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colKm {
    order: 2;
    flex: 0 0 15%;
    margin-bottom: 8px;
  }
}
.AtrTimeLineTable-group .u-colKm {
  color: #427998;
}
.AtrTimeLineTable-group .u-colKm strong {
  color: #01215a;
  font-weight: 500;
}
.AtrTimeLineTable .notePrint {
  flex: 0 0 38% !important;
  justify-content: left;
  padding-right: 5px;
}
.AtrTimeLineTable .u-colNote {
  flex: 0 0 43%;
  padding-left: 3.6%;
}
.AtrTimeLineTable .u-colNote.notePrint {
  padding-left: 110px;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colNote {
    flex: 0 0 100%;
    order: 4;
  }
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colNote {
    flex: 0 0 100%;
    order: 4;
  }
}
.AtrTimeLineTable-group .u-colNote {
  position: relative;
}
.AtrTimeLineTable .u-colNote a.is-active {
  color: #0485f4;
}
.AtrTimeLineTable .u-colNote a.color-red {
  color: #ff4a77 !important;
}
.AtrTimeLineTable .u-colNoteRow {
  order: 3;
  margin-top: 1px;
  flex: 1;
}
.AtrTimeLineTable .u-colNoteRow.notePrint {
  padding-left: 60px;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrTimeLineTable .u-colNoteRow {
    flex: 0 0 100%;
    order: 5;
    justify-content: right;
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colNoteRow {
    flex: 0 0 25%;
    order: 3;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrTimeLineTable .u-colNoteRow {
    flex: 0 0 80%;
  }
}
@media screen and (min-width: 64em) {
  .AtrTimeLineTable .u-colNoteRow {
    margin-left: 28px;
  }
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .AtrTimeLineTable .u-colNoteRow {
    flex: 0 0 auto;
    order: 2;
    padding-left: 0px !important;
    min-width: 175px;
  }
}
.AtrTimeLineTable-group .u-colNote {
  position: relative;
}
.AtrTimeLineTable .u-colNoteRow a.is-active {
  color: #0485f4;
}
.AtrTimeLineTable .u-colNoteRow a.color-red {
  color: #ff4a77 !important;
}
.AtrTimeLineTable a {
  text-decoration: underline;
}
