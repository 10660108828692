.Title {
  margin-bottom: 0.7em;
  text-align: center;
  display: block;
}
@media screen and (min-width: 48em) {
  .Title {
    text-align: left;
    margin-bottom: 1.333333333333333em;
  }
}
.Title--center {
  text-align: center;
}
@media screen and (min-width: 48em) {
  .Title--center {
    text-align: center;
  }
}
.Title--withDash {
  position: relative;
  padding-bottom: 20px;
}
.Title--withDash:after {
  content: '';
  width: 40px;
  height: 2px;
  background: #0485f4;
  position: absolute;
  bottom: 0;
  left: calc(50% - 20px);
}
@media screen and (min-width: 48em) {
  .Title--withDash:after {
    left: 0;
  }
}
.Title--center.Title--withDash:after {
  left: calc(50% - 20px);
}
.Title--dashColorGreen:after {
  background: #5edb3d;
}
.Title h3.blue {
  color: #01215a;
}
