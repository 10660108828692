.top-to-btm {
  position: relative;
}
.ArrowTop {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
  background-color: #5edb3d;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: icons;
  font-feature-settings: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-size: 18px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-alternates: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-variation-settings: normal;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  margin-top: 30px;
  text-align: center;
  text-rendering: auto;
  width: 50px;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.ArrowTop:before {
  content: '\ea0b';
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-right: 0px;
}
