.Info {
  color: #ff4a77;
  display: inline-flex;
  font-weight: bold;
  position: relative;
  line-height: 1.3;
  font-size: 1.4rem;
  padding: 0 0 0 1.5em;
}
@media screen and (min-width: 48em) {
  .Info {
    font-size: 1.5rem;
  }
}
.Info .Icon {
  position: absolute;
  top: 0.15em;
  left: 2px;
  height: 1em;
}
.Info .Icon--check {
  color: #5edb3d;
}
.Info .Icon--check:hover {
  color: #0485f4;
}
.Info--withBg {
  padding: 10px 15px;
}
.Info--withBg.Info--withIcon {
  padding-left: 4rem;
}
.Info--withBg.Info--withIcon .Icon {
  top: calc(10px + 0.15em);
  left: 15px;
}
.Info--withBg.Info--alert {
  background: rgba(255,74,119,0.15);
}
.Info--withBg.Info--check {
  background: rgba(94,219,61,0.15);
}
