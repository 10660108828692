@media screen and (max-width: 47.9375em) {
  .NavLayer {
    position: absolute;
    top: 0;
    left: 0 !important;
    right: 0;
    transform: translateX(100%);
    background: #01215a;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    height: 100%;
  }
}
@media screen and (min-width: 48em) {
  .NavLayer {
    opacity: 0;
    transform: translateY(-5px);
    transition: 0.3s transform, 0.3s opacity;
    position: absolute;
    box-shadow: 0 10px 25px rgba(46,101,160,0.1);
    background: #fff;
    left: 0%;
    top: calc(100% - 10px);
    color: #01215a;
    border-radius: 3px;
    font-size: 1.5rem;
    box-shadow: 0 10px 45px rgba(46,101,160,0.2);
    display: flex;
    flex-flow: row nowrap;
    padding: 50px 58px;
    justify-content: space-between;
  }
}
@media screen and (max-width: 47.9375em) {
  .NavLayer-header {
    height: 80px;
    background-color: #0c2c64;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0 15px;
  }
}
@media screen and (min-width: 48em) {
  .NavLayer-header {
    display: none;
  }
}
@media screen and (max-width: 47.9375em) and (min-width: 22.5em) {
  .NavLayer-header {
    padding: 0 50px;
  }
}
@media screen and (max-width: 47.9375em) {
  .NavLayer-content {
    padding: 40px 50px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.NavLayer-title {
  font-family: 'Inter Medium', sans-serif;
  color: #b3ceff;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .NavLayer-title:before {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\ea09';
    margin-right: 20px;
  }
}
@media screen and (max-width: 47.9375em) {
  .NavLayer li {
    padding-bottom: 2.07em;
  }
}
@media screen and (min-width: 48em) {
  .NavLayer li {
    padding-bottom: 1em;
  }
  .NavLayer li:last-child {
    padding-bottom: 0;
  }
}
.NavLayer a {
  text-decoration: none;
}
@media screen and (min-width: 48em) {
  .NavLayer--langswitch {
    padding: 20px 20px;
  }
}
.NavLayer:not(.is-active) {
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .NavLayer.is-active {
    transform: none;
  }
  .NavLayer.is-active a {
    color: #fff;
  }
}
@media screen and (min-width: 48em) {
  .NavLayer.is-active {
    opacity: 1;
    transform: none;
  }
}
