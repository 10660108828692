.Input {
  border: 1px solid #c0d8e5;
  box-shadow: none;
  background-color: #fff;
  font-size: 1.6rem;
  color: inherit;
  padding: 0 20px;
  border-radius: 0;
  width: auto;
  font-family: inherit;
  font-weight: normal;
  height: 60px;
  margin: 0;
  border-radius: 3px;
}
.Input:focus {
  border-color: #5edb3d;
  outline: none;
}
.Input::placeholder {
  color: #b2b7c4;
  font-family: 400;
  font-size: 1.6rem;
}
.Input--dark {
  border-color: #1a3f81;
  background-color: transparent;
}
.Input--dark::placeholder {
  color: rgba(179,206,255,0.5);
}
.Input--textarea {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 104px;
  resize: vertical;
  display: block;
}
@media screen and (min-width: 64em) {
  .Input--desktopLarge {
    height: 80px;
  }
}
.Input.is-invalid {
  border-color: #ff4a77;
}
.Input.is-invalid:focus {
  border-color: #ff4a77;
  box-shadow: 0 0 0 5px rgba(255,74,119,0.2);
}
.Input.is-invalid::selection {
  background-color: #ffe3e7;
}
.InputError {
  font-size: 1.7rem;
  color: #f42234;
  font-weight: bold;
  display: block;
  padding: 5px 0;
  line-height: 1.166666666666667;
  font-family: 'Inter Medium', sans-serif;
  padding-left: 0px;
}
.LeftAbsolute {
  position: absolute !important;
  left: 0px;
}
input:read-only {
  background-color: #f5f5f5;
}
