.orderStep {
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  display: flex;
  border-radius: 3px;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  overflow: hidden;
  color: #b3ceff;
  font-size: 1.5rem;
  margin-right: 150px;
}
@media screen and (min-width: 100em) {
  .orderStep {
    margin-left: 20px;
    margin-right: 170px;
  }
}
@media screen and (max-width: 100em) {
  .orderStep {
    margin-right: 0;
    border-radius: 0;
  }
}
.orderStep .orderStep-item {
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
  flex: 1 1;
  padding: 10px;
  text-decoration: none;
  background-color: #01215a;
  padding: 14px 30px;
  flex-flow: row;
}
@media screen and (max-width: 74.9375em) {
  .orderStep .orderStep-item {
    padding: 14px 15px;
  }
}
.orderStep .orderStep-item .orderStep-number {
  flex: 0 0 auto;
  border-radius: 50%;
  border: 1px solid #1a3f81;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 30px;
  font-size: 1.7rem;
  margin-right: 20px;
}
@media screen and (max-width: 74.9375em) {
  .orderStep .orderStep-item .orderStep-number {
    display: none;
  }
}
.orderStep .orderStep-item:after {
  position: absolute;
  top: calc(50% - 8px);
  left: calc(100% - 1px);
  z-index: 1;
}
.orderStep .orderStep-item.is-active {
  background: #5edb3d;
  color: #fff;
}
.orderStep .orderStep-item.is-active .orderStep-number {
  background: #fff;
  color: #5edb3d;
  border-color: #fff;
}
.orderStep .orderStep-item.is-active:after {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: "";
  display: inline-block;
  border-width: 8px 0 8px 9px;
  border-color: transparent;
  border-left-color: #5edb3d;
}
