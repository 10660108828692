.Table {
  font-size: 1.3rem;
  line-height: 1.4;
  overflow: auto;
  color: #01215a;
}
@media screen and (min-width: 48em) {
  .Table {
    font-size: 1.4rem;
  }
}
.Table table {
  border-collapse: collapse;
  width: 100%;
}
.Table tr {
  background: #eff8fd;
}
.Table tr:nth-child(even) {
  background: #fff;
}
.Table.noBackground tr {
  background: #fff !important;
}
.Table thead tr {
  background: #01215a;
  color: #fff;
  height: auto;
}
.Table td,
.Table th {
  padding: 0.4em 1.5em 0.4em 1.5em;
  height: 4rem;
  max-height: 12rem;
}
@media screen and (min-width: 48em) {
  .Table td,
  .Table th {
    height: 4rem;
  }
}
.Table td.error {
  color: #ff4a77;
}
.Table.blurred {
  position: relative;
  filter: none;
  overflow: hidden;
  opacity: 1;
}
.Table.blurred:after,
.Table.blurred:before {
  content: '';
  position: absolute;
  display: block;
  z-index: 1;
  background: rgba(255,255,255,0.6);
}
.Table.blurred:before {
  width: 100%;
  height: calc(100% - 8rem);
  bottom: 0;
}
.Table.blurred:after {
  width: 100%;
  height: 3.4rem;
  bottom: 0;
  right: 0;
  left: auto;
  top: 3.4rem;
  background: linear-gradient(to right, rgba(255,255,255,0) 0, rgba(255,255,255,0) 20%, rgba(255,255,255,0.6) 30%);
}
@media screen and (min-width: 48em) {
  .Table.blurred:after {
    top: 4rem;
    height: 4rem;
  }
}
.Table.blurred td {
  filter: blur(3px);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Table.blurred td {
    opacity: 0.2;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Table.blurred thead {
    text-shadow: none;
  }
}
.Table.blurred tbody tr:first-child td:first-child {
  text-shadow: none;
  filter: none;
  opacity: 1;
}
