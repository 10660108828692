.sectionCheckLeas .warning {
  color: #ff4a77;
}
.sectionCheckLeas .noLeasText {
  padding: 10px 0px;
  color: #5edb3d;
}
.sectionCheckLeas .pbAllways {
  page-break-after: always;
}
.sectionCheckLeas .black {
  color: #000;
}
.sectionCheckLeas .pt-15 {
  padding-top: 15px;
}
.sectionCheckLeas .pb-25 {
  padding-bottom: 25px;
}
.sectionCheckLeas .smallInfoText {
  font-size: 1.5rem;
}
