.PrintDetailPaid .Grid-cellX,
.PrintDetailPaid .pageBreakAvoid {
  break-inside: avoid;
}
.PrintDetailPaid .ToggleItemMileageTableNote-close {
  display: none;
}
.PrintDetailPaid .pageBreakAvoid {
  page-break-inside: avoid;
  break-inside: avoid;
}
.PrintDetailPaid h1,
.PrintDetailPaid h2 {
  color: #01215a;
  font-weight: 700;
  line-height: 1.2;
}
.PrintDetailPaid h2 {
  margin: 25px 0;
  text-align: center;
}
.PrintDetailPaid h2.bolder {
  font-weight: 900;
}
.PrintDetailPaid h2.warning {
  color: #ff4a77;
}
.PrintDetailPaid .mt-100 {
  margin-top: 100px;
}
.PrintDetailPaid .d-flex {
  display: flex;
}
.PrintDetailPaid .Image img {
  width: 300px !important;
  display: block !important;
  padding: 7px 7px;
  padding-top: 7px;
  page-break-inside: avoid;
  break-inside: avoid;
}
.PrintDetailPaid .Table {
  width: 100%;
}
.PrintDetailPaid .ImageAnonymized img {
  width: 150px !important;
  display: inline !important;
  padding: 0px 7px;
}
.PrintDetailPaid .Image,
.PrintDetailPaid .ImageAnonymized {
  display: inline-block;
  float: left;
}
.PrintDetailPaid .AtrGallery {
  position: relative;
  display: inline-block;
  float: left;
}
.PrintDetailPaid .AtrGallery .fl {
  float: none;
  display: inline-block;
}
body {
  font-size: 1.5rem;
  line-height: 1.8;
}
.fs15 {
  font-size: 1.5rem;
}
.headTable {
  width: 100%;
}
.headTable td {
  font-size: 20px;
  font-family: Arial;
  font-weight: bold;
  color: #01215a !important;
  width: 99%;
  text-align: right;
}
.headTable td a {
  text-decoration: none;
  color: #01215a !important;
}
.headTable td a:visited {
  color: inherit;
}
.w1 {
  width: 1%;
}
.printLink {
  font-weight: 200;
  font-size: 110%;
}
.captionM {
  margin-bottom: -15px;
}
.dsi {
  display: block;
  float: left;
}
.pba,
.AtrMileageTable {
  page-break-inside: avoid;
}
.fl {
  float: left;
}
.print {
  float: left;
  width: 100%;
}
.print .pmb-30 {
  padding-bottom: 30px !important;
}
p {
  display: inline-block;
  width: 100%;
}
@media print {
  .printMonthNumber {
    display: none;
  }
}
