.Price {
  text-align: right;
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 4.2rem;
  justify-content: center;
  white-space: nowrap;
}
.Price-cross {
  color: #ff4500;
  text-decoration: line-through;
  font-size: 0.5em;
  margin-right: 10px;
  padding-top: 0.35em;
}
.Price--small {
  font-size: 2.6rem;
}
.Price--small .Price-cross {
  font-size: 0.75em;
  padding-top: 0.2em;
}
