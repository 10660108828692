.SectionPartners-gridWrap {
  max-width: 70vw;
  line-height: 1.4;
  width: 100%;
  margin: 0 auto;
}
@media screen and (min-width: 48em) {
  .SectionPartners-gridWrap {
    max-width: none;
  }
}
@media screen and (min-width: 87.5em) {
  .SectionPartners-gridWrap {
    width: 100%;
  }
}
.SectionPartners-gridCell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vigImage {
  width: 300px;
}
