.ToggleOpenerFaqTab {
  position: relative;
  padding: 20px 30px;
  width: 100%;
  color: #01215a;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s background-color;
  border-radius: 3px;
  font-weight: 700;
  border-bottom: 1px solid rgba(133,190,215,0.2);
  text-align: center;
  line-height: 1.4;
}
@media screen and (min-width: 48em) {
  .ToggleOpenerFaqTab {
    padding: 20px 50px;
  }
}
@media screen and (min-width: 75em) {
  .ToggleOpenerFaqTab {
    font-size: 1.8rem;
  }
}
.ToggleOpenerFaqTab.is-active {
  color: #0485f4;
  border-color: #0485f4;
}
