.Logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Logo img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  flex: 0 0 auto;
}
.Logo--medium img {
  max-width: 80%;
  max-height: 80%;
}
.Logo--small img {
  max-width: 60%;
  max-height: 60%;
}
