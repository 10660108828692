.Review {
  color: #01215a;
  line-height: 1.4;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.Review>* {
  width: 100%;
}
.Review-author {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: auto;
}
.Review-text {
  margin-bottom: 25px;
  font-size: 1.8rem;
  max-width: 730px;
  margin: 0 auto;
}
@media screen and (min-width: 48em) {
  .Review-text {
    margin-bottom: 2.419354838709677%;
  }
}
@media screen and (min-width: 87.5em) {
  .Review-text {
    font-size: 2.1rem;
  }
}
.Review-text:last-child {
  margin-bottom: 30px;
}
@media screen and (min-width: 48em) {
  .Review-text:last-child {
    margin-bottom: 60px;
  }
}
.Review-title {
  font-size: 1.5rem;
  color: #01215a;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  font-weight: 700;
}
@media screen and (min-width: 48em) {
  .Review-title {
    margin-bottom: 30px;
  }
}
.Review .Rating {
  margin-bottom: 10px;
}
@media screen and (min-width: 48em) {
  .Review--large {
    margin-bottom: 6.048387096774194%;
    font-size: calc(0.928074245939675vw + 1.087238979118329rem);
  }
}
@media screen and (min-width: 87.5em) {
  .Review--large {
    font-size: 2.6rem;
  }
}
.Review .rating-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
