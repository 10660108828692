.Nav {
  height: 100%;
  line-height: 1.2;
  display: flex;
  width: 100%;
}
.Nav ::-webkit-scrollbar-thumb {
  background-color: rgba(178,183,196,0.5);
  border: 2px solid #01215a;
}
@media screen and (max-width: 33.6875em) {
  .Nav {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 200;
    background-color: #0c2c64;
    color: #fff;
    font-size: 1.8rem;
    font-weight: bold;
    opacity: 0;
    transition: opacity 0.2s ease;
    width: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .Nav {
    display: block;
    position: fixed;
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    left: auto;
    bottom: auto;
    width: 380px;
    padding-top: 80px;
    border-radius: 3px;
    box-shadow: 0 20px 45px rgba(46,101,160,0.2);
    background-color: #0c2c64;
    z-index: 200;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .Nav:after {
    width: 0;
    height: 0;
    background: 0;
    border-style: solid;
    content: '';
    display: inline-block;
    border-width: 0 5px 5px 5px;
    border-color: transparent;
    border-bottom-color: #01215a;
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    right: 39px;
  }
}
.Nav-inner {
  height: 100%;
  display: flex;
  width: 100%;
}
.Nav-main {
  display: flex;
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  .Nav-main {
    background-color: #01215a;
    padding: 0 50px;
    overflow-x: hidden;
    overflow-y: auto;
    transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(-10px);
    height: 100%;
    padding: 0 25px 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .Nav.is-active .Nav-main {
    transform: translateY(0);
  }
  .Nav.is-active .Nav-main a,
  .Nav.is-active .Nav-main .Nav-link--langswitch,
  .Nav.is-active .Nav-main .Nav-iconLink {
    color: #fff;
  }
}
.Nav-links {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  .Nav-links {
    flex-flow: column;
    margin-bottom: 20px;
    justify-content: normal;
  }
}
@media screen and (min-width: 64em) {
  .Nav-links {
    height: 100%;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
}
.Nav-links a {
  text-decoration: none;
  min-width: max-content;
  font-weight: 700;
}
@media screen and (max-width: 47.9375em) {
  .Nav-links a {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(180,217,255,0.14);
    height: 80px;
  }
}
.Nav-link--sublayer {
  position: relative;
}
@media screen and (min-width: 48em) {
  .Nav-link--sublayer {
    padding-right: 20px;
  }
  .Nav-link--sublayer:after {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\ea08';
    position: absolute;
    right: 0;
    transition: 0.3s transform;
  }
  .Nav-link--sublayer.is-active:after {
    transform: rotate(180deg);
  }
}
@media screen and (max-width: 47.9375em) {
  .Nav-button {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid rgba(180,217,255,0.14);
    display: block;
    width: 80px;
    height: 100%;
  }
}
@media screen and (min-width: 48em) {
  .Nav-button {
    display: none;
  }
}
.Nav-link:first-child .Nav-button {
  border-top-width: 1px;
}
.Nav-button:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea0a';
}
@media screen and (max-width: 47.9375em) {
  .Nav-button:after {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 47.9375em) and (min-width: 33.75em) {
  .Nav-button {
    width: 60px;
  }
}
.Nav-iconLink {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  font-weight: 700;
}
.Nav-iconLink:hover {
  text-decoration: none;
}
.is-mouse-pointer .Nav-iconLink:hover {
  color: #0485f4;
}
.is-mouse-pointer .Nav-iconLink:hover .Icon {
  color: inherit;
}
.Nav-iconLink .Icon {
  width: 1.2em;
  height: 1.2em;
  font-size: 1.2em;
  margin: -0.5em 0.866666666666667em -0.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}
@media screen and (min-width: 48em) {
  .Nav-iconLink .Icon {
    color: #b2b7c4;
  }
}
.Nav.is-layer-active .Nav-layer {
  transition: 0.3s transform, 0.3s opacity;
  transform: translateY(-5px);
}
.Nav.is-layer-visible .Nav-layer {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 47.9375em) {
  .Nav.is-active {
    opacity: 1;
  }
}
.Nav.is-active .Nav-inner {
  flex-direction: column;
}
.Nav.is-active .Nav-links a {
  width: 100%;
}
@media screen and (max-width: 47.9375em) {
  .Nav:not(.is-active) {
    pointer-events: none;
  }
}
.Nav .switcher {
  width: 130px;
  justify-content: end;
}
