.Modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 400;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transform: scale(1.04);
}
.Modal .bold {
  font-weight: 700;
}
.Modal h2 {
  color: #01215a;
}
.Modal .Check:before {
  top: 4px;
}
.Modal .Check:after {
  top: 4px;
}
.is-cms .Modal {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  display: block;
  opacity: 1;
  transform: none !important;
}
.Modal.is-visible {
  display: block;
}
.Modal.is-visible:not(.is-active) {
  transform: scale(1);
}
.Modal.is-active {
  opacity: 1;
  transform: scale(1);
}
.Modal-background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
  background-color: rgba(0,0,0,0.1);
}
.Modal-layer {
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  transition: opacity 0.4s ease, transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
@media screen and (min-width: 48em) {
  .Modal-layer {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    background-color: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 30px 16px;
  }
}
@media screen and (min-width: 48em) {
  .is-cms .Modal-layer {
    position: relative;
  }
}
@media screen and (min-width: 48em) {
  .Modal-layer:before,
  .Modal-layer:after {
    content: '';
    display: block;
    flex-grow: 1;
  }
}
@media screen and (min-width: 48em) {
  .Modal.is-active .Modal-layer {
    transform: translate3d(0, 0, 0);
    transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition-delay: 0.2s;
  }
}
.Modal.is-fullscreen .Modal-layer {
  padding: 0;
  overflow: hidden;
}
.Modal-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
}
@media screen and (min-width: 48em) {
  .Modal-inner {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    top: auto;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    max-width: 800px;
    width: 100%;
  }
}
@media screen and (min-width: 48em) {
  .Modal.is-fullscreen .Modal-inner {
    overflow: auto;
    max-width: none;
    width: 100%;
  }
}
.Modal.is-loading .Modal-inner {
  min-height: 200px;
  background-color: #fff;
  width: 100%;
  background-image: url("./img/loading.svg");
  background-position: center;
}
.Modal-content {
  width: 100%;
  background-color: #fff;
  transition: opacity 0.2s ease;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-grow: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
}
.Modal-content .description {
  text-align: left;
}
@media screen and (max-width: 33.6875em) {
  .Modal-content .title,
  .Modal-content .description {
    float: none;
    margin: 0 0 !important;
    text-align: left;
  }
  .Modal-content .VisualIcon {
    display: none !important;
  }
  .Modal-content .button {
    text-align: center;
  }
}
@media screen and (min-width: 48em) {
  .Modal-content {
    padding: 80px 0;
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    overflow: initial;
    -webkit-overflow-scrolling: initial;
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(20px);
  }
}
@media screen and (min-width: 48em) {
  .Modal.is-active .Modal-content {
    transform: translateY(0);
  }
}
.Modal.is-loading .Modal-content {
  opacity: 0;
  min-height: 200px;
}
.Modal.is-fullscreen .Modal-content {
  min-height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.Modal-close {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  border: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 0;
  display: block;
  position: absolute;
  background: none;
  left: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
  color: #0485f4;
}
@media screen and (min-width: 48em) {
  .Modal-close {
    background-color: #eff8fd;
    border-radius: 50%;
    left: auto;
    top: -10px;
    right: -10px;
    color: #0485f4;
    transition: transform 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateY(20px) scale(1);
  }
}
@media screen and (min-width: 64em) {
  .Modal-close {
    top: 10px;
    right: 10px;
  }
}
@media screen and (min-width: 48em) {
  .Modal.is-fullscreen .Modal-close {
    right: 30px;
    top: 30px;
  }
}
@media screen and (min-width: 48em) {
  .Modal.is-active .Modal-close {
    transform: translateY(0);
  }
}
.Modal-close:focus {
  outline: none;
}
.Modal-close:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea0f';
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 48em) {
  .Modal-close:after {
    content: '\ea10';
  }
}
@media screen and (min-width: 48em) {
  .is-mouse-pointer .Modal.is-active .Modal-close:hover {
    transform: scale(1.2);
  }
}
