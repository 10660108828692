.PayedRegEndInfo {
  padding-top: 20px;
}
.PayedRegEndInfo h2 {
  color: #013475;
}
.PayedRegEndInfo .separator {
  height: 3px;
  border-top: 2px solid #ededed;
}
.PayedRegEndInfo .gray {
  color: #808080;
}
.PayedRegEndInfo .fwNormal {
  font-weight: normal;
}
