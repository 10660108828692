.Container {
  margin: 0 auto;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 1240px;
  position: relative;
  box-sizing: content-box;
}
@media screen and (min-width: 22.5em) {
  .Container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (min-width: 48em) {
  .Container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media screen and (min-width: 75em) {
  .Container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.Container--wide {
  max-width: 1680px;
}
.Container--slim {
  max-width: 850px;
}
.Container--narrow {
  max-width: 1080px;
}
.Container--medium {
  max-width: 1400px;
}
.Container--full {
  max-width: 1920px;
}
.Container.is-pure {
  padding: 0;
  position: static;
}
@media screen and (min-width: 48em) {
  .Container.is-pure {
    padding: 0;
  }
}
@media screen and (min-width: 64em) {
  .Container.is-pure {
    padding: 0;
  }
}
