.Hamburger {
  position: relative;
  background: transparent;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  padding: 0;
  cursor: pointer;
  border: none;
  display: block;
  z-index: 100;
  overflow: hidden;
}
.Hamburger:focus {
  outline: none;
}
.Hamburger-content {
  display: block;
  width: 26px;
  height: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Hamburger-line {
  pointer-events: none;
  display: block;
  height: 2px;
  width: 100%;
  background: currentColor;
  position: absolute;
  left: 0;
  overflow: hidden;
  transition: transform 300ms ease, top 300ms ease, opacity 300ms ease;
  background-clip: padding-box;
}
.Hamburger-line:nth-child(1) {
  top: 2px;
}
.NavOpener.is-active .Hamburger-line:nth-child(1) {
  transform: rotate(45deg);
  top: 5px;
}
.Hamburger-line:nth-child(2) {
  top: 6px;
}
.NavOpener.is-active .Hamburger-line:nth-child(2) {
  opacity: 0;
}
.Hamburger-line:nth-child(3) {
  top: 10px;
}
.Hamburger-line:nth-child(3):after {
  transition-delay: 100ms;
}
.NavOpener.is-active .Hamburger-line:nth-child(3) {
  transform: rotate(-45deg);
  top: 5px;
}
