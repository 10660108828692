.PagePayedReg {
  color: #000;
}
.PagePayedReg .VisualIcon {
  width: 3rem;
  height: 3rem;
}
.PagePayedReg .Button {
  height: 40px;
}
.PayedRegHeader {
  border-top: 2px solid #ededed;
  min-height: 54px;
  color: #000;
  position: relative;
  text-align: center;
}
.PayedRegHeader span {
  line-height: 54px;
}
.PayedRegRowInfo {
  border-top: 2px solid #ededed;
  min-height: 40px;
  color: #000;
  position: relative;
}
.PayedRegRowInfo--inactive {
  cursor: auto;
  background-color: #f5f5f5;
  color: #808080;
}
.PayedRegRowInfo--inactive .VisualIcon {
  opacity: 0.4;
}
.PayedRegRowInfo .Grid-cell {
  display: flex;
  align-items: center;
}
.PayedRegRowInfo .Grid-cell button {
  margin-top: 6px;
  margin-bottom: 6px;
}
.PayedRegRowInfo .Grid-cell .BubbleOpener {
  margin-top: 13px;
}
.PayedRegRowInfo:last-of-type {
  border-bottom: 2px solid #ededed;
}
.avalaible {
  color: #f69900;
}
.unavalaible {
  color: #808080;
}
@media screen and (max-width: 33.6875em) {
  .xs-offset-2 {
    margin-left: 16.66666667%;
  }
}
@media screen and (max-width: 63.9375em) {
  .xs-offset-2 {
    margin-left: 16.66666667%;
  }
}
@media screen and (max-width: 33.6875em) {
  .xs-offset-icon {
    justify-content: normal;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 63.9375em) {
  .xs-offset-icon {
    justify-content: normal;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 33.6875em) {
  .xs-offset-helper {
    justify-content: normal;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 63.9375em) {
  .xs-offset-helper {
    justify-content: normal;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 33.6875em) {
  .xs-offset-normal {
    justify-content: normal;
  }
}
@media screen and (max-width: 63.9375em) {
  .xs-offset-normal {
    justify-content: normal;
  }
}
.tooltip-container {
  font-size: 12px;
  line-height: 17.6px;
  color: rgba(0,0,0,0.7);
  width: 600px;
}
.green {
  color: #5edb3d;
}
@media screen and (max-width: 33.6875em) {
  .xs-small-hidden {
    display: none !important;
  }
}
.black {
  color: #000;
}
