.PulseDot {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  box-sizing: content-box;
  background-color: #5edb3d;
  background-clip: padding-box;
  border-radius: 50%;
  position: relative;
}
.PulseDot:before {
  width: 100%;
  height: 100%;
  background-color: rgba(94,219,61,0.5);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  content: '';
  display: block;
  transform-origin: 50% 50%;
  animation: dot-pulse 2s linear infinite both;
}
@-moz-keyframes dot-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50%, 100% {
    transform: scale(3);
    opacity: 0;
  }
}
@-webkit-keyframes dot-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50%, 100% {
    transform: scale(3);
    opacity: 0;
  }
}
@-o-keyframes dot-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50%, 100% {
    transform: scale(3);
    opacity: 0;
  }
}
@keyframes dot-pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50%, 100% {
    transform: scale(3);
    opacity: 0;
  }
}
