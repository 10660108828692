.SectionShape {
  pointer-events: none;
  background-color: transparent;
}
.SectionShape--radial {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  padding-bottom: 50%;
  transform-origin: 50% 0;
  transform: translateX(-50%) rotate(-10.7deg);
  background-image: radial-gradient(ellipse at 50% 0%, #eff8fd 0%, rgba(239,248,253,0.001) 45%);
  max-width: 1920px;
}
.SectionShape--radialLeftTop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 70%;
  transform-origin: 0 0;
  transform: translateX(-50%) rotate(-10.7deg);
  background-image: radial-gradient(ellipse at 50% 0%, #eff8fd 0%, #eff8fd 30%, rgba(239,248,253,0.001) 45%);
}
.SectionShape--right {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  width: 75.35714285714286%;
  transform-origin: 100% 0;
  transform: skew(0, -10.7deg);
  background-image: linear-gradient(to right, rgba(239,248,253,0.001) 30%, #eff8fd 100%);
  padding-bottom: 39.107142857142854%;
}
.SectionShape--rightCustom {
  background-image: radial-gradient(ellipse at 80% 100%, #eff8fd 0%, rgba(239,248,253,0) 70%);
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(-10.7deg);
  transform-origin: bottom center;
  width: calc(110vw + 300px);
  position: absolute;
  padding-bottom: 119.04761904761905%;
}
.SectionShape--rightMiddle {
  position: absolute;
  z-index: -1;
  top: 50%;
  right: 0;
  width: 75.35714285714286%;
  transform-origin: 100% 0;
  transform: translateY(-40%) skew(0, -10.7deg);
  background-image: linear-gradient(to right, rgba(239,248,253,0.001) 30%, #eff8fd 100%);
  padding-bottom: 29.761904761904763%;
}
.SectionShape--rightMiddleUp {
  position: absolute;
  z-index: -1;
  padding-bottom: 238.0952380952381%;
  background-image: radial-gradient(ellipse at 80% 100%, #eff8fd 0%, rgba(239,248,253,0) 70%);
  top: auto;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) rotate(-10.7deg);
  transform-origin: bottom center;
  width: calc(110vw + 300px);
}
@media screen and (min-width: 48em) {
  .SectionShape--rightMiddleUp {
    padding-bottom: 119.04761904761905%;
  }
}
@media screen and (min-width: 75em) {
  .SectionShape--rightMiddleUp {
    padding-bottom: 71.42857142857143%;
  }
}
.SectionShape--rightBottomUp {
  position: absolute;
  z-index: -1;
  padding-bottom: 238.0952380952381%;
  background-image: radial-gradient(ellipse at 80% 100%, #eff8fd 0%, #eff8fd 40%, rgba(239,248,253,0) 70%);
  top: auto;
  bottom: 0;
  left: 50%;
  transform: rotate(-10.7deg);
  transform-origin: bottom center;
  width: calc(110vw + 300px);
}
@media screen and (min-width: 48em) {
  .SectionShape--rightBottomUp {
    padding-bottom: 119.04761904761905%;
  }
}
@media screen and (min-width: 75em) {
  .SectionShape--rightBottomUp {
    padding-bottom: 71.42857142857143%;
  }
}
.SectionShape--left {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1680px;
  min-height: 100%;
  transform-origin: 50% 100%;
  transform: skew(0, -10.7deg);
  background-image: linear-gradient(to bottom, rgba(239,248,253,0.001) 10%, #eff8fd 40%);
  padding-bottom: 39.107142857142854%;
}
.SectionShape--leftBottom {
  position: absolute;
  padding-left: inherit;
  top: 0;
  left: 50%;
  width: 100%;
  margin: 4% auto 0;
  transform: translateX(-50%) skew(0, -10.7deg);
  background-image: radial-gradient(ellipse at 0% 100%, #eff8fd 0%, #eff8fd 40%, rgba(239,248,253,0) 80%);
  padding-bottom: 41.66666666666667%;
}
@media screen and (min-width: 120em) {
  .SectionShape--leftBottom {
    max-width: calc(100% - 120px);
  }
}
.SectionShape--leftMiddle {
  position: absolute;
  padding-left: inherit;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0 auto;
  transform: translate(-50%, -70%) skew(0, -10.7deg);
  background-image: radial-gradient(ellipse at 0% 100%, #eff8fd 0%, rgba(239,248,253,0.2) 40%, rgba(239,248,253,0) 80%);
  padding-bottom: 41.66666666666667%;
}
@media screen and (min-width: 120em) {
  .SectionShape--leftMiddle {
    max-width: calc(100% - 120px);
  }
}
