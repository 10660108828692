.AtrNavBlock-title {
  display: block;
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .AtrNavBlock-title {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
    margin-bottom: 25px;
    color: #01215a;
  }
}
@media screen and (min-width: 75em) {
  .AtrNavBlock-title {
    font-size: 2.6rem;
  }
}
.AtrNavBlock-title:after {
  content: '';
  background-color: rgba(133,190,215,0.2);
  height: 1px;
  width: 40px;
  display: block;
  margin-top: 15px;
}
.AtrNavBlock .AtrNavLink:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (min-width: 48em) {
  .AtrNavBlock .AtrNavLink:not(:last-child) {
    margin-bottom: 20px;
  }
}
