.PrintTachoSection .Table {
  width: 480px;
}
.PrintTachoSection .Table thead tr {
  background: none;
}
.PrintTachoSection .Table tr {
  background: none;
}
.PrintTachoSection .underText {
  font-style: italic;
}
