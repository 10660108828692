.PageArticle .ArticleCard {
  margin-bottom: -1px;
}
.PageArticle .Grid-cell {
  border-bottom: 1px solid #ddd;
}
.PageArticle .article-Categories {
  display: block;
  text-align: center;
}
.PageArticle .article-Categories-list {
  font-weight: 700;
  line-height: 1.2;
}
.PageArticle .article-Categories-item {
  margin: 3px;
  overflow: hidden;
  background-color: #ebf6ff;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.PageArticle .article-Categories-link {
  color: #0485f4;
  text-decoration: none;
  white-space: nowrap;
  padding: 0.533333333333333em 1.333333333333333em;
  transition: color 0.2s ease;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.PageArticle .article-Categories-link.is-active {
  color: #ebf6ff;
  background-color: #0485f4;
}
.PageArticle .article-Categories-itembefore {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #daedfd;
  right: -5%;
  left: auto;
  width: 0;
  transition-duration: 0.4s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translateZ(0) skewX(-10.7deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: width;
  z-index: -1;
}
