.NavOpener {
  position: fixed;
  top: 27px;
  right: 30px;
  z-index: 10;
  font-size: 1.4rem;
  font-weight: 800;
  display: inline-flex;
  align-items: center;
  flex-flow: row nowrap;
  padding: 0;
  margin: 0;
  background: none;
  font-family: inherit;
  color: #01215a;
  cursor: pointer;
  transform: translateX(calc(100% + 40px)) translateZ(0);
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
@media screen and (min-width: 48em) {
  .NavOpener {
    top: 42px;
    right: 30px;
  }
}
@media screen and (min-width: 48em) {
  .NavOpener {
    display: none;
  }
}
.NavOpener--static {
  position: relative;
  top: auto;
  right: auto;
  transform: none;
  transition: none;
}
@media screen and (min-width: 48em) {
  .NavOpener--static {
    top: auto;
    right: auto;
  }
}
.NavOpener.is-pinned {
  transform: translateX(0);
}
.NavOpener.is-active {
  color: #fff;
}
@media screen and (min-width: 48em) {
  .NavOpener.is-active {
    color: #01215a;
  }
}
.NavOpener:focus {
  outline: none;
}
.NavOpener-label {
  margin-right: 15px;
  display: none;
}
@media screen and (min-width: 48em) {
  .NavOpener-label {
    display: block;
  }
}
.Nav .NavOpener {
  color: #fff;
  position: absolute;
  top: 27px;
  right: 30px;
}
@media screen and (min-width: 48em) {
  .Nav .NavOpener {
    display: none;
  }
}
