.FadedBackground {
  background: #fff;
}
@media screen and (min-width: 48em) {
  .FadedBackground {
    position: relative;
  }
}
.FadedBackground:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  right: 0;
  padding-bottom: 62.016129032258064%;
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  background-color: #fff;
  z-index: -1;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
}
@media screen and (max-width: 47.9375em) {
  .FadedBackground--mobileBox {
    box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  }
  .FadedBackground--mobileBox,
  .FadedBackground--mobileBox:before {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}
@media screen and (max-width: 47.9375em) {
  .FadedBackground--onlyDesktop:before {
    content: none;
  }
}
.FadedBackground--small:before {
  padding-bottom: 16.129032258064516%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
@media screen and (min-width: 64em) {
  .FadedBackground--small:before {
    padding-bottom: 25%;
  }
}
.FadedBackground--rounded,
.FadedBackground--rounded:before {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
