.AtrCarValue {
  text-align: center;
  line-height: 1.4;
}
.AtrCarValue-title {
  font-size: 1.4rem;
  display: block;
}
.AtrCarValue-text {
  color: #0485f4;
  line-height: 1.3;
}
