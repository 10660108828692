.BannerCarolinaLarge {
  position: relative;
  display: block;
  text-decoration: none;
  padding-top: 30px;
  overflow: hidden;
  line-height: 1.4;
}
.BannerCarolinaLarge:before {
  content: '';
  position: absolute;
  background: #0485f4;
  display: block;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 3px;
  transform-origin: center bottom;
  transition: 0.3s transform cubic-bezier(0.19, 1, 0.22, 1);
}
.BannerCarolinaLarge-inner {
  padding: 40px 40px 40px 30px;
  position: relative;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .BannerCarolinaLarge-inner {
    padding: 45px 60px;
  }
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-inner {
    padding: 0;
    display: flex;
    align-items: center;
  }
}
.BannerCarolinaLarge-title {
  font-weight: normal;
  padding-bottom: 30px;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .BannerCarolinaLarge-title {
    padding-right: 300px;
  }
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-title {
    padding-bottom: 50px;
    line-height: 1.461538461538461;
  }
}
.BannerCarolinaLarge-block {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-block {
    flex: 1 1 0%;
    padding: 30px 40px;
  }
}
@media screen and (min-width: 87.5em) {
  .BannerCarolinaLarge-block {
    padding: 50px 65px;
  }
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-block:first-child {
    padding-right: 20px;
  }
}
@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .BannerCarolinaLarge-block:first-child {
    flex: 0 0 270px;
  }
}
.BannerCarolinaLarge-download {
  display: inline-block;
  padding-right: 20px;
  position: relative;
  margin-top: 50px;
}
@media screen and (min-width: 48em) {
  .BannerCarolinaLarge-download {
    padding-right: 40px;
    margin-top: 25px;
  }
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-download {
    position: absolute;
    bottom: 30px;
    left: 40px;
  }
}
@media screen and (min-width: 87.5em) {
  .BannerCarolinaLarge-download {
    bottom: 50px;
    left: 65px;
  }
}
.BannerCarolinaLarge-download .Icon {
  position: absolute;
  top: 50%;
  right: 0;
  transition: 0.5s transform ease-out;
  transform: translateY(-55%);
  color: #5edb3d;
  margin-left: 10px;
}
.BannerCarolinaLarge-image {
  position: absolute;
  bottom: -10px;
  right: -10px;
  width: 45%;
}
@media screen and (min-width: 48em) {
  .BannerCarolinaLarge-image {
    top: -30px;
    right: -60px;
    width: 350px;
  }
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-image {
    position: relative;
    align-self: stretch;
    width: 27.82258064516129%;
    top: -30px;
    right: auto;
  }
}
.BannerCarolinaLarge-image:before {
  content: '';
  display: block;
  padding-bottom: 83.33333333333334%;
}
@media screen and (min-width: 64em) {
  .BannerCarolinaLarge-image:before {
    padding-bottom: 69.56521739130434%;
  }
}
.BannerCarolinaLarge-image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transform-origin: center bottom;
  transition: 0.9s transform cubic-bezier(0.19, 1, 0.22, 1);
}
.BannerCarolinaLarge .Text li {
  margin-bottom: 10px;
}
.BannerCarolinaLarge .Text li:before {
  top: 0.5em;
}
.BannerCarolinaLarge .Text li:last-child {
  margin-bottom: 0;
}
.BannerCarolinaLarge:hover:before {
  transform: scale(0.99);
}
.BannerCarolinaLarge:hover .BannerCarolinaLarge-image img {
  transform: rotate(3deg);
}
.BannerCarolinaLarge:hover .BannerCarolinaLarge-download .Icon {
  transform: translateY(-55%) translateX(5px);
}
