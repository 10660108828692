.FormCoupon {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px -5px;
}
.FormCoupon-input {
  padding: 0 5px;
  margin-bottom: 5px;
}
.FormCoupon-button {
  padding: 0 5px;
  margin-bottom: 5px;
}
.FormCoupon--default .FormCoupon-input,
.FormCoupon--static .FormCoupon-input {
  flex: 99999 1 300px;
}
.FormCoupon--default .FormCoupon-button,
.FormCoupon--static .FormCoupon-button {
  flex: 1 1 240px;
}
