#saleCarInsuranceAutocheck ul li {
  padding-left: 1.375em;
  position: relative;
}
#saleCarInsuranceAutocheck ul li:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  border-width: 7px 7px 0 0;
  border-color: #5edb3d transparent transparent;
  content: '';
  display: block;
  position: absolute;
  top: 0.8em;
  left: 0;
}
