.SectionAboutContact .Visual-content:before {
  position: absolute;
  transform-origin: 0 0;
  transition: transform 1s linear;
  transform: skew(-10.7deg) rotate(-10.7deg);
  transition: transform 1.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 1.4s ease;
  display: block;
  content: '';
  padding-bottom: 24.615384615384617%;
  bottom: 0;
  margin-bottom: -3%;
  left: 0;
  width: 100%;
  mix-blend-mode: multiply;
  background-image: linear-gradient(to right, rgba(4,133,244,0) 50%, rgba(4,133,244,0.8) 80%);
}
