.sectionValuation .CarValue {
  text-align: center;
  line-height: 1.4;
}
.sectionValuation .CarValue-title {
  font-size: 1.4rem;
  display: block;
}
.sectionValuation .CarValue-text {
  color: #0485f4;
  line-height: 1.3;
  font-family: 'Inter Medium', sans-serif;
  font-size: 1.8rem;
  line-height: 1.615384615384615;
}
@media screen and (min-width: 48em) {
  .sectionValuation .CarValue-text {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
  }
}
@media screen and (min-width: 75em) {
  .sectionValuation .CarValue-text {
    font-size: 2.6rem;
  }
}
.sectionValuation .pd40Title {
  padding-top: 40px;
  padding-bottom: 40px;
}
.sectionValuation .pd40AdvertSection {
  padding-bottom: 40px;
}
.sectionValuation .AdvertInfo {
  line-height: 1.4;
  text-align: left;
}
.sectionValuation .AdvertInfo .AtrCarValue-text {
  color: #01215a;
  font-size: 1.8rem;
}
.sectionValuation .Label {
  font-weight: 700;
}
