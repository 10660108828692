.Social {
  flex-shrink: 0;
  font-size: 1.6rem;
  position: relative;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
}
@media screen and (min-width: 48em) {
  .Social--desktopInline {
    display: inline-flex;
    align-items: center;
    line-height: 30px;
    height: 30px;
    position: relative;
  }
  .Social--desktopInline:before {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    display: block;
    font-size: 16px;
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
  }
  .Social--desktopInline:after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background: rgba(179,206,255,0.2);
  }
}
@media screen and (min-width: 48em) {
  .Social--desktopIcon {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #1a3f81;
  }
  .Social--desktopIcon .Social-label {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: absolute;
  }
  .Social--desktopIcon:before {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    line-height: 1;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: inherit;
  }
}
@media screen and (max-width: 47.9375em) {
  .Social--mobileIcon {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #1a3f81;
  }
  .Social--mobileIcon .Social-label {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    position: absolute;
  }
  .Social--mobileIcon:before {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    line-height: 1;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: inherit;
  }
}
@media screen and (min-width: 48em) {
  .Social--desktopInline.Social--facebook {
    padding-left: 40px;
  }
  .Social--desktopInline.Social--facebook:after {
    left: 25px;
  }
}
@media screen and (min-width: 48em) {
  .Social--desktopInline.Social--youtube {
    padding-left: 50px;
  }
  .Social--desktopInline.Social--youtube:after {
    left: 35px;
  }
}
@media screen and (min-width: 48em) {
  .Social--desktopInline.Social.Social--cebia {
    padding-left: 80px;
  }
  .Social--desktopInline.Social.Social--cebia:after {
    left: 65px;
  }
}
@media screen and (max-width: 47.9375em) {
  .Social--cebia {
    width: 80px;
  }
}
.Social--cebia:before {
  content: '';
  width: 50px;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/logo-cebia-white.svg");
}
@media screen and (min-width: 48em) {
  .Social--cebia:before {
    width: 50px;
    top: -2px;
    left: 0;
  }
}
.Social--facebook:before {
  content: '\ea12';
}
.Social--youtube:before {
  content: '\ea23';
}
.Social--twitter:before {
  content: '\ea20';
}
.Social--linkedin:before {
  content: '\ea18';
}
@media screen and (min-width: 48em) {
  .Social--desktopInline.Social--x {
    padding-left: 40px;
  }
  .Social--desktopInline.Social--x:after {
    left: 25px;
  }
}
@media screen and (min-width: 48em) {
  .Social--desktopInline.Social--instagram {
    padding-left: 40px;
  }
  .Social--desktopInline.Social--instagram:after {
    left: 25px;
  }
}
.Social--x:before {
  content: '\ea22';
}
.Social--instagram:before {
  content: '\ea17';
}
