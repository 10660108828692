.SectionMain-layout {
  display: flex;
}
@media screen and (min-width: 48em) {
  .SectionMain-layout {
    display: flex;
    margin: 0;
  }
}
@media screen and (min-width: 48em) {
  .SectionMain-nav {
    width: 30%;
    padding-right: 20px;
  }
}
@media screen and (min-width: 75em) {
  .SectionMain-nav {
    padding-right: calc(4.285714285714286% - 20px);
  }
}
.SectionMain-main {
  width: 100%;
}
@media screen and (min-width: 48em) {
  .SectionMain-main {
    width: 70%;
  }
}
.SectionMain-atrItem {
  background: #fff;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  margin-bottom: 10px;
  position: relative;
}
.SectionMain-atrItem-disabled {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .SectionMain-atrItem-disabled {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.SectionMain-atrItem-disabled .VisualIcon {
  opacity: 0.5;
}
.SectionMain-atrItem-disabled .ToggleOpenerAtr-title,
.SectionMain-atrItem-disabled .ToggleOpenerAtr-status {
  color: #9fa5b5;
}
.SectionMain-atrItem-disabled span:after {
  color: #9fa5b5;
}
@media screen and (max-width: 47.9375em) {
  .SectionMain-atrItem {
    margin-left: -10px;
    margin-right: -10px;
  }
}
.SectionMain-category:not(:last-child) {
  margin-bottom: 60px;
}
.SectionMain-categoryHeader {
  position: relative;
}
@media screen and (max-width: 47.9375em) {
  .SectionMain-categoryHeader {
    padding-left: 50px;
  }
}
.SectionMain-categoryHeader .AtrNavOpener {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (min-width: 48em) {
  .SectionMain-categoryHeader .AtrNavOpener {
    display: none;
  }
}
.SectionMain-categoryHeaderSmart {
  display: none;
}
.SectionMain-navOpener {
  position: absolute;
  top: 10px;
  left: 10px;
}
.SectionMain .Info--check {
  color: #5edb3d;
}
.SectionMain .VisualIcon--orangeLock {
  margin-bottom: 5px;
}
@media screen and (max-width: 63.9375em) {
  .SectionMain .VisualIcon--orangeLock {
    margin-right: 0px;
  }
}
