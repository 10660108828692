.SectionMap-quote {
  background: #eff8fd;
  position: relative;
  padding: 20px 30px;
}
@media screen and (min-width: 48em) {
  .SectionMap-quote {
    font-size: 1.4rem;
  }
}
@media screen and (min-width: 64em) {
  .SectionMap-quote {
    padding: 50px 55px;
  }
}
.SectionMap-quote:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: '';
  display: inline-block;
  border-width: 12px 0 12px 12px;
  border-color: transparent;
  border-left-color: #eff8fd;
  position: absolute;
  top: -12px;
  left: 0;
}
