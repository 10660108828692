.VisualIcon {
  display: inline-block;
  vertical-align: middle;
  width: 4rem;
  height: 4rem;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
}
@media screen and (min-width: 33.75em) {
  .VisualIcon {
    width: 6rem;
    height: 6rem;
  }
}
.VisualIcon--inline {
  margin-top: -0.7em;
  height: 2em;
  width: 2em;
}
@media screen and (min-width: 33.75em) {
  .VisualIcon--desktopLarge {
    width: 5rem;
    height: 5rem;
  }
}
.VisualIcon-small {
  width: 4rem;
  height: 4rem;
}
