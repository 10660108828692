.SectionHeaderCaption {
  padding: 24px 30px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #01215a;
  border-radius: 3px;
}
.SectionHeaderCaption .m-auto {
  margin: auto;
}
.SectionHeaderCaption a {
  color: #fff;
}
.SectionHeaderCaption .NoneUnderline {
  text-decoration: none;
  display: flex;
  align-items: center;
  align-self: left;
}
.SectionHeaderCaption .NoneUnderline:hover {
  text-decoration: none;
}
.SectionHeaderCaption .ArrowLeft:before {
  content: '\ea09';
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-siz: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-right: 5px;
}
.SectionHeaderCaption .CaptionStyle {
  font-size: 1.7rem;
  width: auto;
  margin-bottom: 0;
  text-align: center;
  color: #b3ceff;
}
.SectionHeaderCaption.Center {
  justify-content: center;
}
