@media screen and (max-width: 33.6875em) {
  .SectionDashboard-overview {
    margin: 0 -10px;
  }
}
.SectionDashboard-overviewRibbon {
  position: absolute;
  top: -10px;
  right: 20px;
  width: 110px;
  max-width: calc(50% - 90px);
}
@media screen and (min-width: 33.75em) {
  .SectionDashboard-overviewRibbon {
    right: 50px;
  }
}
.SectionDashboard .d-flex {
  display: flex;
}
.SectionDashboard .color-errorRed {
  color: #ff4a77;
}
.SectionDashboard .vinFindLink {
  cursor: pointer;
  margin-left: 9px;
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .SectionDashboard .LoadArea-loader {
    left: 50px;
    bottom: -5px;
    justify-content: flex-start;
    top: auto;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .sm-mb-30 {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 22.5em) and (max-width: 33.6875em) {
  .sm-mb-30 {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .sm-mb-30 {
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 64em) {
  .Grid.mobile-show {
    display: none;
  }
}
#noDataModal .Modal-close {
  visibility: hidden;
}
