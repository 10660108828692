.LogoCebia--light {
  background-image: url("./img/logo-cebia-white.svg");
  background-size: contain;
}
.LogoCebia--light img {
  visibility: hidden;
}
.LogoGjirafa {
  height: 51px;
  width: 200px;
}
.LogoGjirafa img {
  height: 100%;
}
.LogoGjirafa--light {
  background-image: url("./img/logo-gjirafa-white.svg");
  background-size: contain;
}
.LogoGjirafa--light img {
  visibility: hidden;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .LogoGjirafa {
    width: 100px;
    height: 41px;
  }
}
