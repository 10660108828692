.Loader--bars {
  position: relative;
  width: 24px;
  height: 24px;
  color: #f69900;
}
.Loader--bars span,
.Loader--bars:before,
.Loader--bars:after {
  content: '';
  position: absolute;
  display: block;
  height: 70%;
  width: 25%;
  opacity: 0.2;
  background: currentColor;
  top: 50%;
  transform: translateY(-50%);
  animation: bars-pulse 750ms infinite;
}
.Loader--bars span {
  left: calc(50% - 12.5%);
  animation-delay: 250ms;
  height: 100%;
}
.Loader--bars:before {
  left: 0;
}
.Loader--bars:after {
  right: 0;
  animation-delay: 500ms;
}
.Loader--bars.Loader--big {
  width: 72px;
  height: 72px;
}
.Loader--dots {
  position: relative;
  display: inline-flex;
}
.Loader--dots span,
.Loader--dots:before,
.Loader--dots:after {
  content: '';
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #b2b7c4;
  animation: tripledot-bounce 1s infinite;
}
.Loader--dots:before {
  animation-delay: 0.1s;
}
.Loader--dots span {
  display: block;
  margin: 0 5px;
  animation-delay: 0.3s;
}
.Loader--dots:after {
  animation-delay: 0.5s;
}
.Loader--circle {
  position: relative;
  width: 6em;
  height: 6em;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f1c40f;
  animation: spin 2s linear infinite;
}
.Loader--circle:before,
.Loader--circle:after {
  content: '';
  position: absolute;
  border: 3px solid transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.Loader--circle:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #3498db;
  animation-duration: 3s;
}
.Loader--circle:after {
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-top-color: #2ecc71;
  animation-duration: 1.5s;
}
.Loader--circleSmall {
  position: relative;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #f1c40f;
  animation: spin 2s linear infinite;
}
.Loader--circleSmall:before,
.Loader--circleSmall:after {
  content: '';
  position: absolute;
  border: 2px solid transparent;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}
.Loader--circleSmall:before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #3498db;
  animation-duration: 3s;
}
.Loader--circleSmall:after {
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-top-color: #2ecc71;
  animation-duration: 1.5s;
}
@-moz-keyframes tripledot-bounce {
  0%, 100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
@-webkit-keyframes tripledot-bounce {
  0%, 100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
@-o-keyframes tripledot-bounce {
  0%, 100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
@keyframes tripledot-bounce {
  0%, 100% {
    opacity: 1;
  }
  60% {
    opacity: 0;
  }
}
@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes bars-pulse {
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes bars-pulse {
  50% {
    opacity: 1;
  }
}
@-o-keyframes bars-pulse {
  50% {
    opacity: 1;
  }
}
@keyframes bars-pulse {
  50% {
    opacity: 1;
  }
}
