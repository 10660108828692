.Shapes {
  position: relative;
}
.Shapes-content {
  position: relative;
  z-index: 2;
}
.Shapes--1 .Shapes-shapeLightBlue {
  left: -4.838709677419355%;
  top: 0;
  margin-top: 5.080645161290322%;
}
html.is-animating .Shapes--1 .Shapes-shapeLightBlue {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(-20vw) !important;
}
.Shapes--1 .Shapes-shapeGreen {
  left: -17.580645161290324%;
  top: 0;
  margin-top: 18.70967741935484%;
}
html.is-animating .Shapes--1 .Shapes-shapeGreen {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(-20vw) !important;
}
.Shapes--1 .Shapes-shapeGreenLine {
  left: 96.45161290322581%;
  top: 0;
  margin-top: 33.70967741935484%;
}
html.is-animating .Shapes--1 .Shapes-shapeGreenLine {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.Shapes--1 .Shapes-shapeBlue {
  left: 65.40322580645162%;
  top: 0;
  margin-top: 32.822580645161295%;
}
html.is-animating .Shapes--1 .Shapes-shapeBlue {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.Shapes--1 .Shapes-shapeBlueLine {
  left: -12.822580645161292%;
  top: 0;
  margin-top: 25.725806451612904%;
}
html.is-animating .Shapes--1 .Shapes-shapeBlueLine {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(-20vw) !important;
}
.Shapes--2 .Shape {
  display: none;
}
@media screen and (min-width: 48em) {
  .Shapes--2 .Shape {
    display: block;
  }
}
.Shapes--2 .Shapes-shapeGreen {
  left: -12.741935483870966%;
  top: 0;
  margin-top: 16.7741935483871%;
}
.Shapes--2 .Shapes-shapeGreenLine {
  left: 91.85483870967742%;
  top: 0;
  margin-top: 12.096774193548388%;
}
.Shapes--2 .Shapes-shapeBlue {
  left: 60.725806451612904%;
  top: 0;
  margin-top: 12.661290322580646%;
}
.Shapes--2 .Shapes-shapeBlueLine {
  left: -8.064516129032258%;
  top: 0;
  margin-top: 10.967741935483872%;
}
