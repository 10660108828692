@media screen and (max-width: 63.9375em) {
  .TimeLimitedPopupContainer .Image img {
    width: 25%;
    height: 25%;
    margin: auto;
  }
}
@media screen and (max-width: 33.6875em) {
  .TimeLimitedPopupContainer .Image img {
    width: 25%;
    height: 25%;
    margin: auto;
  }
}
@media screen and (max-width: 33.6875em) {
  .TimeLimitedPopupContainer .Grid-cell {
    padding: 0px !important;
    text-align: center;
  }
}
@media screen and (max-width: 63.9375em) {
  .TimeLimitedPopupContainer .Grid-cell {
    padding: 0px !important;
    text-align: center;
  }
}
@media screen and (max-width: 33.6875em) {
  .TimeLimitedPopupContainer .Button {
    display: flex;
    justify-self: center;
    margin: auto;
  }
}
@media screen and (max-width: 63.9375em) {
  .TimeLimitedPopupContainer .Button {
    display: flex;
    justify-self: center;
    margin: auto;
  }
}
