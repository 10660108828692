.FormItem {
  display: inline-block;
  margin-bottom: 25px;
}
.FormItem--separator {
  margin-bottom: 0;
  width: 100%;
  display: block;
}
.FormItem--full {
  display: block;
  width: 100%;
}
.FormItem--empty {
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 64em) {
  .FormItem--submit {
    display: flex;
    justify-content: flex-end;
  }
}
.FormItem .Label {
  display: block;
  font-size: 1.5rem;
  color: #01215a;
  font-weight: 400;
}
.FormItem.is-required .Label:after {
  content: '*';
  color: #ff4a77;
}
.FormItem .Input,
.FormItem .Select {
  width: 100%;
}
