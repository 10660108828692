.SectionHighlights {
  line-height: 1.4;
  padding: 60px 0 30px;
}
@media screen and (min-width: 75em) {
  .SectionHighlights {
    padding: 60px 0 60px;
  }
}
.SectionHighlights-ribbon {
  width: 139px;
}
@media screen and (min-width: 33.75em) {
  .SectionHighlights-list {
    display: flex;
  }
}
.SectionHighlights-item {
  padding: 0 20px;
  position: relative;
  font-size: 15px;
  font-weight: bold;
}
@media screen and (min-width: 64em) {
  .SectionHighlights-item {
    padding-left: 50px;
  }
}
@media screen and (max-width: 63.9375em) {
  .SectionHighlights-item {
    margin-bottom: 30px;
    font-weight: bold;
  }
}
@media screen and (min-width: 64em) {
  .SectionHighlights-item .Icon {
    position: absolute;
    left: 20px;
    margin-top: 2px;
  }
}
@media screen and (max-width: 63.9375em) {
  .SectionHighlights-item .Icon {
    display: block;
    margin: 0 auto;
    font-size: 35px;
  }
}
