.AtrNavOpener {
  width: 32px;
  height: 32px;
  position: relative;
  background: none;
  color: inherit;
}
.AtrNavOpener:before {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea0e';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: inherit;
}
.AtrNavOpener.is-active:before {
  content: '\ea0f';
}
