.Section {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  transition: opacity 0.4s ease;
}
@media screen and (min-width: 48em) {
  .Section {
    padding-top: 9.167vw;
    padding-bottom: 9.167vw;
  }
}
@media screen and (min-width: 75em) {
  .Section {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
html.is-animating .Section {
  opacity: 0;
}
@media screen and (min-width: 48em) {
  .Section--medium {
    padding-top: 6.667vw;
    padding-bottom: 6.667vw;
  }
}
@media screen and (min-width: 75em) {
  .Section--medium {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media screen and (min-width: 48em) {
  .Section--fat {
    padding-top: 11.667vw;
    padding-bottom: 11.667vw;
  }
}
@media screen and (min-width: 75em) {
  .Section--fat {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}
.Section--bare {
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (min-width: 48em) {
  .Section--bare {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .Section--bare {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.Section--appended {
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  .Section--appended {
    padding-top: 0;
  }
}
@media screen and (min-width: 75em) {
  .Section--appended {
    padding-top: 0;
  }
}
.Section--prepended {
  padding-bottom: 0;
}
@media screen and (min-width: 48em) {
  .Section--prepended {
    padding-bottom: 0;
  }
}
@media screen and (min-width: 75em) {
  .Section--prepended {
    padding-bottom: 0;
  }
}
.Section-header {
  padding-bottom: 40px;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .Section-header {
    padding-bottom: 5vw;
    font-size: calc(0.464037122969838vw + 1.243619489559165rem);
  }
}
@media screen and (min-width: 75em) {
  .Section-header {
    padding-bottom: 60px;
    font-size: 1.8rem;
  }
}
.Section-header--withControl {
  text-align: left;
}
@media screen and (min-width: 33.75em) {
  .Section-header--withControl {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (min-width: 48em) {
  .Section-header--withControl {
    padding-bottom: 8.333vw;
  }
}
@media screen and (min-width: 75em) {
  .Section-header--withControl {
    padding-bottom: 100px;
  }
}
.Section-title {
  margin-bottom: 0.5em;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .Section-title {
    text-align: left;
    margin-bottom: 1.333333333333333em;
  }
}
.Section-header--withControl .Section-title {
  margin-bottom: 0;
  flex-grow: 1;
}
.Section-title--center {
  text-align: center;
}
@media screen and (min-width: 48em) {
  .Section-title--center {
    text-align: center;
  }
}
.Section-title--stripe {
  margin-bottom: 0;
}
@media screen and (min-width: 48em) {
  .Section-title--stripe {
    margin-bottom: 0;
  }
}
.Section-title--stripe:after {
  display: block;
  content: '';
  width: 40px;
  height: 2px;
  background-color: #0485f4;
  margin-top: 0.333333333333333em;
  margin-bottom: 0.380952380952381em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 48em) {
  .Section-title--stripe:after {
    margin-left: 0;
  }
}
@media screen and (min-width: 48em) {
  .Section-title--center.Section-title--stripe:after {
    margin-left: auto;
  }
}
.Section-anchor {
  position: absolute;
  top: 0;
}
.Section--appended .Section-anchor {
  top: -40px;
}
@media screen and (min-width: 48em) {
  .Section--appended .Section-anchor {
    top: -9.167vw;
  }
}
@media screen and (min-width: 75em) {
  .Section--appended .Section-anchor {
    top: -110px;
  }
}
.Section-controls {
  padding-top: 30px;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .Section-controls {
    padding-top: 6.667vw;
  }
}
@media screen and (min-width: 75em) {
  .Section-controls {
    padding-top: 80px;
  }
}
