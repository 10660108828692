.SectionOverview {
  overflow: hidden;
  padding-top: 25px;
}
.SectionOverview--full {
  padding-top: 0px;
  z-index: -1;
  overflow: unset;
}
.SectionOverview--default {
  margin-top: 55px;
}
