.SectionAboutIntro-quote {
  color: #fff;
  background: #01215a;
  position: relative;
  padding: 35px 30px;
}
@media screen and (min-width: 64em) {
  .SectionAboutIntro-quote {
    padding: 50px 55px;
  }
}
.SectionAboutIntro-quote:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  content: '';
  display: inline-block;
  border-width: 12px 12px 0 12px;
  border-color: transparent;
  border-top-color: #01215a;
  position: absolute;
  top: 0;
  right: -11px;
}
.SectionAboutIntro-quote li:not(:last-child) {
  margin-bottom: 15px;
}
.SectionAboutIntro-quote strong {
  font-weight: 700;
}
.SectionAboutIntro-text {
  padding: 30px 30px 0;
}
@media screen and (min-width: 48em) {
  .SectionAboutIntro-text {
    padding: 50px 40px;
  }
}
.SectionAboutIntro .VisualIcon {
  width: 2em;
  height: 2em;
}
.SectionAboutIntro .JustifyText {
  text-align: justify;
}
