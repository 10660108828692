@media screen and (min-width: 64em) {
  .SectionAboutComplaints {
    margin-top: 50px;
  }
}
@media screen and (min-width: 64em) {
  .SectionAboutComplaints-body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 64em) {
  .SectionAboutComplaints-content {
    width: 59.67741935483871%;
  }
}
.SectionAboutComplaints-visual {
  display: none;
}
@media screen and (min-width: 64em) {
  .SectionAboutComplaints-visual {
    display: block;
    width: 31.451612903225808%;
    margin-top: -8.333vw;
  }
}
@media screen and (min-width: 75em) {
  .SectionAboutComplaints-visual {
    margin-top: -100px;
  }
}
