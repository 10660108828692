.ToggleOpenerAtr {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  line-height: 1.4;
  vertical-align: bottom;
  padding: 20px 80px 20px 20px;
  font-size: 1.2rem;
  text-decoration: none;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr {
    flex-wrap: nowrap;
    font-size: 1.4rem;
    padding: 30px 80px 30px 35px;
  }
}
.ToggleOpenerAtr:before {
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  background: #85bed7;
  opacity: 0;
  bottom: 0;
  left: 0;
}
.ToggleOpenerAtr:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea08';
  position: absolute;
  bottom: 9px;
  right: 15px;
  transition: 0.3s transform;
  transform: translateY(-50%);
  color: #b2b7c4;
}
@media screen and (min-width: 33.75em) {
  .ToggleOpenerAtr:after {
    bottom: 10px;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr:after {
    top: 50%;
    right: 40px;
    bottom: auto;
  }
}
.ToggleOpenerAtr .Info {
  font-size: inherit;
}
.ToggleOpenerAtr-title {
  color: #01215a;
  margin-right: auto;
  font-size: 1.6rem;
  width: calc(100% - 65px);
  line-height: 1.2;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr-title {
    width: auto;
    padding-right: 20px;
  }
}
@media screen and (min-width: 75em) {
  .ToggleOpenerAtr-title {
    font-size: 2.2rem;
  }
}
.ToggleOpenerAtr-status,
.ToggleOpenerAtr-infoStatus {
  line-height: 1;
  margin-top: 5px;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr-status,
  .ToggleOpenerAtr-infoStatus {
    margin: 3px 0 0 20px;
  }
}
@media screen and (min-width: 75em) {
  .ToggleOpenerAtr-status,
  .ToggleOpenerAtr-infoStatus {
    margin: 3px 0 0 65px;
  }
}
.ToggleOpenerAtr-infoStatus {
  margin-bottom: -5px;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr-infoStatus {
    margin-bottom: 0;
    margin-top: 0;
  }
}
.ToggleOpenerAtr-status {
  color: #f69900;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  position: absolute;
  right: 30px;
  bottom: 15px;
  text-align: right;
  width: 130px;
}
.ToggleOpenerAtr-status .VisualIcon {
  background-size: cover;
}
@media screen and (min-width: 33.75em) {
  .ToggleOpenerAtr-status {
    width: auto;
    white-space: nowrap;
    right: 60px;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerAtr-status {
    position: static;
  }
}
.ToggleOpenerAtr .VisualIcon {
  margin-right: 25px;
  flex: 0 0 auto;
  width: 4rem;
  height: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .ToggleOpenerAtr .TripleDot {
    bottom: -3px;
  }
  .ToggleOpenerAtr .TripleDot:before,
  .ToggleOpenerAtr .TripleDot:after,
  .ToggleOpenerAtr .TripleDot span {
    width: 6px;
    height: 6px;
  }
}
.ToggleOpenerAtr.is-active:before {
  opacity: 0.2;
}
.ToggleOpenerAtr.is-active:after {
  transform: translateY(-50%) rotateX(180deg);
  color: #0485f4;
}
