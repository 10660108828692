.Timeline-timeline {
  height: 57px;
  position: relative;
  margin-left: -150px;
  margin-right: -150px;
  overflow: hidden;
  z-index: 0;
}
.Timeline-timeline:before {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  top: 0;
  width: 150px;
  background-image: linear-gradient(to right, #fff 5%, rgba(255,255,255,0) 100%);
}
.Timeline-timeline:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: 0;
  top: 0;
  width: 150px;
  background-image: linear-gradient(to left, #fff 5%, rgba(255,255,255,0) 100%);
}
.Timeline-content {
  padding-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  outline: none;
}
@media screen and (min-width: 33.75em) {
  .Timeline-content {
    padding-top: 30px;
  }
}
@media screen and (min-width: 48em) {
  .Timeline-content {
    padding-top: 40px;
  }
}
@media screen and (min-width: 64em) {
  .Timeline-content {
    padding-top: 50px;
  }
}
.Timeline-line {
  position: absolute;
  top: 0;
  left: 0;
}
.Timeline .flickity-viewport {
  width: 100%;
  outline: none;
}
.Timeline-item {
  padding-right: 40px;
  flex-shrink: 0;
  width: 80vw;
  max-width: 410px;
  position: relative;
}
@media screen and (min-width: 33.75em) {
  .Timeline-item {
    width: 410px;
    padding-right: 90px;
  }
}
.Timeline-itemDate {
  font-size: 1.8rem;
  color: #5edb3d;
  line-height: 1;
  font-weight: bold;
}
.Timeline-itemTitle {
  font-size: 1.8rem;
  line-height: 1.230769230769231;
  color: #01215a;
  font-weight: normal;
  margin-bottom: 0.807692307692308em;
  margin-top: 0.807692307692308em;
}
@media screen and (min-width: 48em) {
  .Timeline-itemTitle {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
  }
}
@media screen and (min-width: 75em) {
  .Timeline-itemTitle {
    font-size: 2.6rem;
  }
}
.Timeline-itemDot {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #5edb3d;
  border: 8px solid #eafae6;
  background-clip: padding-box;
  position: absolute;
  top: -20px;
  margin-top: -15px;
  box-shadow: 0 0 0 10px #fff;
  opacity: 0;
}
@media screen and (min-width: 33.75em) {
  .Timeline-itemDot {
    top: -30px;
  }
}
@media screen and (min-width: 48em) {
  .Timeline-itemDot {
    top: -40px;
  }
}
@media screen and (min-width: 64em) {
  .Timeline-itemDot {
    top: -50px;
  }
}
.Timeline.is-ready .Timeline-itemDot {
  opacity: 1;
}
.fileLink,
.pointer {
  cursor: pointer;
}
