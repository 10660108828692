.ArticleCard {
  text-decoration: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding: 0;
}
@media screen and (min-width: 48em) {
  .ArticleCard {
    font-size: 1.6rem;
  }
}
.ArticleCard-body {
  flex-grow: 1;
}
.ArticleCard-date {
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  color: #b2b7c4;
  line-height: 1.2;
  padding-bottom: 1em;
  display: block;
}
.ArticleCard-image {
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
.ArticleCard-image:before {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 56.994818652849744%;
}
.ArticleCard-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  border-radius: 3px;
}
.ArticleCard-content {
  padding: 15px 0 0 0;
  display: block;
}
@media screen and (min-width: 48em) {
  .ArticleCard-content {
    padding-top: 30px;
  }
}
@media screen and (min-width: 64em) {
  .ArticleCard-content {
    padding-top: 40px;
  }
}
.ArticleCard-title {
  font-weight: normal;
  color: #01215a;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.166666666666667em;
}
@media screen and (min-width: 48em) {
  .ArticleCard-title {
    font-size: 1.8rem;
    line-height: 1.611111111111111;
  }
}
.is-mouse-pointer .ArticleCard:hover .ArticleCard-title {
  color: #0485f4;
}
.ArticleCard a {
  text-decoration: none;
}
.ArticleCard a:hover {
  text-decoration: underline;
}
.ArticleCard--simple {
  padding-bottom: 40px;
  border-bottom: 1px solid transparent;
}
@media screen and (min-width: 87.5em) {
  .ArticleCard--simple {
    padding-bottom: 60px;
  }
}
.ArticleCard--simple:hover {
  border-color: #0485f4;
}
.ArticleCard--large {
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  color: #b3ceff;
  min-height: 0;
  background-color: #01215a;
}
@media screen and (min-width: 48em) {
  .ArticleCard--large {
    font-size: calc(0.464037122969838vw + 1.243619489559165rem);
    line-height: 1.777777777777778;
  }
}
@media screen and (min-width: 75em) {
  .ArticleCard--large {
    font-size: 1.8rem;
  }
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-body {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }
}
.ArticleCard--large .ArticleCard-info {
  color: inherit;
}
@media screen and (min-width: 64em) {
  .ArticleCard--large .ArticleCard-info {
    margin-bottom: 1.2em;
  }
}
.ArticleCard--large .ArticleCard-date {
  color: inherit;
}
.ArticleCard--large .ArticleCard-image {
  max-height: 300px;
  border-radius: 0px;
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-image {
    width: 50%;
    align-self: stretch;
    max-height: none;
  }
}
.ArticleCard--large .ArticleCard-image:before {
  padding-bottom: 80.64516129032258%;
}
.ArticleCard--large .ArticleCard-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0px;
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-image img {
    max-width: none;
    height: 100%;
    width: auto;
  }
}
.ArticleCard--large .ArticleCard-title {
  color: #fff;
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-title {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
    line-height: 1.461538461538461;
  }
}
@media screen and (min-width: 75em) {
  .ArticleCard--large .ArticleCard-title {
    font-size: 2.6rem;
  }
}
.ArticleCard--large .ArticleCard-content {
  padding: 10px;
  background-color: #01215a;
}
@media screen and (min-width: 22.5em) {
  .ArticleCard--large .ArticleCard-content {
    padding: 15px;
  }
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-content {
    width: 50%;
    padding: 30px;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 64em) {
  .ArticleCard--large .ArticleCard-content {
    padding-left: 7.903225806451612%;
    padding-right: 7.903225806451612%;
  }
}
@media screen and (min-width: 48em) {
  .ArticleCard--large .ArticleCard-content:after {
    display: inline-block;
    font: normal normal normal 15px/1 'icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    content: '\ea04';
    width: 2.777777777777778em;
    height: 2.777777777777778em;
    background-color: #5edb3d;
    border-radius: 50%;
    display: inline-block;
    color: #fff;
    line-height: 2.777777777777778em;
    text-align: center;
    margin-top: 1.666666666666667em;
  }
}
