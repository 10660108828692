.AtrGallery-main {
  width: 270px;
}
@media screen and (min-width: 64em) {
  .AtrGallery-main {
    flex-basis: auto;
  }
}
.AtrGallery-main .Image:before {
  padding-bottom: 200%;
}
.AtrGallery-mainImage {
  background: #eff8fd;
  padding: 20px;
  max-width: 240px;
  margin: 0 auto;
}
.AtrGallery img {
  cursor: pointer;
}
