.SectionRating {
  padding-bottom: 110px;
}
.SectionRating .RatingText {
  font-size: 1.5rem;
  color: #01215a;
}
.SectionRating .RatingTextSuccess {
  font-size: 1.5rem;
  color: #707990;
}
.SectionRating .star-ratings {
  display: flex !important;
  justify-content: center;
}
.SectionRating .star-container {
  width: 36px !important;
  height: 29px !important;
}
