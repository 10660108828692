.Button {
  position: relative;
  display: inline-flex;
  flex-flow: row nowrap;
  padding: 10px 15px;
  background: #5edb3d;
  appearance: none;
  font-weight: bold;
  max-width: 100%;
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.5rem;
  min-width: 200px;
  height: 60px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-family: 'Inter Medium', sans-serif;
  border-radius: 3px;
  overflow: hidden;
  transform: translateZ(0);
}
.Button:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #48ca25;
  right: -5%;
  left: auto;
  width: 0;
  transition-duration: 0.4s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translateZ(0) skewX(-10.7deg);
  backface-visibility: hidden;
  will-change: width;
  z-index: -1;
}
.is-mouse-pointer .Button:hover,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button {
  text-decoration: none;
}
.is-mouse-pointer .Button:hover:before,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button:before {
  left: -5%;
  right: auto;
  width: 110%;
  transition-duration: 0.5s;
  transition-delay: 0.1s;
}
@media screen and (min-width: 48em) {
  .Button {
    font-size: 1.5rem;
    padding: 10px 28px;
  }
}
.Button:before {
  z-index: auto;
}
.Button[disabled] {
  background-color: #dde0e5;
  color: #fff;
  cursor: default;
}
.Button[disabled]:before,
.Button[disabled]:after {
  display: none !important;
}
@media screen and (min-width: 64em) {
  .Button--desktopLarge {
    height: 80px;
    font-size: 1.6rem;
    font-weight: 700;
    font-size: 1.8rem;
  }
}
.Button--block {
  width: 100%;
}
.Button--hasIcon {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}
.Button--icon {
  padding: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  min-width: 0;
  min-height: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .Button--icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
  }
}
.Button--icon .Button-content {
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
}
.Button--icon>.Icon {
  font-size: 16px;
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.Button--icon>.Icon:before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: inherit;
}
@media screen and (max-width: 47.9375em) {
  .Button--mobileIcon {
    padding: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
    min-width: 0;
    min-height: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .Button--mobileIcon .Button-content {
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
  }
  .Button--mobileIcon>.Icon {
    font-size: 16px;
    color: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
  }
  .Button--mobileIcon>.Icon:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: inherit;
  }
}
@media screen and (max-width: 47.9375em) and (min-width: 48em) {
  .Button--mobileIcon {
    width: 60px;
    height: 60px;
    line-height: 60px;
    padding: 0;
  }
}
.Button--circle {
  border-radius: 50%;
}
@media screen and (max-width: 47.9375em) {
  .Button--mobileCircle {
    border-radius: 50%;
  }
}
.Button--blue {
  background: #0485f4;
  color: #fff;
}
.Button--blue:before {
  background-color: #047ae0;
}
.Button--darkBlue {
  background: #1a3f81;
  color: #fff;
}
.Button--darkBlue:before {
  background-color: #2150a3;
}
.Button--lightBlue {
  background-color: #eff8fd;
  color: #0485f4;
}
.Button--lightBlue:before {
  background-color: #daedfd;
}
.Button--transparent {
  background-color: transparent;
  color: $white;
}
.Button--transparent:before {
  background-color: transparent;
}
.Button--green {
  background-color: #5edb3d;
  color: $white;
  position: relative;
}
.Button--green:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #48ca25;
  right: -5%;
  left: auto;
  width: 0;
  transition-duration: 0.4s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translateZ(0) skewX(-10.7deg);
  backface-visibility: hidden;
  will-change: width;
  z-index: -1;
}
.is-mouse-pointer .Button--green:hover,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button--green {
  text-decoration: none;
}
.is-mouse-pointer .Button--green:hover:before,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button--green:before {
  left: -5%;
  right: auto;
  width: 110%;
  transition-duration: 0.5s;
  transition-delay: 0.1s;
}
.Button--gray {
  background-color: #707990;
  color: $white;
  position: relative;
}
.Button--gray:before {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  background-color: #5e6679;
  right: -5%;
  left: auto;
  width: 0;
  transition-duration: 0.4s;
  transition-property: width;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-delay: 0s;
  transform: translateZ(0) skewX(-10.7deg);
  backface-visibility: hidden;
  will-change: width;
  z-index: -1;
}
.is-mouse-pointer .Button--gray:hover,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button--gray {
  text-decoration: none;
}
.is-mouse-pointer .Button--gray:hover:before,
.is-mouse-pointer .HoverAnimation-trigger:hover .Button--gray:before {
  left: -5%;
  right: auto;
  width: 110%;
  transition-duration: 0.5s;
  transition-delay: 0.1s;
}
.Button--link {
  background: none;
  color: #01215a;
}
.Button--link:before {
  background-color: #eff8fd;
}
.Button--small {
  height: 40px;
  min-width: 120px;
  font-size: 1.3rem;
}
@media screen and (min-width: 48em) {
  .Button--small {
    font-size: 1.3rem;
  }
}
.Button-content {
  position: relative;
  z-index: 1;
  flex-grow: 1;
  transition: opacity 0.2s ease;
}
.Button.is-loading .Button-content,
.Button-loadingTrigger .Button-content {
  opacity: 0;
}
.Button--link .Button-content {
  text-decoration: underline;
}
.Button .Icon {
  flex-grow: 0;
  position: relative;
  z-index: 1;
  transition: opacity 0.2s ease;
}
.Button.is-loading .Icon,
.Button-loadingTrigger .Button .Icon {
  opacity: 0;
}
.Button .Button-content + .Icon,
.Button .Icon + .Button-content {
  margin-left: 30px;
}
