.PayedRegInfoExplanatory h2 {
  color: #013475;
}
.PayedRegInfoExplanatory .separator {
  height: 3px;
  border-top: 2px solid #ededed;
}
.PayedRegInfoExplanatory .gray {
  color: #808080;
}
.PayedRegInfoExplanatory .fwNormal {
  font-weight: normal;
}
