.SectionHeader--default {
  background: #01215a;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  line-height: 1.2;
}
.SectionHeader--default .capitalize {
  text-transform: lowercase;
}
.SectionHeader--default .capitalize::first-letter {
  text-transform: uppercase;
}
.SectionHeader--default .crLink {
  cursor: pointer;
}
.SectionHeader--default .SectionHeader-banner {
  position: relative;
  top: -20px;
  margin: 0 auto 20px;
  background: #fff;
  flex: 0 0 auto;
  width: 120px;
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  border-radius: 3px;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .SectionHeader--default .SectionHeader-banner {
    width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .SectionHeader--default .SectionHeader-banner {
    width: 200px;
  }
}
@media screen and (min-width: 87.5em) {
  .SectionHeader--default .SectionHeader-banner {
    width: 220px;
  }
}
.SectionHeader--default .percentage {
  align-items: center;
  margin-right: 15px;
}
.SectionHeader--default .percentage img {
  padding-right: 15px;
}
.SectionHeader--default .spaceBetween {
  justify-content: space-between;
  align-items: center;
}
.SectionHeader--default .SectionHeader-box {
  padding: 30px 15px;
}
.SectionHeader--default .SectionHeader-box--right {
  color: #b3ceff;
  padding: 30px 15px 45px 15px;
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--default .SectionHeader-box--right {
    padding-top: 0;
  }
}
@media screen and (max-width: 63.9375em) {
  .SectionHeader--default .SectionHeader-box--right {
    padding-left: 15px;
  }
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--default .SectionHeader-box {
    padding-top: 0;
  }
}
@media screen and (max-width: 63.9375em) {
  .SectionHeader--default .SectionHeader-box {
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .SectionHeader--default .SectionHeader-box {
    padding-left: 5px;
  }
}
.SectionHeader--default .SectionHeader-logoWrap {
  position: relative;
}
.SectionHeader--default .SectionHeader-logoWrap:before {
  content: '';
  padding-bottom: 100%;
  display: block;
}
.SectionHeader--default .SectionHeader-logo {
  position: absolute;
  top: 25px;
  right: 25px;
  left: 25px;
  bottom: 25px;
}
.SectionHeader--default .SectionHeader-title {
  display: block;
  font-size: 1.6rem;
  padding-bottom: 30px;
  color: rgba(255,255,255,0.8);
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--default .SectionHeader-title {
    display: none;
  }
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--default .AtrOverviewCar-label {
    display: none;
  }
}
.SectionHeader--default .SectionHeader-vin {
  display: block;
  font-size: 2.2rem;
  padding-bottom: 10px;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .SectionHeader--default .SectionHeader-vin {
    font-size: 1.7rem;
  }
}
.SectionHeader--default .SectionHeader-make {
  display: block;
  font-size: 1.8rem;
}
.SectionHeader--default .SectionHeader-footer {
  border-top: 1px solid rgba(133,190,215,0.2);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
  padding: 40px 0 50px;
}
.SectionHeader--default .border-left {
  border-left: 1px solid rgba(133,190,215,0.3) !important;
  padding-bottom: 15px;
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--default .border-left {
    padding-top: 15px;
    border-left: none;
  }
}
@media screen and (max-width: 63.9375em) {
  .SectionHeader--default .border-bottom {
    border-bottom: 1px solid rgba(133,190,215,0.3) !important;
  }
}
.SectionHeader--default .pl-75 {
  padding-left: 75px;
}
.SectionHeader--default .List li {
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (min-width: 48em) {
  .SectionHeader--default .List li {
    margin-bottom: 20px;
  }
}
.SectionHeader--default .List--circleCheck li {
  position: relative;
  padding-left: 2.5em;
}
@media screen and (max-width: 63.9375em) {
  .SectionHeader--default .List--circleCheck li {
    padding-top: 10px;
  }
}
.SectionHeader--default .List--circleCheck li:before {
  content: '';
  display: block;
  position: absolute;
  display: inline-block;
  font: normal normal normal 14px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\EA0C';
  color: #fff;
  left: 0;
  line-height: 1.6;
  text-align: center;
  padding-top: 1px;
  width: 1.6em;
  height: 1.6em;
  border-radius: 50%;
  background: #5edb3d;
  box-shadow: 0 1px 1px 0 rgba(29,47,76,0.102);
}
@media screen and (max-width: 33.6875em) {
  .SectionHeader--mobileSnap {
    margin: 0 -10px;
  }
}
@media screen and (max-width: 47.9375em) {
  .SectionHeader--mobileSnap {
    margin: 0 -20px;
  }
}
.SectionHeader--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #01215a;
  color: #fff;
  padding: 10px 15px;
}
@media screen and (min-width: 48em) {
  .SectionHeader--fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.SectionHeader--fixed .SectionHeader-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SectionHeader--fixed .SectionHeader-title {
  white-space: nowrap;
  margin-right: auto;
  font-weight: bold;
  font-size: 2.6rem;
  max-width: 40%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
