.VisualBlock {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .VisualBlock {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
}
.VisualBlock-visual {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .VisualBlock-visual {
    width: 43.95161290322581%;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock-visual {
    width: 47.983870967741936%;
  }
}
.VisualBlock-text + .VisualBlock-visual {
  margin-top: 1.75em;
}
@media screen and (min-width: 48em) {
  .VisualBlock-text + .VisualBlock-visual {
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  .VisualBlock-text {
    width: 48.38709677419355%;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock-text {
    width: 40.32258064516129%;
  }
}
.VisualBlock-visual + .VisualBlock-text {
  padding-top: 1.75em;
}
@media screen and (min-width: 48em) {
  .VisualBlock-visual + .VisualBlock-text {
    padding-top: 0;
  }
}
.VisualBlock-textSeparator {
  height: 1px;
  background-color: #e7f2f7;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media screen and (min-width: 48em) {
  .VisualBlock-textSeparator {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.VisualBlock-figure {
  position: relative;
  max-width: 595px;
}
.VisualBlock-figcaption {
  text-align: right;
  font-size: 1.5rem;
  line-height: 1.466666666666667;
  margin-top: -1.5em;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  font-weight: bold;
}
@media screen and (min-width: 33.75em) {
  .VisualBlock-figcaption {
    position: absolute;
    bottom: 10px;
    right: 0;
    margin-top: 0;
  }
}
@media screen and (min-width: 48em) {
  .VisualBlock-figcaption {
    margin-top: -1.5em;
    position: static;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock-figcaption {
    position: absolute;
    margin-top: 0;
  }
}
.VisualBlock-figcaption strong {
  color: #01215a;
  display: block;
}
.VisualBlock-shapeGreen {
  left: -17.741935483870968%;
  top: 0;
  margin-top: 12.096774193548388%;
  z-index: -1;
  width: 109.24369747899159%;
}
html.is-animating .VisualBlock-shapeGreen {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.VisualBlock-shapeGreenLine {
  left: 96.45161290322581%;
  top: 0;
  margin-top: 5.967741935483871%;
  z-index: 2;
}
html.is-animating .VisualBlock-shapeGreenLine {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.VisualBlock-shapeLightBlueGradient {
  left: -7.17741935483871%;
  top: 0;
  margin-top: -1.854838709677419%;
  z-index: -2;
  width: 109.24369747899159%;
}
html.is-animating .VisualBlock-shapeLightBlueGradient {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.VisualBlock-shapeBlueLine {
  left: -12.741935483870966%;
  top: 0;
  margin-top: 18.387096774193548%;
}
html.is-animating .VisualBlock-shapeBlueLine {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(-20vw) !important;
}
.VisualBlock-visualShape {
  left: 26.451612903225808%;
  top: 0;
  margin-top: 52.09677419354839%;
  width: 109.24369747899159%;
  mix-blend-mode: multiply;
}
@supports (mix-blend-mode: multiply) {
  .VisualBlock-visualShape {
    z-index: 2;
  }
}
html.is-animating .VisualBlock-visualShape {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
.VisualBlock--elevated {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
@media screen and (min-width: 48em) {
  .VisualBlock--elevated {
    flex-flow: column nowrap;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock--elevated {
    flex-flow: row nowrap;
  }
}
.VisualBlock--elevated .VisualBlock-text {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  padding: 40px 30px 33.33333333333333% 30px;
}
@media screen and (min-width: 33.75em) and (max-width: 47.9375em) {
  .VisualBlock--elevated .VisualBlock-text {
    max-width: 480px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 48em) {
  .VisualBlock--elevated .VisualBlock-text {
    width: 100%;
    flex-grow: 1;
    box-shadow: 0 20px 45px rgba(46,101,160,0.2);
    padding: 6.451612903225806%;
    padding-bottom: 25%;
    align-self: flex-start;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock--elevated .VisualBlock-text {
    padding-bottom: 6.451612903225806%;
  }
}
.VisualBlock--elevated .VisualBlock-textSeparator {
  margin-left: -15px;
  margin-right: -15px;
}
@media screen and (min-width: 48em) {
  .VisualBlock--elevated .VisualBlock-textSeparator {
    margin-left: -16%;
    margin-right: -16%;
  }
}
@media screen and (max-width: 63.9375em) {
  .VisualBlock--elevated .VisualBlock-visual {
    width: 80%;
    max-width: 400px;
  }
}
@media screen and (min-width: 48em) {
  .VisualBlock--elevated .VisualBlock-visual {
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock--elevated .VisualBlock-visual {
    width: 47.983870967741936%;
  }
}
.VisualBlock--elevated .VisualBlock-text + .VisualBlock-visual {
  margin-top: -25.210084033613445%;
}
@media screen and (min-width: 48em) {
  .VisualBlock--elevated .VisualBlock-text + .VisualBlock-visual {
    margin-top: -18.75%;
  }
}
@media screen and (min-width: 64em) {
  .VisualBlock--elevated .VisualBlock-text + .VisualBlock-visual {
    margin-top: 0;
  }
}
.VisualBlock--dark .VisualBlock-text {
  background-color: #01215a;
  color: #fff;
}
@media screen and (max-width: 22.4375em) {
  .VisualBlock--mobileSnap {
    margin: 0 -12px;
  }
}
@media screen and (max-width: 33.6875em) {
  .VisualBlock--mobileSnap {
    margin: 0 -20px;
  }
}
