.ToggleItem {
  max-height: 0;
  overflow: hidden;
  will-change: max-height;
  margin-bottom: 5px;
}
.ToggleItem.is-active {
  max-height: 100%;
}
.ToggleItem.is-flashing {
  animation: 1.5s flash 0.2s ease-out;
}
.ToggleItem.is-flashing>* {
  animation: 1.5s flash-content 0.2s ease-out;
}
@-moz-keyframes flash-content {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  55% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flash-content {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  55% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flash-content {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  55% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash-content {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  55% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
