.Header {
  border-bottom: 1px solid rgba(133,190,215,0.2);
  font-size: 1.4rem;
  line-height: 1.4;
  position: relative;
  z-index: 11;
  font-weight: 900;
  color: #01215a;
}
.Header-content {
  min-height: 80px;
}
.Header-contentStep {
  position: relative;
  display: flex;
  align-items: center;
}
.Header-content {
  justify-content: center;
}
@media screen and (min-width: 48em) {
  .Header-content {
    padding: 0 80px 0 120px;
    height: 110px;
  }
}
@media screen and (min-width: 64em) {
  .Header-content {
    padding: 0 200px;
  }
}
.Header-contentStep {
  min-height: 110px;
}
@media screen and (max-width: 100em) {
  .Header-contentStep {
    height: auto;
    flex-direction: column;
  }
}
.Header-nav {
  display: flex;
}
.Header-navStep {
  width: 100%;
}
.Header-logo,
.Header-logo-gjirafa {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (min-width: 48em) {
  .Header-logo,
  .Header-logo-gjirafa {
    position: absolute;
    top: calc(50% - 16px);
    left: 0;
    padding: 0;
  }
}
@media screen and (min-width: 64em) {
  .Header-logo,
  .Header-logo-gjirafa {
    width: 130px;
    height: 40px;
    top: calc(50% - 20px);
  }
}
.Header-logo a,
.Header-logo-gjirafa a {
  display: block;
}
@media screen and (min-width: 64em) {
  .Header-logo-gjirafa {
    width: 200px;
    height: auto;
    top: calc(50% - 20px);
  }
}
.Header-logo-Step .LogoCebia {
  margin-right: 20px;
}
@media screen and (max-width: 100em) {
  .Header-logo-Step .LogoCebia {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .Header-logo-Step .LogoCebia img {
    width: 100px;
  }
}
.Header--light {
  color: #fff;
  border: none;
}
.Header-logo-smart {
  flex-grow: 0;
  flex-shrink: 0;
}
@media screen and (min-width: 48em) {
  .Header-logo-smart {
    position: absolute;
    top: calc(50% - 30px);
    left: 0;
  }
}
@media screen and (min-width: 64em) {
  .Header-logo-smart {
    top: calc(50% - 31px);
  }
}
@media screen and (max-width: 33.6875em) {
  .sm-margin-auto {
    margin: auto;
  }
}
@media screen and (max-width: 100em) {
  .Container.step {
    padding: 0;
  }
}
