.Banner {
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: #fff;
  background-color: #0485f4;
  display: block;
  width: auto;
  border-radius: 3px;
  display: flex;
  flex-flow: column nowrap;
}
.is-mouse-pointer .Banner {
  transition: box-shadow 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: inset 0px 0px 0px 0px #fff;
}
.is-mouse-pointer .Banner:hover {
  transition-duration: 0.8s;
  box-shadow: inset 0px 0px 0px 5px #fff;
}
.Banner-body {
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.Banner-content {
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 30px 30px 40px;
  order: 1;
}
@media screen and (min-width: 48em) {
  .Banner-content {
    min-height: 200px;
    padding: 12.096774193548388% 12.096774193548388% 12.096774193548388% 14.516129032258066%;
  }
}
@media screen and (min-width: 64em) {
  .Banner-content {
    min-height: 240px;
  }
}
@media screen and (min-width: 48em) {
  .Banner-visual + .Banner-content {
    min-height: 0;
    padding: 9.67741935483871% 12.096774193548388% 27px 14.516129032258066%;
  }
}
@media screen and (min-width: 33.75em) {
  .Grid .Banner {
    min-height: 100%;
  }
}
.Banner-title {
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  line-height: 1.615384615384615;
  color: #fff;
  flex-grow: 1;
  line-height: 1.461538461538461;
  font-family: inherit;
  font-weight: normal;
  margin: 0;
}
@media screen and (min-width: 48em) {
  .Banner-title {
    font-size: calc(1.85614849187935vw + 0.374477958236659rem);
  }
}
@media screen and (min-width: 75em) {
  .Banner-title {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 48em) {
  .Banner-title {
    line-height: 1.461538461538461;
  }
}
.Banner-title strong {
  font-weight: 800;
}
.Banner-more {
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 1em;
}
.Banner-more:after {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea04';
  color: #5edb3d;
  margin-left: 1em;
  transition: transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.is-mouse-pointer .Banner:hover .Banner-more:after {
  transform: translateX(0.5em);
}
.Banner-visual {
  margin: 0 auto;
  order: 2;
  background-size: contain;
}
