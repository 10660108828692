.PrintIdentification h1,
.PrintIdentification h2 {
  text-align: center;
  color: #01215a;
  line-height: 1.2;
}
.PrintIdentification h1 {
  margin-top: 40px;
  margin-bottom: 30px;
}
.PrintIdentification h2 {
  margin: 25px 0px;
  font-weight: bolder;
}
.PrintIdentification p {
  display: block;
  float: left;
  width: 100%;
  color: #1f1f1f;
  margin-bottom: 1.6em;
}
.PrintIdentification div.infoBottom > p:first-child {
  margin-top: 20px;
}
.PrintIdentification .itemIdent {
  margin-top: 40px;
  page-break-inside: avoid;
}
.PrintIdentification .tblCouponInfoHead {
  text-align: left;
}
.PrintIdentification .pageBreakAvoid {
  page-break-inside: avoid;
}
.PrintIdentification .Image img,
.PrintIdentification .ImageAnonymized img {
  width: 300px !important;
  display: block !important;
  padding: 0px 7px;
  padding-top: 7px;
  max-height: 320px;
  page-break-inside: avoid;
  break-inside: avoid;
}
.PrintIdentification .Image,
.PrintIdentification .ImageAnonymized {
  display: block;
  float: left;
}
.PrintIdentification .fl {
  float: left;
  max-width: 300px;
  page-break-inside: avoid;
  break-inside: avoid;
}
.PrintIdentification .itemIdent {
  float: left;
}
.PrintIdentification .imageDesc {
  margin-left: 8px;
  display: block;
}
