.LoadArea {
  position: inherit;
}
.LoadArea--relative {
  position: relative;
}
.LoadArea--initial {
  position: initial;
}
.LoadArea-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  color: #f69900;
  font-weight: bold;
  line-height: 1;
}
.LoadArea.is-loading .LoadArea-content {
  opacity: 0;
  pointer-events: none;
}
.LoadArea--center .LoadArea-loader {
  justify-content: center;
  align-items: center;
}
.LoadArea--centerRight .LoadArea-loader {
  justify-content: flex-end;
  align-items: center;
}
.LoadArea--fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.LoadArea--fullscreen:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #01215a;
  opacity: 0.8;
}
.LoadArea--fullscreen .LoadArea-loader {
  position: relative;
  background: #fff;
  z-index: 0;
  padding: 45px 40px;
  display: flex;
  flex-flow: column-reverse;
  color: #01215a;
  font-size: 2rem;
}
.LoadArea--fullscreen .Loader {
  margin: 20px 0 30px 0;
}
.LoadArea--loadReport:before {
  opacity: 0.97;
}
