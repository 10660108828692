.InputWithCounter {
  display: block;
  position: relative;
  line-height: 1.2;
  color: #01215a;
}
.InputWithCounter-label {
  position: absolute;
  overflow: hidden;
  font-weight: 600;
  top: 15px;
  left: 17px;
  font-size: 11px;
  transition: 0.01s all 0.3s;
  transform: translateY(-50%);
}
.InputWithCounter-label:before {
  content: '';
  position: absolute;
  background: linear-gradient(to top, rgba(255,255,255,0), #fff 20%, #fff 80%, rgba(255,255,255,0));
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  transform: translateX(-100%) skew(-10.7deg, 0);
  transition: 0.4s transform cubic-bezier(0.1, 0.5, 0.5, 0);
}
.InputWithCounter-input.Input {
  width: 100%;
  padding-left: 15px;
  padding-top: 10px;
  text-transform: uppercase;
}
@media screen and (min-width: 64em) {
  .InputWithCounter-input.Input {
    padding-left: 30px;
    font-size: 2rem;
  }
}
@media screen and (min-width: 48em) {
  .InputWithCounter-input.Input {
    padding-right: 120px;
  }
}
.InputWithCounter-input.Input::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}
.InputWithCounter-counter {
  position: absolute;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  color: #b2b7c4;
  font-size: 12px;
  bottom: 5px;
  right: 10px;
}
@media screen and (min-width: 48em) {
  .InputWithCounter-counter {
    top: calc(50% - 7.5px);
    right: 35px;
    font-size: 15px;
  }
}
.InputWithCounter-error {
  font-family: 'Inter Medium', sans-serif;
  position: absolute;
  color: #ff4a77;
  font-size: 12px;
  font-weight: bold;
  top: 8px;
  right: 10px;
}
@media screen and (min-width: 48em) {
  .InputWithCounter-error {
    top: auto;
    bottom: 17px;
    right: 35px;
  }
}
.InputWithCounter.has-error {
  color: #ff4a77;
}
.InputWithCounter.has-error .InputWithCounter-input {
  border-color: #ff4a77;
}
.InputWithCounter:not(.is-empty) .InputWithCounter-counter {
  color: #01215a;
}
.InputWithCounter.is-full .InputWithCounter-counter {
  color: #5edb3d;
}
.InputWithCounter.is-empty:not(.is-focus) .InputWithCounter-label {
  top: 50%;
  left: 15px;
  font-size: 17px;
}
.InputWithCounter.is-empty:not(.is-focus) .InputWithCounter-label:before {
  transform: translateX(100%) skew(-10.7deg, 0);
}
@media screen and (min-width: 48em) {
  .InputWithCounter.is-empty:not(.is-focus) .InputWithCounter-label {
    left: 40px;
  }
}
@media screen and (min-width: 87.5em) {
  .InputWithCounter.is-empty:not(.is-focus) .InputWithCounter-label {
    font-size: 20px;
  }
}
