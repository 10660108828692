.ToggleOpenerGeneralSimple-visual {
  margin: -15px 0 -15px -5px;
  height: auto;
  width: 70px;
  padding: 5px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralSimple-visual {
    justify-content: center;
    margin: -25px 10px -25px -30px;
    width: 110px;
    min-height: 80px;
  }
}
.ToggleOpenerGeneralSimple-titleLeft {
  margin-right: auto;
  font-weight: bold;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralSimple-titleLeft {
    width: calc(100% - 70px);
  }
}
.ToggleOpenerGeneralSimple-titleRight {
  padding-right: 20px;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralSimple-titleRight {
    margin-top: 15px;
    width: 70%;
    text-align: left;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralSimple-titleRight {
    margin-left: auto;
    white-space: nowrap;
  }
}
@media screen and (max-width: 47.9375em) {
  .ToggleOpenerGeneralSimple-inner {
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralSimple:after {
    right: 30px;
    top: 50%;
    height: max-content;
  }
}
.ToggleOpenerGeneralSimple .TogglerFlag {
  width: 50px;
}
.ml-64 {
  margin-left: 64px;
}
@media screen and (max-width: 63.9375em) {
  .ml-64 {
    margin-left: 0px;
  }
}
