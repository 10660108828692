.PayedRegRowEsa:nth-child(2n) {
  background-color: #f2f2f2;
}
.PayedRegRowEsa {
  border-top: 2px solid #ededed;
  color: #000;
  position: relative;
  padding: 5px;
}
.PayedRegRowEsaHead {
  background-color: #1670c8;
  color: #fff;
  position: relative;
  padding: 5px;
}
.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}
