.FirstCapitalize:first-letter {
  text-transform: uppercase;
}
.PageDetailPaid .loader-parent {
  width: 100%;
  height: 20px;
}
.PageDetailPaid .Header-content {
  flex-direction: column !important;
  padding: 0 0 0 0px !important;
}
.PageDetailPaid .Header-content .SectionHeaderCaption {
  max-width: 1400px;
}
@media screen and (max-width: 116.875em) {
  .PageDetailPaid .Header-content .Header-logo,
  .PageDetailPaid .Header-content .Header-logo-gjirafa {
    display: flex !important;
    flex-grow: 0 !important;
    flex-shrink: 0 !important;
    width: 100px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    position: relative !important;
    margin: auto !important;
  }
}
@media screen and (min-width: 48em) {
  .PageDetailPaid .Header-content .Header-logo,
  .PageDetailPaid .Header-content .Header-logo-gjirafa {
    height: 120px !important;
  }
}
.sample .PageHeader {
  padding: 30px 20px 0px 20px;
}
.sample .Header-content {
  flex-direction: row !important;
}
.lessData {
  padding-top: 0px;
}
@media screen and (max-width: 116.875em) {
  .lessData {
    padding-top: 50px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lessData {
    padding-top: 0px;
  }
}
@media screen and (max-width: 33.6875em) {
  .lessData {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 33.6875em) {
  .lessData .lessDataInner {
    text-align: center;
  }
}
