.AtrOverview--mobile {
  display: flex;
  margin-top: 20px !important;
}
.AtrOverview--mobile li {
  line-height: 2.2em;
}
@media screen and (min-width: 75em) {
  .AtrOverview--mobile {
    display: none;
  }
}
@media screen and (max-width: 63.9375em) {
  .AtrOverview--default .AtrOverviewSale {
    display: none;
  }
}
.AtrOverview--default,
.AtrOverview--mobile {
  background: #01215a;
  color: #fff;
  border-radius: 3px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-flow: column;
  line-height: 1.2;
}
.AtrOverview--default li,
.AtrOverview--mobile li {
  line-height: 2.2em;
}
.AtrOverview--default .AtrOverview-banner,
.AtrOverview--mobile .AtrOverview-banner {
  position: relative;
  top: -20px;
  margin: 0 auto 20px;
  background: #fff;
  flex: 0 0 auto;
  width: 120px;
  box-shadow: 0 20px 45px rgba(46,101,160,0.2);
  border-radius: 3px;
  z-index: 1;
}
@media screen and (min-width: 48em) {
  .AtrOverview--default .AtrOverview-banner,
  .AtrOverview--mobile .AtrOverview-banner {
    width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .AtrOverview--default .AtrOverview-banner,
  .AtrOverview--mobile .AtrOverview-banner {
    width: 200px;
  }
}
@media screen and (min-width: 87.5em) {
  .AtrOverview--default .AtrOverview-banner,
  .AtrOverview--mobile .AtrOverview-banner {
    width: 220px;
  }
}
.AtrOverview--default .AtrOverview-logoWrap,
.AtrOverview--mobile .AtrOverview-logoWrap {
  position: relative;
}
.AtrOverview--default .AtrOverview-logoWrap:before,
.AtrOverview--mobile .AtrOverview-logoWrap:before {
  content: '';
  padding-bottom: 100%;
  display: block;
}
.AtrOverview--default .AtrOverview-logo,
.AtrOverview--mobile .AtrOverview-logo {
  position: absolute;
  top: 25px;
  right: 25px;
  left: 25px;
  bottom: 25px;
}
.AtrOverview--default .AtrOverview-title,
.AtrOverview--mobile .AtrOverview-title {
  display: block;
  font-size: 2.6rem;
}
.AtrOverview--default .AtrOverview-vin,
.AtrOverview--mobile .AtrOverview-vin {
  display: block;
  margin-bottom: 50px;
}
.AtrOverview--default .AtrOverview-footer,
.AtrOverview--mobile .AtrOverview-footer {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 0%;
  padding: 40px 0 50px;
}
@media screen and (max-width: 33.6875em) {
  .AtrOverview--mobileSnap {
    margin: 0 -10px;
  }
}
@media screen and (max-width: 47.9375em) {
  .AtrOverview--mobileSnap {
    margin: 0 -20px;
  }
}
.AtrOverview--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #01215a;
  color: #fff;
  padding: 10px 15px;
}
@media screen and (min-width: 48em) {
  .AtrOverview--fixed {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.AtrOverview--fixed .AtrOverview-button {
  min-width: auto;
  padding-left: 30px;
  padding-right: 30px;
  height: 50px;
  margin-left: 15px;
}
.AtrOverview--fixed .AtrOverview-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AtrOverview--fixed .AtrOverview-title {
  white-space: nowrap;
  margin-right: auto;
  font-weight: bold;
  font-size: 2.6rem;
  max-width: 40%;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
}
.AtrOverview .smaller {
  font-size: 1.5rem;
  color: #a0c3ff;
  margin-top: -10px;
}
.fw400 {
  font-weight: 400 !important;
}
