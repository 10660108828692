.SectionPageHeader {
  position: relative;
  background: #01215a;
  padding: 20px 0 35px;
  color: #fff;
}
@media screen and (min-width: 48em) {
  .SectionPageHeader {
    padding: calc(10.440835266821345vw + -4.518561484918793rem) 0 calc(10.440835266821345vw + -4.518561484918793rem);
  }
}
@media screen and (min-width: 75em) {
  .SectionPageHeader {
    padding: 90px 0 120px;
  }
}
.SectionPageHeader:before {
  content: '';
  position: absolute;
  background: #01215a url("./img/bg-blue.webp") 70% 0/auto 80% no-repeat;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-top: -110px;
  margin-bottom: -80px;
}
@media screen and (min-width: 48em) {
  .SectionPageHeader:before {
    background-size: auto 100%;
  }
}
@media screen and (min-width: 64em) {
  .SectionPageHeader:before {
    margin-top: -110px;
  }
}
@media screen and (min-width: 87.5em) {
  .SectionPageHeader:before {
    clip-path: polygon(0 0, 100% 0, 100% 78%, 50% 100%, 50% 78%, 0 100%);
    margin-bottom: -250px;
  }
}
@media screen and (min-width: 100em) {
  .SectionPageHeader:before {
    background-image: url("./img/bg.webp");
  }
}
.SectionPageHeader-header {
  position: relative;
  text-align: center;
}
.SectionPageHeader-title {
  margin-bottom: 0.333333333333333em;
  font-family: 'Inter', sans-serif;
}
.SectionPageHeader-form {
  max-width: 850px;
  padding: 0 15px;
  margin: 0 auto;
}
@media screen and (min-width: 75em) {
  .SectionPageHeader-highlights {
    font-size: 1.8rem;
  }
}
.SectionPageHeader-highlights ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-bottom: -5px;
}
.SectionPageHeader-highlights .ul-left {
  justify-content: left;
}
.SectionPageHeader-highlights li {
  margin: 0 25px 5px;
  display: inline-flex;
  align-items: center;
}
.SectionPageHeader-highlights li span {
  align-self: baseline;
}
.SectionPageHeader-sublinks {
  padding-top: 10px;
}
@media screen and (max-width: 33.6875em) {
  .SectionPageHeader-sublinks {
    padding-top: 30px;
    margin: 0 -20px;
    text-align: center;
  }
}
.SectionPageHeader-sublinks>* {
  display: inline-block;
  margin: 0 20px;
}
.SectionPageHeader-shapeGreen {
  left: -40.32258064516129%;
  top: 0;
  margin-top: 16.129032258064516%;
}
@media screen and (max-width: 47.9375em) {
  .SectionPageHeader-shapeGreen {
    display: none;
  }
}
html.is-animating .SectionPageHeader-shapeGreen {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(-20vw) !important;
}
.SectionPageHeader-shapeBlue {
  left: 88.70967741935483%;
  top: 0;
  margin-top: 37.903225806451616%;
}
@media screen and (max-width: 47.9375em) {
  .SectionPageHeader-shapeBlue {
    display: none;
  }
}
html.is-animating .SectionPageHeader-shapeBlue {
  transform: skew(-10.7deg) rotate(-10.7deg) translateX(20vw) !important;
}
