.Shape {
  max-width: 650px;
  position: absolute;
  transform-origin: 0 0;
  transition: transform 1s linear;
  transform: skew(-10.7deg) rotate(-10.7deg);
  background-color: currentColor;
  transition: transform 1.4s cubic-bezier(0.19, 1, 0.22, 1), opacity 1.4s ease;
}
html.is-animating .Shape {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  transition-duration: 0.3s;
  opacity: 0;
}
.Shape:before {
  display: block;
  content: '';
  padding-bottom: 24.615384615384617%;
}
.Shape--blueLine,
.Shape--greenLine {
  height: 1px;
  width: 15.645161290322582%;
  max-width: 194px;
  z-index: 3;
}
.Shape--blueLine:before,
.Shape--greenLine:before {
  display: none;
}
.Shape--blue,
.Shape--blueLine {
  color: #0485f4;
}
.Shape--darkBlueGradient {
  background-color: transparent;
  opacity: 0.2;
  background-image: linear-gradient(-20deg, #01215a 0%, #042a6e 100%);
}
.Shape--blueGradient {
  background-color: transparent;
  background-image: linear-gradient(to right, rgba(4,133,244,0) 10%, #0485f4 50%);
}
.Shape--green,
.Shape--greenLine {
  color: #5edb3d;
}
.Shape--lightBlue {
  color: #ddecfc;
}
.Shape--mediumBlue {
  color: #1b4795;
  opacity: 0.2;
}
.Shape--mediumBlueGradient {
  color: #1b4795;
  opacity: 0.2;
  background-color: transparent;
  background-image: linear-gradient(to right, rgba(27,71,149,0) 0%, #1b4795 100%);
}
.Shape--darkBlue {
  color: #01215a;
}
.Shape--lightBlueGradient {
  background-color: transparent;
  background-image: linear-gradient(to right, #ddecfc 0%, rgba(221,236,252,0) 80%);
}
