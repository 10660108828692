.Chart {
  position: relative;
  margin-top: 20px;
  height: 250px;
}
@media screen and (min-width: 48em) {
  .Chart {
    height: 400px;
  }
}
@media screen and (min-width: 75em) {
  .Chart {
    height: 470px;
  }
}
.Chart-scroll,
.Chart-chart,
.Chart [data-highcharts-chart] {
  height: 100%;
}
.Chart .highcharts-axis.highcharts-xaxis {
  display: none;
}
.Chart .highcharts-root {
  font-family: 'Inter Medium', sans-serif !important;
}
.Chart .highcharts-credits {
  display: none;
}
.Chart .highcharts-plot-band-label {
  color: #707990;
  font-weight: 700;
  font-size: 13px !important;
}
.Chart .highcharts-label-small {
  font-weight: 150;
}
.Chart .highcharts-tooltip {
  z-index: 1;
}
.Chart .highcharts-tooltip > span {
  background: #0485f4;
  border-radius: 3px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
}
@media screen and (min-width: 48em) {
  .Chart--short {
    height: 350px;
  }
}
@media screen and (min-width: 75em) {
  .Chart--short {
    height: 350px;
  }
}
.Chart.is-overflow:before,
.Chart.is-overflow:after {
  position: absolute;
  z-index: 1;
  width: 40px;
  height: 100%;
  display: block;
}
.Chart.is-overflow:before {
  background: linear-gradient(to right, #fff, rgba(255,255,255,0));
  left: 0;
  top: 0;
}
.Chart.is-overflow:after {
  background: linear-gradient(to left, #fff, rgba(255,255,255,0));
  right: 0;
  top: 0;
}
.Chart.is-overflow .Chart-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}
.Chart.is-overflow-right:after {
  content: '';
}
.Chart.is-overflow-left:before {
  content: '';
}
