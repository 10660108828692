.BubbleOpener {
  width: 2.2em;
  height: 2.2em;
  margin: 0;
  border-radius: 50%;
  display: inline-block;
  background: #1a3f81;
  color: #b3ceff;
  font-size: 1rem;
  position: relative;
  vertical-align: middle;
  top: -0.3em;
  cursor: pointer;
}
.BubbleOpener .Icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}
.BubbleOpener.Gray {
  color: #1a3f81;
  background-color: #f0f0f0;
}
