.LangSwitch {
  display: flex;
  font-weight: bold;
  position: relative;
}
.LangSwitch-col {
  flex: 1 1 50%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
@media screen and (min-width: 48em) {
  .LangSwitch-col {
    display: block;
  }
}
.LangSwitch-col + .LangSwitch-col {
  margin-left: 2px;
}
.LangSwitch a {
  display: flex;
  align-items: center;
  padding: 11px 26px !important;
  border-radius: 3px;
  margin-bottom: 2px;
  text-decoration: none;
}
.LangSwitch a.is-active {
  background: rgba(180,217,255,0.14);
}
@media screen and (min-width: 48em) {
  .LangSwitch a.is-active {
    background: rgba(180,217,255,0.2);
  }
}
.LangSwitch a:hover {
  background: rgba(180,217,255,0.2);
}
.LangSwitch .Flag {
  margin-right: 20px;
}
