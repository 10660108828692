.AtrCategoryCardNew {
  height: auto;
  line-height: 1.1;
}
.AtrCategoryCardNew-bubble {
  float: right;
  margin-top: 9px;
  margin-left: 10px;
}
.AtrCategoryCardNew-title {
  color: #01215a;
  display: block;
  font-weight: bold;
  margin-right: auto;
  margin-left: 20px;
  width: 300px;
  text-align: left;
}
.AtrCategoryCardNew-title label {
  cursor: pointer;
}
.AtrCategoryCardNew-status {
  display: block;
  color: #f69900;
  margin-bottom: 5px;
}
.AtrCategoryCardNew--main {
  border-radius: 3px;
  box-shadow: 0 10px 25px rgba(46,101,160,0.1);
  background: #fff;
  padding: 20px 30px;
  display: flex;
  flex-flow: column;
  cursor: pointer;
  margin-bottom: 10px;
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main {
    padding: 10px;
  }
}
.AtrCategoryCardNew--main .AtrCategoryCardNew-icon {
  width: 34px;
  height: 34px;
  position: relative;
  z-index: 0;
  text-align: center;
  line-height: 16px;
  border-radius: 50%;
  background: #eaffe4;
  color: #fff;
  align-self: center;
  display: block;
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .AtrCategoryCardNew-icon {
    width: 34px;
    height: 34px;
    display: block;
  }
}
.AtrCategoryCardNew--main .AtrCategoryCardNew-icon:before {
  padding: 1px 1px 0px 0px;
  line-height: 34px;
}
.AtrCategoryCardNew--main .AtrCategoryCardNew-icon:after {
  content: '';
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background: #5edb3d;
}
@media screen and (min-width: 48em) {
  .AtrCategoryCardNew--main {
    padding: 20px 30px;
  }
}
.AtrCategoryCardNew--main .VisualIcon {
  width: 4rem;
  height: 4rem;
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .VisualIcon {
    display: table-cell;
    vertical-align: middle;
  }
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .AtrCategoryCardNew-title {
    order: 1;
    width: auto;
  }
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .AtrCategoryCardNew-divIcon {
    display: table-cell;
    vertical-align: middle;
    width: 34px;
  }
}
.AtrCategoryCardNew--main .font-smaller {
  font-size: 1.4rem;
  color: #707990;
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .left {
    padding-bottom: 10px;
    max-width: 79%;
  }
}
.AtrCategoryCardNew--main .AtrCategoryCardNew-info {
  margin-top: auto;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media screen and (max-width: 33.6875em) {
  .AtrCategoryCardNew--main .AtrCategoryCardNew-info {
    display: table;
  }
}
.AtrCategoryCardNew.is-disabled {
  opacity: 0.5;
}
.AtrCategoryCardNew.is-disabled .AtrCategoryCardNew-status,
.AtrCategoryCardNew.is-disabled .AtrCategoryCardNew-title {
  color: inherit;
}
