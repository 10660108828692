.warning {
  color: #ff4a77;
}
.flex-wrap-center {
  justify-content: center;
  flex-wrap: wrap;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-45 {
  padding-bottom: 45px;
}
.row-blue {
  height: 4rem;
  padding: 0.4rem 1.5rem;
  max-height: 12rem;
  background-color: #01215a;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  line-height: 3.6rem;
}
.tableContainer {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 63.9375em) {
  .tableContainer {
    flex-direction: column;
  }
}
.flex-text-center {
  flex: 0 1 auto;
  text-align: center;
}
.flex-one-auto {
  flex: 1 1 auto;
}
.flex-one-auto table tr td {
  width: 50%;
}
.DamageSection .light-green {
  color: #5edb3d;
}
.DamageSection ul.circle {
  list-style: circle;
  padding-left: 3.5em;
}
.DamageSection ul.disc {
  list-style: disc;
  padding-left: 1.2em;
}
.DamageSection ul.disc li {
  padding-left: 0.5em;
}
.PB-always {
  break-before: always;
  break-inside: avoid;
}
.text-justify {
  text-align: justify;
}
