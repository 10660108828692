.blue-box-medium {
  color: #fff;
  padding: 20px 30px;
  margin-top: 25px;
  background-color: #1670c8;
}
.blue-box-medium .Input {
  color: #000;
}
.blue-box-medium .InputError {
  color: #ffc600;
  font-size: 14px;
}
.PayedRegInfoForm-button {
  height: 58px !important;
}
.PayedRegInfoForm-formItem--button {
  display: flex;
  flex-direction: column;
}
