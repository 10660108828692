.FormVin {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -5px -5px;
}
.FormVin-input {
  padding: 0 5px;
  margin-bottom: 5px;
}
.FormVin-button {
  padding: 0 5px;
  margin-bottom: 5px;
}
.FormVin--default .FormVin-input,
.FormVin--static .FormVin-input {
  flex: 99999 1 300px;
}
.FormVin--default .FormVin-button,
.FormVin--static .FormVin-button {
  flex: 1 1 240px;
}
.FormVin--fixed {
  flex-flow: row;
}
.FormVin--fixed .FormVin-input {
  flex: 99999 1 300px;
}
@media screen and (min-width: 22.5em) {
  .FormVin--fixed .FormVin-button {
    flex: 1 1 80px;
  }
}
@media screen and (min-width: 48em) {
  .FormVin--fixed .FormVin-button {
    flex: 1 1 240px;
  }
}
