.SectionControls {
  padding-top: 30px;
  text-align: center;
}
@media screen and (min-width: 48em) {
  .SectionControls {
    padding-top: 6.667vw;
  }
}
@media screen and (min-width: 75em) {
  .SectionControls {
    padding-top: 80px;
  }
}
