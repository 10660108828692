.ToggleOpenerGeneralVoucher-price {
  font-size: 1.125em;
  color: #01215a;
  font-weight: bold;
  white-space: nowrap;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralVoucher-price {
    margin-top: -2px;
  }
}
.ToggleOpenerGeneralVoucher-title {
  text-align: left;
  margin-right: auto;
  padding: 5px 0;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralVoucher-title {
    width: calc(100% - 36px);
  }
}
.ToggleOpenerGeneralVoucher-check {
  margin: 0 0 0 7px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
}
@media screen and (max-width: 63.9375em) {
  .ToggleOpenerGeneralVoucher-check {
    width: 51px;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralVoucher-check {
    padding: 0;
    width: 80px;
    margin-left: -30px;
  }
}
.ToggleOpenerGeneralVoucher-info {
  font-size: 0.875em;
  padding: 7px 90px 0 0;
  text-align: left;
  color: #b2b7c4;
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralVoucher-info {
    margin-left: 50px;
    padding: 0;
    min-width: 20%;
  }
}
@media screen and (min-width: 64em) {
  .ToggleOpenerGeneralVoucher:after {
    right: 30px !important;
    top: 50% !important;
    bottom: auto !important;
  }
}
