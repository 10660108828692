.AtrNavLink {
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  flex-flow: column;
  align-items: flex-start;
}
@media screen and (min-width: 64em) {
  .AtrNavLink {
    flex-flow: row;
  }
}
.AtrNavLink > span:first-child {
  margin-right: 15px;
}
@media screen and (min-width: 48em) {
  .AtrNavLink > span:first-child {
    margin-bottom: 3px;
  }
}
.AtrNavLink-infoStatus {
  color: #f69900;
  text-align: right;
}
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  .AtrNavLink-infoStatus {
    text-align: left;
  }
}
.AtrNavLink--unavailable {
  color: #b2b7c4;
}
.AtrNavLink--unavailable .AtrNavLink-infoStatus {
  color: inherit;
}
.AtrNavLink .Info {
  font-size: inherit;
}
.AtrNavLink .Info--alert,
.AtrNavLink .Info--check {
  padding: 0px;
}
.AtrNavLink .Icon--alert {
  position: inherit !important;
}
@media screen and (max-width: 63.9375em) {
  .AtrNavLink .Icon--check {
    left: 12px !important;
  }
}
.AtrNavLink .Icon--alert:before {
  position: relative;
  top: 1px;
  right: 4px;
}
.AtrNavLink .Icon--check:before {
  position: relative;
  top: 2px;
  right: 12px;
}
.AtrNavLink:hover {
  color: #0485f4;
}
.AtrNavLink:hover .AtrNavLink-infoStatus,
.AtrNavLink:hover .Info {
  color: inherit;
}
.AtrNavLink:hover .Icon--check {
  color: inherit;
}
