.Table--half table tr td {
  width: 50%;
}
.pb-none {
  page-break-inside: avoid;
  page-break: avoid;
}
.text-justify {
  text-align: justify;
}
