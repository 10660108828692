.Checkbox {
  display: inline-flex;
  flex-flow: row nowrap;
  line-height: 1.5;
}
.Checkbox--wrapped {
  text-align: left;
}
.Checkbox-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  appearance: none;
}
.Checkbox-placeholder {
  display: inline-block;
  flex-shrink: 0;
  width: 17px;
  height: 17px;
  border: 1px solid #c0d8e5;
  vertical-align: middle;
  text-align: center;
  line-height: 17px;
  font-size: 1.6rem;
  color: #fff;
  background: #5edb3d;
  border-radius: 3px;
  box-shadow: inset 0px 0px 0px 11px #fff;
  transition: color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  margin-top: 0.2em;
}
.Checkbox--wrapped .Checkbox-placeholder {
  box-shadow: inset 0px 0px 0px 11px #fff, 0 0 0 6px #f8f9fa;
  margin: 6px;
}
.Checkbox-input:checked + .Checkbox-placeholder,
.Checkbox-input:checked + .Checkbox-trigger .Checkbox-placeholder {
  color: #fff;
  box-shadow: inset 0px 0px 0px 0px #fff;
  border-color: #5edb3d;
}
.Checkbox--wrapped .Checkbox-input:checked + .Checkbox-placeholder,
.Checkbox--wrapped .Checkbox-input:checked + .Checkbox-trigger .Checkbox-placeholder {
  box-shadow: inset 0px 0px 0px 0px #fff, 0 0 0 6px #f8f9fa;
}
.Checkbox-input:disabled + .Checkbox-placeholder,
.Checkbox-input:disabled + .Checkbox-trigger .Checkbox-placeholder {
  opacity: 0.5;
  cursor: not-allowed;
  border-color: #c0d8e5;
}
.Checkbox-input:focus + .Checkbox-placeholder,
.Checkbox-input:focus + .Checkbox-trigger .Checkbox-placeholder {
  border-color: #5edb3d;
  box-shadow: inset 0px 0px 0px 11px #fff, 0 0 0 5px #eff8fd;
}
.Checkbox-input:checked:focus + .Checkbox-placeholder,
.Checkbox-input:checked:focus + .Checkbox-trigger .Checkbox-placeholder {
  box-shadow: inset 0px 0px 0px 0px #fff, 0 0 0 5px #eff8fd;
}
.Checkbox--dark .Checkbox-placeholder {
  border-color: #1a3f81;
  box-shadow: inset 0px 0px 0px 11px #01215a;
  color: #01215a;
}
.Checkbox--dark .Checkbox-input:checked + .Checkbox-placeholder,
.Checkbox-input:checked + .Checkbox-trigger .Checkbox--dark .Checkbox-placeholder {
  color: #fff;
  box-shadow: inset 0px 0px 0px 0px #01215a;
}
.Checkbox--dark .Checkbox-input:focus + .Checkbox-placeholder,
.Checkbox-input:focus + .Checkbox-trigger .Checkbox--dark .Checkbox-placeholder {
  border-color: #0485f4;
  box-shadow: inset 0px 0px 0px 11px #01215a, #0485f4;
}
.Checkbox--dark .Checkbox-input:checked:focus + .Checkbox-placeholder,
.Checkbox-input:checked:focus + .Checkbox-trigger .Checkbox--dark .Checkbox-placeholder {
  box-shadow: inset 0px 0px 0px 0px #0485f4, #0485f4;
}
.is-mouse-pointer .Checkbox-placeholder:hover,
.is-mouse-pointer .Checkbox:hover .Checkbox-placeholder {
  border-color: #5edb3d;
}
.Checkbox-placeholder:before {
  display: inline-block;
  font: normal normal normal 15px/1 'icons';
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  content: '\ea0c';
  line-height: 17px;
  vertical-align: top;
}
.Checkbox-label {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  text-align: left;
}
.Checkbox-placeholder + .Checkbox-label {
  margin-left: 10px;
}
.Checkbox-input:disabled ~ .Checkbox-label {
  opacity: 0.7;
  color: #b2b7c4;
}
.Checkbox.is-invalid .Checkbox-placeholder {
  border-color: #ff4a77;
}
.Checkbox.is-invalid:hover .Checkbox-placeholder {
  border-color: #ff4a77;
}
