.Poster {
  will-change: transform;
  transform-origin: center center;
  z-index: 2;
  transform: translateZ(0);
  backface-visibility: hidden;
}
.Poster-inner {
  position: relative;
  max-width: 1240px;
  margin: 0 auto;
  transition: 0.2s opacity;
}
.Poster-bg {
  background: #01215a;
}
.Poster-main {
  background: #fff;
  transition: 0.4s background-color 0.1s;
}
.Poster-main:before {
  visibility: visible;
  transition: 0.5s visibility;
}
@media screen and (min-width: 75em) and (max-width: 87.4375em) {
  .Poster-main:before {
    padding-bottom: 8%;
  }
}
.Poster-shapes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  transition: 0.1s visibility 0.5s, 0.2s opacity 0.5s;
}
.Poster-shapes--inside {
  overflow: hidden;
}
.Poster-shapeBlueGradient {
  left: -9.67741935483871%;
  top: 0;
  margin-top: 21.774193548387096%;
  background-color: transparent;
  background-image: linear-gradient(-20deg, #01215a 0%, #042a6e 100%);
}
.Poster-shapeDarkBlueGradient {
  background-color: transparent;
  opacity: 0.2;
  background-image: linear-gradient(20deg, #01215a 0%, #214588 80%);
  left: 56.451612903225815%;
  top: 0;
  margin-top: 10.483870967741936%;
}
.Poster-shapeGreenLine {
  left: 95.16129032258065%;
  top: 0;
  margin-top: 8.870967741935484%;
  max-width: 155px;
}
.Poster-shapeBlueLine {
  left: -8.870967741935484%;
  top: 0;
  margin-top: 16.129032258064516%;
}
.Poster--formVin {
  color: #fff;
  padding: 50px 30px 30px;
}
@media screen and (min-width: 48em) {
  .Poster--formVin {
    padding: calc(11.600928074245939vw + -39.095127610208806px) 35px 70px;
  }
}
@media screen and (min-width: 75em) {
  .Poster--formVin {
    padding: 55px 40px 65px;
  }
}
.Poster--formVin.is-static {
  background: #01215a;
}
.Poster--formVin.is-fixed {
  line-height: 1.2;
  background: #01215a;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px 20px;
  transition: 0.3s transform;
  z-index: 10;
}
.Poster--formVin.is-fixed .Poster-shapes {
  visibility: hidden;
  opacity: 0;
}
.Poster--formVin.is-fixed:not(.is-active) {
  transform: translateY(100%);
}
.Poster--basicList {
  color: #fff;
  padding: 50px 30px 30px;
}
@media screen and (min-width: 48em) {
  .Poster--basicList {
    padding: calc(11.600928074245939vw + -39.095127610208806px) 35px 70px;
  }
}
@media screen and (min-width: 75em) {
  .Poster--basicList {
    padding: 55px 40px 55px;
  }
}
.Poster--basicList.is-static {
  background: #01215a;
}
.Poster--basicList.is-fixed {
  line-height: 1.2;
  background: #01215a;
  z-index: 10;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 15px 5px;
  transition: 0.3s transform;
}
@media screen and (min-width: 22.5em) {
  .Poster--basicList.is-fixed {
    padding: 15px 20px;
  }
}
.Poster--basicList.is-fixed .Poster-shapes {
  visibility: hidden;
  opacity: 0;
}
.Poster--basicList.is-fixed:not(.is-active) {
  transform: translateY(100%);
}
