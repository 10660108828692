.ToggleItemAtr-inner {
  padding: 15px;
}
@media screen and (min-width: 48em) {
  .ToggleItemAtr-inner {
    padding: 30px 35px;
  }
}
@media screen and (min-width: 75em) {
  .ToggleItemAtr-inner {
    padding: 45px 55px;
  }
}
