.Slider {
  position: relative;
}
.Slider-slider {
  display: flex;
  overflow: hidden;
}
.Slider-slider.flickity-enabled {
  display: block;
}
.Slider-item {
  flex: 0 0 100%;
  width: 100%;
}
.Slider-prevButton,
.Slider-nextButton {
  position: absolute;
  z-index: 2;
  top: calc(50% - 25px);
}
.Slider-nextButton {
  right: 0;
}
.Slider-prevButton {
  left: 0;
}
.Slider-text {
  font-size: 2.6rem;
}
.Slider--review .Slider-item {
  padding: 0 70px;
}
@media screen and (min-width: 48em) {
  .Slider--review .Slider-item {
    padding: 0 100px;
  }
}
.Slider--steps .Slider-item {
  padding: 0 15px;
  width: 80%;
}
@media screen and (min-width: 48em) {
  .Slider--steps .Slider-item {
    padding: 0 40px;
    flex: 0 0 45%;
    width: 45%;
  }
}
@media screen and (min-width: 64em) {
  .Slider--steps .Slider-item {
    flex: 0 0 25%;
    width: 25%;
  }
}
.Slider--hpReview {
  width: calc(100% + 40px);
  margin: 0 -20px;
}
@media screen and (max-width: 119.9375em) {
  .Slider--hpReview {
    padding: 0 50px;
  }
}
.Slider--hpReview.is-ready .Slider-item,
.Slider--hpReview.is-ready .flickity-slider {
  height: 100%;
}
@media screen and (max-width: 33.6875em) {
  .Slider--hpReview .Slider-nextButton {
    right: 15px;
    bottom: -20px;
    top: auto;
  }
}
@media screen and (min-width: 48em) {
  .Slider--hpReview .Slider-nextButton {
    right: auto;
    left: calc(100% - 20px);
  }
}
@media screen and (min-width: 120em) {
  .Slider--hpReview .Slider-nextButton {
    left: calc(100% + 50px);
  }
}
@media screen and (max-width: 33.6875em) {
  .Slider--hpReview .Slider-prevButton {
    left: 15px;
    bottom: -20px;
    top: auto;
  }
}
@media screen and (min-width: 48em) {
  .Slider--hpReview .Slider-prevButton {
    left: auto;
    right: calc(100% - 20px);
  }
}
@media screen and (min-width: 120em) {
  .Slider--hpReview .Slider-prevButton {
    right: calc(100% + 50px);
  }
}
.Slider--hpReview .Review {
  padding: 0 20px;
}
.Slider--hpReview .Slider-item {
  width: 100%;
}
@media screen and (min-width: 48em) {
  .Slider--hpReview .Slider-item {
    flex: 0 0 45%;
    width: 45%;
  }
}
@media screen and (min-width: 64em) {
  .Slider--hpReview .Slider-item {
    flex: 0 0 33.33333333333333%;
    width: 33.33333333333333%;
  }
}
