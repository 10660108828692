.sectionCheckVehicleByPerson .List li {
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sectionCheckVehicleByPerson .List--triangle li {
  padding-left: 1.375em;
  position: relative;
}
.sectionCheckVehicleByPerson .List--triangle li:nth-child(odd) {
  background-color: #eff8fd;
}
@media screen and (max-width: 63.9375em) {
  .sectionCheckVehicleByPerson .List--triangle li {
    padding-top: 10px;
  }
}
.sectionCheckVehicleByPerson .List--triangle li:before {
  width: 0;
  height: 0;
  background: 0;
  border-style: solid;
  border-width: 7px 7px 0 0;
  border-color: #5edb3d transparent transparent;
  top: 0.8em;
  content: '';
  display: block;
  position: absolute;
  left: 0;
}
.sectionCheckVehicleByPerson .test {
  color: #000;
}
@media screen and (min-width: 64em) {
  .CarviciToggle .ToggleOpenerGeneralVoucher-info {
    margin-left: 95px;
  }
}
