.CarSection {
  padding: 0;
  padding-bottom: 20px;
}
.LogoSection {
  align-items: center;
  justify-content: center;
}
.Logo {
  justify-content: left;
}
.Logo img.LogoImg {
  width: 80%;
}
.pl-25 {
  padding-left: 25px;
}
.IconBaseCarWarMessage {
  margin-top: -10px;
  margin-right: 4px;
}
.BaseCarWarMessage {
  margin-left: 3px;
  text-decoration: none;
}
.tracer-Overview {
  color: #01215a;
  box-shadow: none;
  background-image: none;
  position: relative;
  line-height: 1.4;
  flex-flow: row wrap;
}
.tracer-Overview-label {
  background-color: #fff !important;
  color: #01215a;
  font-size: 1.5rem;
  font-family: 'Acumin Pro SemiCondensed', sans-serif;
  display: block;
}
.tracer-Overview-vin {
  background-color: #fff !important;
  color: #01215a;
  font-size: 1.8rem;
}
.row-warning {
  height: 4rem;
  padding: 0.4em 1.5em;
  max-height: 12rem;
  color: #ff4a77;
  background-color: #ffbdce;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 700;
}
.Link--warning {
  color: #ff4a77;
}
.redWarning {
  color: #ff4a77;
  font-weight: bold;
}
.Chart {
  height: 380px !important;
  width: 98% !important;
  margin-left: 0px;
  margin-right: 0px;
}
.TachoSection,
.DamageSection,
.SerRecSection,
.RokvySection,
.TheftSection,
.TaxiSection,
.CheckLeaseSection,
.ValuationSection,
.EquipmentSection,
.CallingActiontSection,
.CarColorSection,
.SecuritySection,
.TechDescriptionSection,
.CarPhotoSection,
.ExekuceSection,
.LienRightsSection,
.LockedSection {
  padding: 0px;
}
.imgWidth {
  width: 100px;
}
.couponInfoDescText {
  font-size: 1.2rem;
}
