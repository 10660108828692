.select__indicator-separator {
  display: none;
}
.select__option {
  color: #000 !important;
}
.select__single-value {
  color: #000 !important;
}
.select__option--is-focused {
  background-color: #fff !important;
  color: #000;
}
.select__menu {
  background-color: #fff !important;
  height: auto !important;
}
.select__menu-list {
  border: 1px solid #b2b7c4;
  border-radius: 5px;
  color: #000;
}
.select__option--is-selected {
  background-color: #2684ff !important;
  color: #fff !important;
}
